export const MEMBER_LIST = [
  {
    image: `${process.env.NEXT_PUBLIC_STATIC_URL}/haruhisa_saito.png`,
    name_ja: '斉藤 晴久 ',
    name_en: 'Haruhisa Saito',
    position: 'Business Co-Creation / Business Planning and Design',
    description: [
      `At SCSK, Mr. Saito was engaged in ERP/SCM implementation consulting for the fashion industry and studied in the United States during that time. He also participated in the launch of the Amazon listing service business in the Japanese market at Amazon Japan. Similarly at Space Market, he was responsible for overall business operations during the start-up phase. At Renoveru, he oversaw smart home and new business ventures. He has been involved in business development, IT, and platform businesses for nearly 20 years. He founded AnyWhere Co., Ltd. in January 2020 after graduating from Waseda University's School of Commerce.`,
      `He is an International Ambassador for the European co-working platform <a href='https://seats2meet.com/en' target='_blank' rel='noopener noreferrer' style='color:#3767B1;' onmouseover=\"this.style.textDecoration='underline';\"onmouseout=\"this.style.textDecoration='none';\" >「Seats2meet.com」</a>, a board member of the Co-Working Space Association, a Minerva® Certified Instructor, and a co-founder of the Musashino regional media 'Meet Musashino'. He enjoys camping and living in two old houses in Kosuge Village, Yamanashi Prefecture.`,
    ],
    media_link: [
      '<a href="https://team-place.com/isa.saito" target="_blank" rel="noopener noreferrer">team-place.com/isa.saito</a>',
    ],
  },
  {
    image: `${process.env.NEXT_PUBLIC_STATIC_URL}/chiharu_ono.png`,
    name_ja: '小野 千春',
    name_en: 'Chiharu Ono',
    position: 'CX Promotion / Customer Support',
    description: [
      'She worked as a convenience store buyer before becoming a freelancer after marriage and childbirth. In 2017, she joined Pan For You, a company with a mission to create a new bread economy and contribute to the local economy, as a founding member. She experienced launching a business from scratch and mainly worked as a merchandiser, procuring goods and delivering them to users.\n\nShe returned to freelancing in the spring of 2021 and is currently working at a venture as the secretary and customer success and running a coworking space in Gunma prefecture as part of an NPO, embodying parallel ways of working such as community building, planning and operation, and employment support. She is also involved in supporting non-attendance at school as a social activity.',
    ],
    media_link: [
      '<a href="https://team-place.com/chiharuwork" target="_blank" rel="noopener noreferrer">team-place.com/chiharuwork</a>',
    ],
  },
  {
    image: `${process.env.NEXT_PUBLIC_STATIC_URL}/masato_nagata.png`,
    name_ja: '永田 真人 ',
    name_en: 'Masato Nagata',
    position: 'Business Co-Creation/PdM and DX Consultant',
    description: [
      'After graduating from the Graduate School of Science and Technology at Keio University, he joined JGC Holdings, a major plant engineering company.\n\nFor over 10 years, he has worked to shorten delivery times, reduce costs, and improve quality through system implementation and standardization and digitalization of the entire business in complex, large-scale liquefied natural gas engineering, procurement, and construction projects. He is currently a founding member of wesionaryTEAM, and is dedicated to supporting corporate business reform and system development as a PdM (product manager) and DX consultant.',
    ],
  },
  {
    image: `${process.env.NEXT_PUBLIC_STATIC_URL}/akifumi_yamamoto.png`,
    name_ja: '山本 哲史 ',
    name_en: 'Akifumi Yamamoto',
    position: 'Business Co-creation/ Business Planning and Design',
    description: [
      'Born in 1987, from Kyoto, graduated from Nihon University. He had interest in swimming as hobby & began swimming at the age of 2 and a half, and went on to become a Japanese champion several times. After graduating from college, he trained with the USC adult team in Los Angeles. He retired from swimming after the 2012 London Olympics selection trials.\n\nHe has designed and planned many businesses to promote industry digital transformation (DX) and improve personal trainer business skills while increasing the fitness population. He is currently based in Kyoto.',
    ],
  },
  {
    image: `${process.env.NEXT_PUBLIC_STATIC_URL}/prakash_devkota.png`,
    name_ja: 'プラカシュ デフコタ',
    name_en: 'Prakash Devkota',
    position: 'Business Co-creation/ Nepal Branch Manager',
    description: [
      'Graduated with a Bachelor’s degree in Information Technology from Nepal University of Information Technology. With experience in software development, previously worked as an IT engineer at JGC Holdings, a leading plant engineering company, where they were responsible for system development and data management in large-scale liquefied natural gas projects. Currently, they serve as a branch manager, overseeing the operations and coordinating engineers across the local team.',
    ],
  },

  {
    image: `${process.env.NEXT_PUBLIC_STATIC_URL}/yuta_higuchi.png`,
    name_ja: '樋口 雄太',
    name_en: 'Yuta Higuchi',
    position: 'Business Co-Creation / PdM',
    description: [
      'Graduated from Hon Denshi Vocational School with a major in Advanced Information Processing. Worked as a programmer and system engineer developing core systems for an MVNO operator. Currently serves as a Product Manager at wesionaryTEAM. Guided by the principle of “Co-Creation,” they strive to create an environment where all project members can contribute ideas. They focus on optimizing project management and enhancing mutual understanding with overseas team members.',
    ],
  },
  {
    image: `${process.env.NEXT_PUBLIC_STATIC_URL}/masachika_sawano.png`,
    name_ja: '澤野 正周',
    name_en: 'Masachika Sawano',
    position: 'Legal Advisor',
    description: [
      'He is a partner lawyer at the law firm of Kusui, Iwasaki, and Sawano (First Tokyo Bar Association). As an in-house lawyer, he has experience as a legal head in Japan and Korea at Twitter Japan Co., Ltd. and in e-commerce, logistics, real estate, human resources and general corporate law at Amazon Japan Co., Ltd. At Scadden Arps, he was mainly involved in structured real estate finance and various real estate transactions.',
      'Affiliation: Kusui, Iwasaki, and Sawano Law Firm. Graduated from the Faculty of Law at the University of Tokyo and completed the Graduate School of Legal Studies at Waseda University.',
    ],
  },
  {
    image: `${process.env.NEXT_PUBLIC_STATIC_URL}/takuya_sakai.png`,
    name_ja: '坂井 拓也',
    name_en: 'Takuya Sakai',
    position: 'Financial Advisor',
    description: [
      'After passing the certified public accountant exam, he joined a new auditing firm, currently PwC Arata LLC. He has been involved in accounting audit services for various industries, including automobiles, wholesale and retail, consumer goods, and energy-related companies. After transferring to PwC tax corporation, he was responsible for tax filings, organizational restructurings, and supporting the introduction of consolidated taxation.\n\nAt Tokyo Kyodo Accounting Office, he was involved in corporate restructuring, financial, tax, and business due diligence services, among others. He started independent practice in 2016 and has held his current position since 2020. He holds a degree from the International Accounting Graduate School of Chuo University and is a certified public accountant and tax accountant.',
    ],
  },
  {
    image: `${process.env.NEXT_PUBLIC_STATIC_URL}/ayumi_isohata.png`,
    name_ja: '五十幡あゆみ ',
    name_en: 'Ayumi Isohata',
    position: 'Designer',
    description: [
      'She has been active as a designer and PdM at companies like Yahoo, Speee, and Leverate. She currently works as a PO and designer at an educational venture, and is also in charge of design supervision and production at wesionaryTEAM.',
    ],
  },
  {
    image: `${process.env.NEXT_PUBLIC_STATIC_URL}/akihito_sato.png`,
    name_ja: '佐藤 明博 ',
    name_en: 'Akihiro Sato',
    position: 'Designer',
    description: [
      'He worked as a designer and director for web improvement projects at WACUL, leading the UI/UX and branding work for the AI Analyst, a web improvement service that utilizes artificial intelligence and was awarded the Good Design Award in 2017.\n\nAfter becoming a freelancer, he started supporting web and video production for startups. In 2020, he launched the Mr. GAMEHIT game-specific video production service department at Mailabo and is in charge of design production for wesionaryTEAM.',
    ],
  },
];
