import { MailIcon, VideoCameraIcon } from '@heroicons/react/outline';
import { Button } from '../../atoms';
import { useTranslation } from 'next-i18next';
import { ForwardedRef, forwardRef } from 'react';
import { ContactCard } from '../../molecules';
import { handleChatOpen } from '../../../utils/handleChatOpen';
import { Text } from '@chakra-ui/react';
import { typography } from 'theme';
import Chat from '../../../assets/chat.svg';

const ContactList = forwardRef(
  (
    { id, className, fullWidth }: any,
    ref: ForwardedRef<HTMLElement>,
  ) => {
    const { t } = useTranslation();

    return (
      <>
        <div
          className={`mt-16 border-l-[6px] border-primary-500 sm:mb-8 ${
            fullWidth ? 'm-auto ' : ''
          }`}
        >
          <Text
            as="h3"
            className="pl-4 mb-4 text-2xl font-bold leading-8 capitalize font-Rubik text-black-secondary md:text-medium md:leading-medium"
          >
            {t('Contact Us')}
          </Text>
          <Text
            as="p"
            className="mb-4 mr-0 pl-4 font-Poppins text-gray-secondary lg:mb-8 lg:mr-[30px] xl:mr-[60px] 3xl:mr-0"
            {...typography.medium}
          >
            {t(
              "We promote business development and DX with you as a co-creation partner. Let us know your company's development concerns and requests.",
            )}
          </Text>
        </div>

        <section
          id={id}
          ref={ref}
          className={`mt-16   px-0 lg:mr-4 lg:flex 2xl:mx-auto 3xl:max-w-none ${className} gap-x-16 3xl:justify-between ${
            fullWidth ? '!mx-auto' : ''
          }`}
        >
          <ContactCard
            marginTop="lg:mt-[33px] mt-4"
            icon={
              <MailIcon className="mt-1 h-[26px] w-[26px] text-primary-500" />
            }
            topText={t('Email')}
            noGap
            margin="32px"
            lowerTextFirst={t(
              'Please feel free to fill a form. We will get back to you soon.',
            )}
            buttonComponent={
              <Button
                className="!w-full lg:!w-auto"
                href="/contact"
                btnVarient="link"
                type="secondary"
              >
                {t('Write an email')}
              </Button>
            }
          />

          <ContactCard
            marginTop="mt-[33px]"
            icon={
              <VideoCameraIcon className="mt-1 h-[26px] w-[26px] text-primary-500" />
            }
            topText={t('Online Meeting')}
            lowerTextFirst={t(
              'Online meeting reservations are available.\nPlease feel free to schedule a meeting.',
            )}
            lowerTextSecond={t('')}
            buttonComponent={
              <Button
                className="!w-full capitalize lg:!w-auto"
                href="https://app.spirinc.com/patterns/availability-sharing/1b4535d5-b65d-496d-8241-c166b23c3c8f/confirm"
                btnVarient="link"
                newTab
                type="secondary"
              >
                {t('Schedule an online meeting')}
              </Button>
            }
          />

          <ContactCard
            margin="0px"
            marginTop="mt-[33px]"
            icon={<Chat className="text-primary-500" />}
            topText={t('Chat')}
            lowerTextFirst={t(
              'Our representatives will respond to you soon.\n',
            )}
            lowerTextSecond={t('Available time:\n')}
            weekday={t('Weekdays 10:00 - 18:00 JST')}
            buttonComponent={
              <Button
                className="!w-full capitalize lg:!w-auto"
                href="/"
                btnVarient="Other"
                onClick={handleChatOpen}
                type="secondary"
              >
                {t('Start chat now')}
              </Button>
            }
          />
        </section>
      </>
    );
  },
);

export { ContactList };
