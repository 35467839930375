import { Text } from '@chakra-ui/react';
import { Button } from 'components/atoms';
import { CoreSection } from 'components/molecules/CoreValuesSection';
import { useTranslation } from 'next-i18next';
import { ExternalLinkIcon } from '@heroicons/react/outline';
import React, { ForwardedRef, forwardRef } from 'react';
import { CORE_VALUES } from 'utils/corevalue';

export const CoreValuesList = forwardRef(
  (
    { id, className, hideButton }: any,
    ref: ForwardedRef<HTMLElement>,
  ) => {
    const { t } = useTranslation();

    return (
      <section id={id} ref={ref} className={`mt-16 ${className}`}>
        <Text
          as="h2"
          className="mb-[27px] border-l-4 border-primary-500 pl-4 font-Rubik text-2xl font-bold capitalize lg:mb-8 lg:mt-8 lg:text-[32px]"
        >
          {t('Core values')}
        </Text>
        {CORE_VALUES?.map((item, index) => (
          <CoreSection
            key={`corevalues-${index}`}
            code={t(item?.code)}
            title={t(item.title)}
            description={t(item.description)}
          />
        ))}
        {!hideButton && (
          <Button
            className="!w-full px-4 sm:px-8 lg:mt-[-16px] lg:!w-auto"
            type="secondary"
            btnVarient="link"
            href="https://wesionary-team.notion.site/Values-1daab19885464671b56e8ce436e245e5"
            newTab
          >
            <div className="flex w-auto justify-center text-center">
              {t('See Action Guidelines by Grade')}
              <ExternalLinkIcon className="ml-2 h-5 w-5" />
            </div>
          </Button>
        )}
      </section>
    );
  },
);
