import { Text } from '@chakra-ui/react';
import {ReactNode} from 'react';
interface IQulaityProps {
  logo?: ReactNode;
  title?: string;
  description?: string;
}

export const QualityOperationItem = ({
  logo,
  title,
  description,
}: IQulaityProps) => {
  return (
    <div className="mb-6 flex gap-4 lg:mb-0">
      <div>{logo}</div>
      <div>
        <Text
          className="font-Poppins text-lg font-semibold leading-[26px] text-gray-primary"
          as="h3"
        >
          {title}
        </Text>
        <Text className="mt-2 font-Poppins text-base text-gray-secondary">
          {description}
        </Text>
      </div>
    </div>
  );
};
