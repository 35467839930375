import { CompanyTopSection } from 'components/molecules/CompanyTopSection';
import { useTranslation } from 'next-i18next';
import { ForwardedRef, forwardRef } from 'react';
import Vision from '../../../../public/vision.svg';
import Mission from '../../../../public/mission.svg';
import { PageProps } from 'shared/interfaces';
import { useRouter } from 'next/router';

export const CompnayTop = forwardRef(
  ({ id, className }: PageProps, ref: ForwardedRef<HTMLElement>) => {
    const { t } = useTranslation();

    const route = useRouter();
    const lang = route.locale;

    return (
      <section
        id={id}
        ref={ref}
        className={`mr-0 min-h-[530px] bg-primary-700 bg-techBg bg-contain bg-center bg-no-repeat p-6 lg:mr-[30px] lg:flex lg:items-center lg:justify-center xl:mr-[60px] 3xl:mr-0 ${className}`}
      >
        <div className="mx-w-[960px]  lg:flex lg:items-start lg:justify-center ">
          <CompanyTopSection
            logo={<Vision />}
            mainTitle={t('Vision')}
            subtitle={t(
              'Towards a society where co-creation is the norm.',
            )}
            description={t(
              'We envision a future where all businesses and individuals effectively combine their knowledge and resources to maximize their value.',
            )}
            margin={'lg:mt-[40px]'}
          />
          <CompanyTopSection
            withBorder
            logo={<Mission />}
            mainTitle={t('Mission')}
            subtitle={t(
              'Accelerate social updating through\nco-creation',
            )}
            description={t(
              `Create a platform where companies and individuals can connect, utilize each other's strengths, and effectively provide new value to the market`,
            )}
            margin={`${
              lang == 'ja' ? 'lg:mt-[14px]' : 'lg:mt-[34px]'
            }  `}
          />
        </div>
      </section>
    );
  },
);
