import { Text } from '@chakra-ui/react';
import { TechFeature } from 'components/molecules';
import { useTranslation } from 'next-i18next';
import { ForwardedRef, forwardRef } from 'react';
import { PageProps } from 'shared/interfaces';
import { typography } from 'theme';

const HighProductivity = forwardRef(
  ({ id }: PageProps, ref: ForwardedRef<HTMLElement>) => {
    const { t } = useTranslation();

    return (
      <section
        id={id}
        ref={ref}
        className="mr-0 lg:mr-[30px] lg:mb-16 xl:mr-[0px]"
      >
        <TechFeature withMargin title={t('High Productivity')}>
          <Text
            as="p"
            className="mb-6 font-Poppins text-white"
            {...typography.medium}
          >
            {t(
              'We automate infrastructure construction, deployment, testing, monitoring, and the collection of vulnerability information, so that development teams can focus on tasks with higher added value. We can also safely release products as many times a day as needed to deliver better products to end users faster.',
            )}
          </Text>
          <Text
            as="p"
            className="font-Poppins text-white"
            {...typography.medium}
          >
            {t(
              `We fall into the "elite" category of Google's software delivery performance metrics.`,
            )}
            <a
              href="https://cloud.google.com/blog/ja/products/devops-sre/announcing-dora-2021-accelerate-state-of-devops-report"
              target={'_blank'}
              className="block font-semibold underline"
            >
              State of DevOps 2021
            </a>
          </Text>
        </TechFeature>
      </section>
    );
  },
);

export { HighProductivity };
