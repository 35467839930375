export const FEATURES_CONTENT = [
  {
    heading: 'Fulfilling the Essence of Needs',
    paragraph:
      'Utilizing design thinking to gain a deeper understanding of users and improve the accuracy of problem solving.',
    class: 'features--1',
    imageName: 'needs',
    imageAlt: 'Utilize design thinking and various frameworks to visualize/analyze ideas'
  },
  {
    heading: 'Increase Synergy',
    paragraph:
      'With maximum transparency, a diverse team participates in development from the upstream process.',
    class: 'features--2',
    imageName: 'synergy',
    imageAlt: 'All members share the mission, ideas, and KPIs to achieve the objectives'
  },
  {
    heading: 'Agile Experimentation and \nIteration',
    paragraph:
      'Utilizing agile development methods, the cycle of hypothesis testing and learning is quickly rotated.',
    class: 'features--3',
    imageName: 'agile',
    imageAlt: 'Use agile development and lean startup methodologies to speedily verify hypotheses'
  },
];
