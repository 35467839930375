import DXSUPPORT from '../public/contact/dx.svg';
import PLANNING from '../public/contact/planning.svg';
import PROTOTYPING from '../public/contact/prototyping.svg';
import POC from '../public/contact/poc.svg';
import APP from '../public/contact/app.svg';
import RD from '../public/contact/r&d.svg';
import GROWTH from '../public/contact/growth.svg';
import SUPPORT from '../public/contact/support.svg';

export const CONTACT_FEATURES: any = [
  {
    icon: <DXSUPPORT />,
    title: 'DX Support',
  },
  {
    icon: <PLANNING />,
    title: 'Planning/Proposal',
  },
  {
    icon: <PROTOTYPING />,
    title: 'Prototyping',
  },
  {
    icon: <POC />,
    title: 'PoC/MVP',
  },
  {
    icon: <APP />,
    title: 'Web/App',
  },
  {
    icon: <RD />,
    title: 'R&D',
  },
  {
    icon: <GROWTH />,
    title: 'Business Growth',
  },
  {
    icon: <SUPPORT />,
    title: 'In-house Support',
  },
];

export const SERVICEOPTIONS = [
  {
    label:
      'New business planning, launching support, and development',
    value: '1',
  },
  {
    label: 'DX support and IT consulting',
    value: '2',
  },
  {
    label: 'Web service and App Development',
    value: '3',
  },
  {
    label: 'Development and renewal of existing systems and services',
    value: '4',
  },
  {
    label: 'Development Second Opinion',
    value: '5',
  },
  {
    label: 'Business Alliances and Collaborations',
    value: '6',
  },
  {
    label: 'Others',
    value: '7',
  },
];

export const KNOWABOUTUS = [
  {
    label: 'Facebook',
    value: '1',
  },
  {
    label: 'LinkedIn',
    value: '2',
  },
  {
    label: 'Medium',
    value: '3',
  },
  {
    label: 'University Career Site',
    value: '4',
  },
  {
    label: 'Friend',
    value: '5',
  },
  {
    label: 'Others',
    value: '6',
  },
];
