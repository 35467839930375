import { DevelopmentPreparation } from 'components/molecules/Preparation';
import { ForwardedRef, forwardRef } from 'react';
import Requirement from '../../../../public/requirement.svg';
import Prototype from '../../../../public/prototyping.svg';
import Api from '../../../../public/api.svg';
import { Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { typography } from 'theme';
import { useRouter } from 'next/router';
import { PageProps } from 'shared/interfaces';

export const QualityPreparationList = forwardRef(
  ({ id }: PageProps, ref: ForwardedRef<HTMLElement>) => {
    const { t } = useTranslation();
    const { locale } = useRouter();

    return (
      <section
        id={id}
        ref={ref}
        className="mb-20 mt-9 md:mr-6 md:mb-16 3xl:mr-0"
      >
        <div className="mb-8 border-l-[6px] border-primary-500 pl-4 lg:mb-12 ">
          <Text
            as="h3"
            className="mb-4 text-2xl font-bold leading-8 font-Rubik text-black-secondary md:text-medium md:leading-medium"
          >
            {t('Development Preparation')}
          </Text>
          <Text
            as="p"
            className="inline font-Poppins text-gray-secondary"
            {...typography.medium}
          >
            {t(
              'By conducting requirements definition and design in a systematic, rule-based manner, we prevent many rework and problems caused by inadequacies in the upstream process.',
            )}
          </Text>
        </div>

        <div className="md:gap-4 lg:grid lg:grid-cols-3 lg:gap-y-16">
          <DevelopmentPreparation
            icon={<Requirement />}
            title={t('Requirement Definition and System Design')}
            description={
              locale?.startsWith('en') ? (
                <div className="mt-4">
                  <Text className="inline text-gray-secondary">
                    {t('Create ')}
                  </Text>

                  <a
                    href="https://affordd.jp/libraries/affordd-conference2021-t02/"
                    target={'_blank'}
                    className="underline text-primary-500"
                  >
                    {t('USDM-based ')}
                  </a>

                  <Text className="inline text-base text-gray-secondary">
                    {t(
                      'requirements specifications and improve the quality by reviewing and approving them. Documentation is kept up-to-date by adding updating to the development flow.',
                    )}
                  </Text>
                </div>
              ) : (
                <div className="mt-4 lg:mt-16">
                  <Link href="/">
                    <a className="underline text-primary-500">
                      {t('USDM')}
                    </a>
                  </Link>
                  <Text className="inline text-base text-gray-secondary">
                    {t(
                      ' ベースの要求仕様書を作成し、レビュー・承認によってその質を上げています。ドキュメントの更新を開発フローに加えることで、最新の状態に保ちます。',
                    )}
                  </Text>
                </div>
              )
            }
          />
          <DevelopmentPreparation
            icon={<Api />}
            title={t('API Design')}
            description={
              <div className=" mt-4 lg:mt-[46px]">
                <Text className="inline text-gray-secondary">
                  {t('Design and documentation of APIs using the ')}
                </Text>
                <a
                  href="https://www.openapis.org/"
                  target={'_blank'}
                  className="underline text-primary-500"
                >
                  {t('OpenAPI ')}
                </a>
                <Text className="inline text-base text-gray-secondary">
                  {t(
                    'specification, a highly versatile, global standard.',
                  )}
                </Text>
              </div>
            }
          />
          <DevelopmentPreparation
            icon={<Prototype />}
            title={t('Prototyping')}
            description={
              <Text className="mt-4 text-base  text-gray-secondary lg:mt-[46px]">
                {t(
                  'Start prototyping from the initial stages. Embodying requirements helps foster a common understanding and allows everyone to share ideas.',
                )}
              </Text>
            }
          />
        </div>
      </section>
    );
  },
);
