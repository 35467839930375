import  { useState, createContext } from 'react';

type ContextProps = {
  selectedMenu: string;
  setSelectedMenu: any;
};

export const Context = createContext<Partial<ContextProps>>({});

const ContextProvider = (props: any) => {
  const [selectedMenu, setSelectedMenu] = useState('co-creation');

  return (
    <Context.Provider
      value={{
        selectedMenu,
        setSelectedMenu,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export { ContextProvider };
