import { INavbarConfig } from 'shared/interfaces/';

export const navBarConfig: INavbarConfig[] = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'Service',
    path: '/service',
  },
  {
    title: 'Technology',
    path: '/technology',
  },
  {
    title: 'Quality',
    path: '/quality',
  },
  {
    title: 'Security',
    path: '/security',
  },
  {
    title: 'Company',
    path: '/company',
  },
  {
    title: 'Career',
    path: '/career',
  },
  {
    title: 'Contact',
    path: '/contact',
  },
];

export const navMenuItems: INavbarConfig[] = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'service',
    path: '/service',
  },
  {
    title: 'technology',
    path: '/technology',
  },
  {
    title: 'quality',
    path: '/quality',
  },
  {
    title: 'security',
    path: '/security',
  },
  {
    title: 'Company Information',
    path: '/company',
  },
  {
    title: 'Recruitment Information',
    path: '/career',
  },
  {
    title: 'Consultation on Development and DX',
    path: '/contact',
    type: 'button',
  },
];
