import { useContext } from 'react';
import { List, Box } from '@chakra-ui/react';
import { INavbarConfig } from 'shared/interfaces';
import SideBarItem from './SideBarItem';
import { Context } from 'utils/Context';
import { scrollToId } from 'utils/scrollToID';

type Props = {
  classes?: string;
  menus: INavbarConfig[];
};

const SideBar = ({ classes, menus }: Props) => {
  const { setSelectedMenu } = useContext(Context);

  const handleScroll = (id: string) => {
    setSelectedMenu(id);
    scrollToId(id);
  };

  return (
    <Box className={`bg-white ${classes} relative`}>
      <div className="absolute -left-full h-full w-full bg-white"></div>
      <List display="flex" flexDir="column" gap="1rem">
        {menus.map((item: INavbarConfig) => (
          <SideBarItem
            onClick={() => handleScroll(item.id || '')}
            key={item.title}
            item={item}
          />
        ))}
      </List>
    </Box>
  );
};

export { SideBar };
