import { ForwardedRef, forwardRef, ReactNode, Fragment } from 'react';
import { useTranslation } from 'next-i18next';
import { Text, useMediaQuery } from '@chakra-ui/react';
import { typography } from 'theme';
import {
  ReactIconOnly,
  FlutterIcon,
  NextIconOnly,
  GoIconOnly,
  TerraFormIconOnly,
  AWSIconOnly,
  GCPOnlyIcon,
  MySqlOnlyIcon,
  GitHUbIconOnly,
  CICDIconOnly,
  FigmaOnlyIcon,
  SlackIconOnly,
  NotionIconOnly,
  JiraIconOnly,
  FigJamIcon,
  NodeIcon,
  PythonIcon,
} from 'public/assets/images/svg';
import { AnimatedLink } from 'components/atoms';
import { officialUrls } from 'constants/officialUrl';
import { PageProps } from 'shared/interfaces';

const GridDate = [
  {
    icons: [
      {
        icon: ReactIconOnly,
        link: officialUrls.reactJs,
      },
      {
        icon: FlutterIcon,
        link: officialUrls.flutter,
      },
      {
        icon: NextIconOnly,
        link: officialUrls.nextJs,
      },
    ],
    title: 'Frontend',
  },
  {
    icons: [
      {
        icon: GoIconOnly,
        link: officialUrls.goLang,
      },
      {
        mobileIcon: NodeIcon,
        link: officialUrls.nodeJs,
      },
      {
        mobileIcon: PythonIcon,
        link: officialUrls.python,
      },
    ],
    title: 'Backend',
  },
  {
    icons: [
      {
        icon: TerraFormIconOnly,
        link: officialUrls.terraform,
      },
      {
        icon: AWSIconOnly,
        link: officialUrls.aws,
      },
      {
        icon: GCPOnlyIcon,
        link: officialUrls.gcp,
      },
    ],
    title: 'Infrastructure',
  },
  {
    icons: 'Clean Architecture, Atomic Design, MonoRepo',
    title: 'Architecture',
  },
  {
    icons: [
      {
        icon: MySqlOnlyIcon,
        link: officialUrls.mySql,
      },
    ],
    title: 'Database',
  },
  {
    icons: [
      {
        icon: GitHUbIconOnly,
        link: officialUrls.github,
      },
    ],
    title: 'Source Code Management',
  },
  {
    icons: [
      {
        icon: CICDIconOnly,
        link: officialUrls.ciCd,
      },
    ],
    title: 'CI/CD',
  },
  {
    icons: [
      {
        icon: FigmaOnlyIcon,
        link: officialUrls.figma,
      },
    ],
    title: 'Prototyping, UI design',
  },
  {
    icons: [
      {
        icon: SlackIconOnly,
        link: officialUrls.slack,
      },
    ],
    title: 'Communication',
  },
  {
    icons: [
      {
        icon: NotionIconOnly,
        link: officialUrls.notion,
      },
      {
        icon: FigJamIcon,
        link: officialUrls.figJam,
      },
    ],
    title: 'Documentation',
  },
  {
    icons: [
      {
        icon: JiraIconOnly,
        link: officialUrls.jira,
      },
    ],
    title: 'Task Management',
  },
];

interface SelectionConcentrationProps extends PageProps {
  headerTitle: string;
  headerBody: string | ReactNode;
}

export const SelectionConcentration = forwardRef(
  (
    { id, headerTitle, headerBody }: SelectionConcentrationProps,
    ref: ForwardedRef<HTMLElement>,
  ) => {
    const { t } = useTranslation();
    const [isMobile] = useMediaQuery('(max-width:767px)');

    const GridCell = ({ children }: any) => {
      return (
        <div className="border-b border-r border-gray-500 p-[24px] text-gray-secondary md:px-[15px] md:py-[26px]">
          {children}
        </div>
      );
    };

    return (
      <section
        id={id}
        ref={ref}
        className="m-auto mr-0 mt-8 lg:mb-16 lg:pr-[30px] xl:pr-[60px] 2xl:mr-0 2xl:pr-0"
      >
        <div className="max-w-[1084px] 2xl:max-w-full 3xl:max-w-full">
          <div className="border-l-[6px] border-primary-500 pl-[16px]">
            <Text
              as="h2"
              {...typography.fontPoppins}
              className="text-2xl font-bold md:border-l-0 md:border-none md:pl-0 md:text-3xl"
            >
              {t(headerTitle)}
            </Text>
            <Text className="mt-4 md:pl-0">
              {typeof headerBody === 'string'
                ? t(headerBody)
                : headerBody}
            </Text>
          </div>

          <div className="mt-8  grid-flow-row grid-cols-1 border-t-[1px] border-l-[1px] border-gray-500 md:grid md:grid-cols-4">
            {GridDate.map((item, index) => {
              if (typeof item.icons === 'string') {
                return (
                  <GridCell key={index}>
                    <Text className="text-left text-base font-semibold">
                      {item.icons}
                    </Text>
                    <Text className="mt-[12px] text-left text-base text-gray-secondary">
                      {t(item.title)}
                    </Text>
                  </GridCell>
                );
              }
              return (
                <GridCell key={index}>
                  <div className="flex flex-wrap items-center justify-start gap-[24px]">
                    {item.icons.map((icon: any, index) => {
                      if (icon?.mobileIcon && isMobile) {
                        return (
                          <div key={index}>
                            {
                              <AnimatedLink href={icon.link}>
                                <icon.mobileIcon />
                              </AnimatedLink>
                            }
                          </div>
                        );
                      }
                      if (icon?.icon)
                        return (
                          <div key={index}>
                            {
                              <AnimatedLink href={icon.link}>
                                <icon.icon />
                              </AnimatedLink>
                            }
                          </div>
                        );
                      return <Fragment key={index}></Fragment>;
                    })}
                  </div>
                  <Text className="mt-[24px] text-left text-base text-gray-secondary">
                    {t(item.title)}
                  </Text>
                </GridCell>
              );
            })}
            <GridCell>
              <Text className="text-base">
                {t('Testing Tool')}:
                <AnimatedLink href={officialUrls.browser}>
                  <span className="font-semibold"> Browserstack</span>
                </AnimatedLink>
              </Text>
              <Text className="text-base">
                {t('API Documentation')}:
                <AnimatedLink href={officialUrls.openApi}>
                  <span className="font-semibold"> OpenAPI</span>
                </AnimatedLink>
              </Text>
              <Text className="text-base">
                {`${t('Email')}, ${t('Calendar')}: `}
                <AnimatedLink href={officialUrls.googleWorkspace}>
                  <span className="font-semibold">
                    Google Workspace
                  </span>
                </AnimatedLink>
              </Text>
            </GridCell>
          </div>
          <div className="mt-4 hidden md:block">
            <Text className="text-gray-secondary">
              {t(
                '* We also use languages such as Node.js and Python, other frameworks and tools as needed.',
              )}
            </Text>
          </div>
        </div>
      </section>
    );
  },
);
