import { SideBar } from 'components/organisms';
import { INavbarConfig } from 'shared/interfaces';

type Props = {
  children: React.ReactNode;
  menus: INavbarConfig[];
};

const WithSider = ({ children, menus }: Props) => {
  return (
    <div className="static m-auto max-w-[1440px] overflow-hidden">
      <div className="fixed z-10 hidden h-screen w-0 bg-transparent pt-[6rem] 3xl:left-0 3xl:block 3xl:w-[14%] break:block break:w-fit">
        <SideBar menus={menus} />
      </div>

      <div
        className={`ml-0 px-6 lg:ml-5 lg:px-0 3xl:ml-[45px] break:ml-[21%]`}
      >
        {children}
      </div>
    </div>
  );
};

export { WithSider };
