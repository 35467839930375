import { Text } from '@chakra-ui/react';
import {ReactNode} from 'react';

interface IDevelopmentProps {
  icon: ReactNode;
  title?: string;
  description?: ReactNode;
}

export const DevelopmentPreparation = ({
  icon,
  title,
  description,
}: IDevelopmentProps) => {
  return (
    <div className="mb-8 lg:mb-0 lg:max-w-[296px]">
      <div className="hidden lg:block">{icon}</div>

      <Text
        className="mt-4 font-Rubik text-2xl font-bold leading-[30px] text-gray-primary"
        as="h3"
      >
        {title}
      </Text>
      {description}
    </div>
  );
};
