import { ReactNode, useRef } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { InputGroup, Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

type FileUploadProps = {
  register: UseFormRegisterReturn;
  accept?: string;
  multiple?: boolean;
  icon?: React.ReactNode;
  label?: string;
  required?: boolean;
  errorMessage?: string;
  children?: ReactNode;
  id?: string;
};

export const Upload = (props: FileUploadProps) => {
  const {
    register,
    accept,
    multiple,
    children,
    icon,
    label,
    required,
    errorMessage,
    id,
  } = props;
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { ref, ...rest } = register as {
    ref: (instance: HTMLInputElement | null) => void;
  };
  const handleClick = () => inputRef.current?.click();
  return (
    <div className="h-[7.5rem]">
      <div className="flex items-center">
        {icon}
        <Text
          as="label"
          className={`
           ${icon && 'ml-[10px]'}
        inline font-Poppins text-[18px] font-normal leading-[26px] text-gray-primary`}
        >
          {label}
        </Text>

        <Text
          as="span"
          className="pl-[3px] font-Poppins text-[18px] font-normal leading-[26px] text-gray-secondary"
        >
          {required ? t('(Required)') : t('(Optional)')}
        </Text>
      </div>
      <InputGroup onClick={handleClick}>
        <input
          id={id}
          type={'file'}
          multiple={multiple || false}
          hidden
          accept={accept}
          {...rest}
          ref={(e) => {
            ref(e);
            inputRef.current = e;
          }}
        />
        <>{children}</>
      </InputGroup>
      {errorMessage && (
        <Text className="mt-1 text-[#960912]" height="25px" as="p">
          {errorMessage}
        </Text>
      )}
    </div>
  );
};
