const scrollToId = (id: string) => {
  const view = process.browser && document.getElementById(id);
  if (view) {
    const yOffset = -200;
    const y =
      view.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }
};

export { scrollToId };
