import { Text } from '@chakra-ui/react';

type Props = {
  src: string;
  link: string;
  title: string;
  publishDate: string;
};

export const Article = (props: Props) => {
  const { src, link, title, publishDate } = props;

  const formatDate = (date: string) => {
    let val = date.split(' ');
    let month = new Date(Date.parse(date)).getMonth() + 1;

    if (month < 10) return val[3] + '/0' + month + '/' + val[1];

    return val[3] + '/' + month + '/' + val[1];
  };

  return (
    <a href={link} target={'_blank'} rel="noopener noreferrer">
      <div className="group flex cursor-pointer flex-col transition duration-200  ease-in sm:max-w-full">
        <img
          src={src}
          className="group-hover:z-120 m-auto h-[230px] max-h-[200px] w-full
             transform border border-solid border-gray-500 object-cover object-center  transition duration-200  ease-in group-hover:z-50 group-hover:opacity-80 sm:group-hover:scale-105 "
          alt="article"
        />
        <Text className="mt-[8px] text-lg font-semibold decoration-solid line-clamp-2 group-hover:underline">
          {title}
        </Text>
        <Text className="mt-[8px] text-base text-gray-secondary">
          Date: {formatDate(publishDate)}
        </Text>
      </div>
    </a>
  );
};
