import { useRouter } from 'next/router';
import { Box } from '@chakra-ui/react';

import { INavbarConfig } from 'shared/interfaces';

import NavItem from './NavItem';

type Props = {
  navConfig: INavbarConfig[];
  classes?: string;
  handleNavClose: () => void;
};

const NavBar = ({ navConfig, handleNavClose }: Props) => {
  const router = useRouter();

  const { pathname } = router;
  const match = (path: any) => (path ? path === pathname : false);

  return (
    <Box
      textAlign="center"
      height="100%"
      overflow="hidden"
      overflowY="auto"
      width="100%"
    >
      <nav className="flex flex-col items-center justify-center gap-[2.5rem] pb-[3rem]">
        {navConfig.map((item: INavbarConfig) => (
          <NavItem
            onClick={handleNavClose}
            key={item.title}
            item={item}
            active={match}
          />
        ))}
      </nav>
    </Box>
  );
};

export { NavBar };
