import { ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { Button } from 'components/atoms';
import { useTranslation } from 'next-i18next';
import { ForwardedRef, forwardRef } from 'react';
import { PageProps } from 'shared/interfaces';
import { typography } from 'theme';

const CustomerSuccess = forwardRef(
  ({ id }: PageProps, ref: ForwardedRef<HTMLElement>) => {
    const { t } = useTranslation();

    const CUSTOMER_EXPERIENCE = [
      'Product quality',
      'Transparency and accountability',
      'Problem finding and proposal skills',
      'Project management and deadlines',
      'Quality of communication',
    ];

    return (
      <section id={id} ref={ref} className="mb-16 md:mb-16">
        <div className="mb-8 mt-[60px] bg-primary-700 px-[24px] lg:mr-[30px] lg:px-[60px] xl:mr-[60px] 2xl:mr-0 ">
          <div className="flex min-h-fit flex-col justify-center bg-techBg bg-contain bg-center bg-no-repeat py-8 text-white md:min-h-[400px]">
            <Text
              as="h4"
              {...typography.h4}
              className="mb-4 border-l-[6px] pl-4 lg:mb-8"
            >
              {t('Customer Experience (CX) Score')}
            </Text>
            <div className="pl-[22px]">
              <Text className="mb-8" {...typography.mediumBold}>
                {t(
                  'We have established a customer experience score as one of the key indicators of organizational success. We regularly collect feedback from our partners to help us improve.',
                )}
              </Text>
              <UnorderedList>
                {CUSTOMER_EXPERIENCE.map(
                  (measures: string, index: number) => (
                    <ListItem {...typography.normal} key={index}>
                      {t(measures)}
                    </ListItem>
                  ),
                )}
              </UnorderedList>
            </div>
          </div>
        </div>
        <Text className="mb-4" {...typography.normal}>
          {t('Learn more:')}
        </Text>
        <div className="flex flex-col gap-6 md:flex-row">
          <Button type="secondary" btnVarient="link" href="/service">
            {t('About Business Co-Creation')}
          </Button>
          <Button
            className="!w-full lg:!w-auto"
            type="secondary"
            btnVarient="link"
            href="/technology"
          >
            {t('About Technology')}
          </Button>
          <Button type="secondary" btnVarient="link" href="/security">
            {t('About Security')}
          </Button>
        </div>
      </section>
    );
  },
);

export { CustomerSuccess };
