import { Text, useMediaQuery } from '@chakra-ui/react';
import { ServiceFeatureCard } from '../../../molecules/ServiceFeatureCard';
import { useTranslation } from 'next-i18next';
import { ForwardedRef, forwardRef } from 'react';
import { BUSINESS_CO_CREATION } from '../../../../utils/business-cocreation';
import { Button } from '../../../atoms';
import { typography } from '../../../../theme';
import { PageProps } from 'shared/interfaces';

const Servicefeatures = forwardRef(
  ({ id }: PageProps, ref: ForwardedRef<HTMLElement>) => {
    const { t } = useTranslation();
    const [isMobile] = useMediaQuery('(max-width: 767px)');

    const evenItems = BUSINESS_CO_CREATION.filter(
      (_, index) => (index + 1) % 2 === 0,
    );

    const oddItems = BUSINESS_CO_CREATION.filter(
      (_, index) => (index + 1) % 2 === 1,
    );

    const BUSINESS_DATA = isMobile
      ? BUSINESS_CO_CREATION
      : [...oddItems, ...evenItems];

    return (
      <section
        id={id}
        ref={ref}
        className="m-auto mr-0 px-0 pt-8 lg:mr-[30px] xl:mr-[60px] 3xl:mr-0"
      >
        <div className="mb-9 border-l-[6px] border-primary-500 pl-4">
          <Text
            as="h2"
            className=" inline font-Rubik text-2xl font-bold leading-[30px] text-gray-primary lg:text-[32px] lg:leading-[38px]"
          >
            {t('Our Business')}
          </Text>
          <Text
            className="inline font-Rubik text-2xl font-bold leading-[30px] text-primary-500 lg:text-[32px] lg:leading-[38px]"
            as="h2"
          >
            {t(' Co-Creation ')}
          </Text>
          <Text
            className="inline font-Rubik text-2xl font-bold leading-[30px] text-gray-primary lg:text-[32px] lg:leading-[38px]"
            as="h2"
          >
            {t('Features')}
          </Text>
        </div>
        <div className="columns-1 md:columns-2 md:gap-x-[60px]  ">
          {BUSINESS_DATA?.map((item, index) => {
            const hide = item?.count == '06' || item?.count == '07';
            return (
              <ServiceFeatureCard
                key={index}
                withBorder={!hide}
                title={t(`${item?.title}`)}
                count={item?.count}
                description={t(`${item?.description}`)}
              />
            );
          })}
        </div>
        <Text
          as="span"
          className="mb-4 mt-8 block font-Poppins"
          {...typography.normal}
        >
          {t('Learn more:')}
        </Text>
        <div className="mb-16 flex flex-col gap-6 lg:flex-row lg:gap-6">
          <Button
            btnVarient="link"
            href="/technology"
            type="secondary"
          >
            {t('About Technology')}
          </Button>
          <Button btnVarient="link" href="/quality" type="secondary">
            {t('About Quality')}
          </Button>
          <Button btnVarient="link" href="/security" type="secondary">
            {t('About Security')}
          </Button>
        </div>
      </section>
    );
  },
);

export { Servicefeatures };
