import { Progress } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

interface Props {
  isActive: boolean
}
const WorkProgressLoader = ({ isActive }: Props) => {
  const [countdown, setCountdown] = useState(0)
  const [timer, setTimer] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (countdown < 1) {
        setTimer((t) => t + 1)
      }
    }, 1)

    return () => clearInterval(intervalId)
  }, [])

  useEffect(() => {
    setCountdown(timer / 16)
  }, [timer])

  useEffect(() => {
    setCountdown(0)
    setTimer(0)
  }, [isActive])

  return (
    <Progress
      className="work-progress !absolute bottom-0 w-full bg-primary-300"
      value={countdown}
      height={2}
    />
  )
}

export { WorkProgressLoader }
