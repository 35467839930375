import Link from 'next/link';
import { LogoLgLight } from 'public/assets/images/svg';
import { MouseEvent } from 'react';
import { useRouter } from 'next/router';
import { navMenuItems } from 'config/NavBar';
import { useTranslation } from 'next-i18next';

type Props = {
  isNavExpanded?: boolean;
  setNavExpanded: Function;
};

export const HeaderTopPC = ({
  isNavExpanded,
  setNavExpanded,
}: Props) => {
  const router = useRouter();
  const { t } = useTranslation();

  const handleLogoClick = (
    e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>,
  ) => {
    e.preventDefault();
    if (typeof window !== 'undefined') {
      localStorage.removeItem('scroll');
      localStorage.removeItem('expandNews');
    }
    if (router.pathname === '/') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      router.push('/');
    }
  };

  return (
    <nav
      className={`m-auto flex max-w-[1440px] items-center justify-between transition-all ${
        isNavExpanded && 'w-full px-6 py-8 lg:px-[30px] xl:px-[60px]'
      }`}
    >
      <nav
        className="flex w-full cursor-pointer items-center justify-between"
        onClick={() => setNavExpanded(false)}
      >
        <Link href="/" passHref>
          <a onClick={handleLogoClick}>
            <LogoLgLight />
          </a>
        </Link>

        <ul className="flex list-none gap-[25px] xl:gap-[50px]">
          {navMenuItems.map((item) => {
            if (item.type === 'button') {
              return (
                <li key={item.title}>
                  <Link href={item.path || ''} passHref>
                    <a
                      className={`whitespace-pre rounded-[10px] border px-[15px] py-[5px] text-base font-semibold text-gray-secondary ${
                        router.pathname === item.path
                          ? 'underline decoration-[#3767B1] decoration-2 underline-offset-[1rem]'
                          : ''
                      }`}
                    >
                      {t(`${item.title}`)}
                    </a>
                  </Link>
                </li>
              );
            }
            return (
              <li key={item.title}>
                <Link href={item.path || ''} passHref>
                  <a
                    className={`whitespace-pre text-base font-semibold text-gray-secondary ${
                      router.pathname === item.path
                        ? 'underline decoration-[#3767B1] decoration-2 underline-offset-[.6rem]'
                        : ''
                    }`}
                  >
                    {t(`${item.title}`)}
                  </a>
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
      <ul></ul>
    </nav>
  );
};
