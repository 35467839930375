import { useEffect, useState } from 'react';
import {
  Drawer,
  DrawerContent,
  DrawerBody,
  DrawerOverlay,
  useMediaQuery,
} from '@chakra-ui/react';

import { NavBar } from 'components/organisms';
import { navMenuItems } from 'config/NavBar';
import { HeaderTop, HeaderTopPC } from 'components/molecules';
import { useRouter } from 'next/router';

interface IProps {
  isActive: boolean;
}

const Header = ({ isActive }: IProps) => {
  const [isNavExpanded, setNavExpanded] = useState<boolean>(false);
  const [isMobile] = useMediaQuery('(max-width: 1024px)');
  const router = useRouter();
  const [activeId] = useState('');

  const isFooterActive = activeId === 'footer';

  const handleNavExpanded = () => {
    setNavExpanded((prevProps: boolean) => !prevProps);
  };
  const isErrorPages = router.pathname === '/404';

  useEffect(() => {
    if (!isMobile) {
      setNavExpanded(false);
    }
  }, [isMobile]);

  return (
    <div
      className={`z-50 w-full px-6 transition-all lg:px-[30px] xl:px-[60px] ${
        isNavExpanded ? 'bg-gray-900' : 'bg-white'
      } ${isErrorPages ? 'bg-primary-700' : ''} ${
        isErrorPages && isNavExpanded ? '!bg-gray-900' : ''
      } ${isActive ? 'py-6' : 'py-8'}`}
    >
      {isMobile ? (
        !isNavExpanded && (
          <HeaderTop
            handleNavExpanded={handleNavExpanded}
            isFooterActive={isFooterActive || isErrorPages}
            isNavExpanded={isNavExpanded}
            setNavExpanded={setNavExpanded}
          />
        )
      ) : (
        <HeaderTopPC
          isNavExpanded={isNavExpanded}
          setNavExpanded={setNavExpanded}
        />
      )}

      <Drawer
        isOpen={isNavExpanded}
        onClose={() => setNavExpanded(false)}
        placement="top"
        size="full"
        autoFocus={false}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody
            className={`h-screen w-full pb-[5rem] ${
              isNavExpanded ? 'bg-gray-900' : ''
            }`}
          >
            <HeaderTop
              isFooterActive={isFooterActive}
              isNavExpanded={isNavExpanded}
              handleNavExpanded={handleNavExpanded}
              setNavExpanded={setNavExpanded}
            />
            <NavBar
              navConfig={navMenuItems}
              handleNavClose={handleNavExpanded}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export { Header };
