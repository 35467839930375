export const BUSINESS_CO_CREATION = [
  {
    count: "01",
    title: "Fulfilling the essence of needs",
    description: "By incorporating design thinking, adding diverse perspectives from different fields, and through observation and empathy, we gain a correct and deep understanding of users and issues. We identify constraints and use hypothetical thinking to adapt technology to solve business challenges.",
  },
  {
    count: "02",
    title: "Increase synergy",
    description: "We build a foundation of transparency by sharing vision, challenges, data, KPIs, ideas, and insights with the entire team. Including engineers and designers, we participate from the upstream process, enhancing everyone's understanding of the business and fostering an internal sense of ownership through multifaceted measures. We achieve high levels of synergy by having diverse, visionary, and proactive members working together.",
  },
  {
    count: "03",
    title: "Agile experimentation and iteration",
    description: "We challenge uncertainty by incorporating agile development while thoroughly defining and designing requirements using a systematic methodology. We test hypotheses quickly and learn from challenges faced, data and user feedback.",
  },
  {
    count: "04",
    title: "Reduce communication burdens",
    description: "We minimize communication points and practice open communication. This reduces the burden of information transmission and increases its speed and accuracy. We also create a culture of open-mindedness by focusing on the details of communication, creating guidelines, and providing training.",
  },
  {
    count: "05",
    title: "Enhance execution",
    description: "We are a flexible and agile team of professionals specializing in design, development, SRE, testing, operations, customer relations, and other areas for promoting development. All members are involved in the entire lifecycle of business development, including business design and requirement definition.",
  },
  {
    count: "06",
    title: "Make it sustainable",
    description: `Never satisfied with the status quo, we constantly explore and verify the next challenges and possibilities through observation, data analysis, and feedback.\nWe also invest upfront in improving the quality of our technology, processes, and systems to ensure that technical debt does not become a stumbling block to business growth. While striving to minimize liabilities from the initial stage, we promote development in an optimal balance in accordance with the business stage.`,
  },
  {
    count: "07",
    title: "Promote in-house development",
    description: "By sharing and supporting the application of our expertise in building development teams and in hiring and training engineers, we support our partners in building their own in-house development teams.",
  },
]