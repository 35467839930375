export const CLIENTS: { [key: string]: string } = {
    'yomiuri.png': 'Yomiuri',
    'avalon.png': 'Avalon Consulting',
    'union-of-kansai.png': 'Union of Kansai Governments',
    'good-place.png': 'Good Place',
    'forstartups.png': 'for Startups',
    'thebodyshop.png': 'The Body Shop',
    'cospa-creation.png': 'Cospa Creation',
    'jmdc.png': 'JMDC',
    'altura.png': 'ALTURA',
    'web-x.png': 'WEBEX',
    'anywhere.svg': 'AnyWhere',
    'ito.svg': 'ITO Technologies',
    'gras-group.png': 'GRAS Group',
    'kol.png': 'KOL Technologies',
    'coach.png': 'Coach Contribution',
    'ndp.png': 'Japan Direct Promotion',
    'perkup.png': 'PerkUP',
    'pyxis.png': 'Pyxis',
    'bonbon.png': 'BonBon',
    'rascals.png': "RASCAL's",
    'rwd.png': 'Real World Data',
    'wooltrakey.png': 'WoOLTRAKEY',
    'atsuta.png': 'Atsuta',
    'miraiyiu.png': 'Miraiyiu',
    'gracebank.png': 'Grace Bank',
};