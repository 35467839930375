import { ReactNode } from 'react';
import { Button } from '@chakra-ui/react';
interface ButtonProps {
  type: 'primary' | 'secondary' | 'circular';
  htmlType?: any;
  children: ReactNode;
  onClick?: () => void;
  isLoading?: boolean;
  btnVarient?: 'link' | 'Other';
  href?: any;
  className?: string;
  newTab?: boolean;
  disabled?: boolean;
}

const ButtonComponent = ({
  htmlType,
  children,
  onClick,
  isLoading,
  type,
  btnVarient,
  href,
  className,
  newTab,
  disabled,
}: ButtonProps) => {
  return (
    <>
      {btnVarient == 'link' ? (
        <a
          href={disabled ? null : href}
          target={newTab ? '_blank' : '_self'}
          className={`inline-block !w-full text-center lg:!w-auto ${className}${
            type == 'secondary' &&
            ' hover:bg-primary-500 hover:text-white'
          } font-Poppins ${
            type == 'circular' ? 'rounded-full' : 'rounded-btn-rs'
          } border  ${
            type == 'primary' ? 'border-none' : 'border-primary-500'
          } text-base ${
            (type == 'primary' || type == 'circular') &&
            'bg-primary-500'
          } px-8 ${type == 'circular' ? 'py-4' : 'py-4'} ${
            type == 'circular' ? 'px-4' : 'px-8'
          } leading-6 ${
            type == 'primary' || type == 'circular'
              ? 'text-white'
              : 'text-primary-500'
          }  ${
            (type == 'primary' || type == 'circular') &&
            'hover:bg-primary-600'
          } ${
            type == 'primary' || type == 'circular'
              ? ' font-semibold'
              : ' font-normal'
          } ${
            disabled
              ? 'cursor-not-allowed bg-[#8F9BB3] opacity-70'
              : ''
          }`}
        >
          {children}
        </a>
      ) : (
        <Button
          spinnerPlacement="start"
          isLoading={isLoading}
          onClick={onClick}
          className={`!w-full lg:!w-auto ${className} ${
            type == 'secondary' && 'hover:bg-primary-500 '
          } font-Poppins ${
            type == 'circular' ? 'rounded-full' : 'rounded-btn-rs'
          } border  ${
            type == 'primary' ? 'border-none' : 'border-primary-500'
          } text-base ${
            (type == 'primary' || type == 'circular') &&
            'bg-primary-500'
          } px-8 
      ${type == 'circular' ? 'py-4' : 'py-4'}
      ${type == 'circular' ? 'px-4' : 'px-8'}
         leading-6 ${
           type == 'primary' || type == 'circular'
             ? 'text-white'
             : 'text-primary-500'
         }  
       ${
         (type == 'primary' || type == 'circular') &&
         'hover:bg-primary-600'
       } 
         ${type == 'secondary' && 'hover:text-white'}
          
     ${
       type == 'secondary' || type == 'circular'
         ? ' font-normal'
         : ' font-semibold'
     } ${className}

      `}
          type={htmlType}
        >
          {children}
        </Button>
      )}
    </>
  );
};

export { ButtonComponent };
