import { ReactNode } from 'react';
import HealthCareIcon from '../assets/health-care.svg';
import PublicServiceIcon from '../assets/public-service.svg';
import EducationIcon from '../assets/education.svg';
import SportsIcon from '../assets/sports.svg';
import EntertainmentIcon from '../assets/entertainment.svg';
import ServiceIcon from '../assets/service.svg';

export interface SliderProps {
  title: string;
  description: string;
  icon: ReactNode;
}

const WORKS_BG = [
  'health-care.mp4',
  'public-service.mp4',
  'edu.mp4',
  'sports.mp4',
  'entertainment.mp4',
  'service.mp4',
];

const WORKS_SLIDERS = [
  {
    title: 'Health Care',
    description: 'PHR, Fitness, Pathology',
    icon: <HealthCareIcon />,
  },
  {
    title: 'Public Service',
    description: 'Regional Activation, Local Community, Media Site',
    icon: <PublicServiceIcon />,
  },
  {
    title: 'Education',
    description: 'Online Course SaaS',
    icon: <EducationIcon />,
  },
  {
    title: 'Sports',
    description: 'DX Platform for Trainers, Community',
    icon: <SportsIcon />,
  },
  {
    title: 'Entertainment',
    description: 'Live Streaming, Skill Sharing',
    icon: <EntertainmentIcon />,
  },
  {
    title: 'Service Industry',
    description: 'Sales DX Platform, Sales Matching, Space Sharing',
    icon: <ServiceIcon />,
  },
] as SliderProps[];

export { WORKS_BG, WORKS_SLIDERS };
