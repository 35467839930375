import { Text } from '@chakra-ui/react';

interface Props {
  title: string;
  children: any;
  withMargin?: boolean;
}

const TechFeature = ({ title, children, withMargin }: Props) => {
  return (
    <section
      className={`m-auto mt-[60px] ${
        withMargin ? 'mr-0 xl:mr-[60px]' : 'max-w-[1084px]'
      } flex max-w-none items-center bg-primary-700 bg-techBg bg-contain bg-center bg-no-repeat py-8 sm:ml-auto md:mt-8 lg:min-h-[530px] lg:py-10 xl:bg-contain 2xl:mr-0 2xl:max-w-full`}
    >
      <div className="items-unset m-auto flex w-full max-w-[961px] flex-col gap-8 px-6 md:flex-row md:items-center lg:gap-[60px]">
        <Text
          as="h3"
          className="min-w-fit whitespace-pre-line font-Rubik text-[40px] font-bold leading-[48px] text-white sm:min-w-[350px] md:text-5xl md:leading-big"
        >
          {title}
        </Text>
        <hr className="block border border-white md:hidden" />
        <div className="border-white pl-0 md:max-w-[554px] md:border-l-[2px] md:pl-[60px]">
          {children}
        </div>
      </div>
    </section>
  );
};

export { TechFeature };
