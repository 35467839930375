import {ReactNode} from 'react';
import {  Text, Textarea } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

interface InputProps {
  value?: string;
  isInvalid?: boolean;
  errorMessage?: string;
  onChange?: any;
  name?: string;
  icon?: ReactNode;
  label?: string;
  required?: boolean;
  placeholder?: string;
  id?: string;
  rest?: any;
}

const TextArea = ({
  value,
  onChange,
  errorMessage,
  isInvalid,
  id,
  name,
  icon,
  label,
  required,
  placeholder,
  rest,
}: InputProps) => {
  const { t } = useTranslation();

  return (
    <div className="mb-6">
      <div className="flex items-center">
        {icon}
        <Text
          as="label"
          className={`
           ${icon && 'ml-[10px]'}
        inline font-Poppins text-[18px] font-normal leading-[26px] text-gray-primary`}
        >
          {label}
        </Text>
        {required && (
          <Text
            as="span"
            className="pl-[3px] font-Poppins text-[18px] font-normal leading-[26px] text-gray-secondary"
          >
            {t('(Required)')}
          </Text>
        )}
      </div>
      <Textarea
        {...rest}
        id={id}
        rows={5}
        w="100%"
        focusBorderColor="#960912"
        name={name}
        value={value}
        onChange={onChange}
        isInvalid={isInvalid}
        errorBorderColor="#960912"
        placeholder={placeholder}
        _placeholder={{
          color: '#8F9BB3',
          fontFamily: 'Poppins',
          fontSize: '16px',
          lineHeight: '24px',
        }}
        className={`mt-3 rounded-lg border p-4 ${
          !isInvalid && '!border-gray-secondary'
        } focus:border focus:border-primary-500 focus:outline-2 focus:outline-primary-400`}
      />
      {errorMessage && (
        <Text className="text-[#960912]" height="25px" as="p">
          {errorMessage}
        </Text>
      )}
    </div>
  );
};

export default TextArea;
