import { Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { ForwardedRef, forwardRef } from 'react';
import { PageProps } from 'shared/interfaces';
import { typography } from 'theme';

const DevelopmentTeam = forwardRef(
  ({ id }: PageProps, ref: ForwardedRef<HTMLElement>) => {
    const { t } = useTranslation();
    const router = useRouter();

    return (
      <section
        id={id}
        ref={ref}
        className="mr-0 mb-[60px] md:mb-16 lg:mr-[30px] xl:mr-[60px] 3xl:mr-0"
      >
        <div className="border-l-[6px] border-primary-500 pl-4 text-gray-primary">
          <Text
            as="h4"
            className="mb-4 text-2xl font-bold leading-8 md:text-medium md:leading-medium"
          >
            {t('Development Team')}
          </Text>
          <Text
            className="text-gray-secondary"
            {...typography.normal}
          >
            {t(
              'Optimized teams will be formed according to the business phase and scale of development. If necessary, QA, R&D, CS, and other teams will also participate to promote development through collaboration.',
            )}
          </Text>
        </div>
        <div className="relative m-auto my-10 h-[147px] max-w-[620px] sm:h-[264px]">
          <Image
            src={
              router.locale === 'ja'
                ? '/development_team_ja.svg'
                : '/development_team.svg'
            }
            layout="fill"
          />
        </div>

        <div className="border-l-[6px] border-primary-500 pl-4 text-gray-primary">
          <Text
            as="h4"
            className="mb-4 text-2xl font-bold leading-8 md:text-medium md:leading-medium"
          >
            {t('Others')}
          </Text>
          <Text
            className="text-gray-secondary"
            {...typography.normal}
          >
            {t(
              'We develop our products systematically and with attention to detail through numerous achievements and repeated improvements. We embed performance, accessibility, data collection and visualization, SEO, and more from the requirements stage.',
            )}
          </Text>
        </div>
      </section>
    );
  },
);

export { DevelopmentTeam };
