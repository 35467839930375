import SysDevIcon from '../assets/systemdev.svg';
import DXIcon from '../assets/dx.svg';
import PoCIcon from '../assets/poc.svg';
import InhouseIcon from '../assets/inhouse.svg';

export const SERVICES_OFFERED = [
    {
        title: "System Development",
        description: "Our company develops high-quality web applications that support corporate growth. We prioritize user-centered UI/UX design, offering responsive websites and applications compatible with both iOS and Android. Leveraging the latest technology stack, we build solutions optimized for your business needs.",
        icon: SysDevIcon,
        link: "/system-development"
    },
    {
        title: "DX Promotion Support",
        description: "We support the promotion of Digital Transformation (DX) by utilizing digital technology to enhance operational efficiency and create new value.\nOur experienced team works closely with you, addressing on-the-ground challenges and providing end-to-end support, from business process improvements and system implementation to data utilization.\nWe are dedicated to walking alongside your organization to achieve meaningful transformation.",
        icon: DXIcon,
        link: "/dx-promotion-support"
    },
    {
        title: "PoC/MVP Co-Development",
        description: "We specialize in co-developing Proof of Concept (PoC) and Minimum Viable Product (MVP) for new ventures and projects.\nOur approach allows for rapid validation of market adaptability, helping to identify and address challenges early on.\nBy eliminating inefficiencies, we take the optimal first step together toward a successful outcome.",
        icon: PoCIcon,
    },
    {
        title: "In-House Development Support",
        description: "We excel in developing Proof of Concept (PoC) and Minimum Viable Product (MVP) for the launch of new businesses and projects.\nOur approach quickly validates market adaptability, identifying and addressing challenges early for continuous improvement.\nBy reducing inefficiencies, we take the optimal first step toward success together.",
        icon: InhouseIcon,
    },
]