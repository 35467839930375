import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

interface DialogProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export const Dialog = ({
  isOpen,
  onClose,
  children,
}: DialogProps) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay className="z-[100] bg-[#000]/80" />
      <ModalContent className="flex flex-col items-center justify-center">
        <ModalBody className="px-2">
          <ModalCloseButton className="mb-2 ml-auto text-white" />
          {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
