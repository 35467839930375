import { useTranslation } from 'next-i18next';

interface BulletPointsProps {
  items: string[];
  align?: 'center' | 'start';
}
const Bullet = () => {
  return (
    <div className="h-3 w-3 shrink-0 rounded-full bg-[#e4e9f2]" />
  );
};

export const BulletPoints = ({
  items,
  align = 'center',
}: BulletPointsProps) => {
  const { t } = useTranslation();
  return (
    <div className="mb-8 space-y-3">
      {items?.map((item, index) => (
        <div
          className={`flex space-x-2 ${
            align === 'start' ? 'items-baseline' : 'items-center'
          }`}
          key={index}
        >
          <Bullet />

          <p className="whitespace-pre-wrap text-gray-primary lg:text-lg">
            {t(item)
              .split('\n')
              .map((line, index) => (
                <span
                  key={index}
                  className={
                    index === 0
                      ? 'text-gray-primary'
                      : 'text-gray-secondary'
                  }
                >
                  {line}
                  {index < t(item).split('\n').length - 1 && <br />}
                </span>
              ))}
          </p>
        </div>
      ))}
    </div>
  );
};
