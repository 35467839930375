import React, { ForwardedRef, forwardRef } from 'react';
import { useTranslation } from 'next-i18next';
import { Text } from '@chakra-ui/react';

import { typography } from 'theme';
import { Button } from 'components/atoms';

import { PageProps } from 'shared/interfaces';

export const Infrastructure = forwardRef(
  ({ id }: PageProps, ref: ForwardedRef<HTMLElement>) => {
    const { t } = useTranslation();

    return (
      <section
        id={id}
        ref={ref}
        className="mr-0 mt-16 lg:mr-[30px] xl:mr-[60px] 2xl:mr-0 3xl:mr-0"
      >
        <div className="md:max-w-desktop 2xl:w-full 3xl:max-w-full">
          <div className="border-l-[6px] border-[#3767B1] md:pl-[16px]">
            <Text
              {...typography.fontPoppins}
              className="pl-[16px]  text-2xl font-bold md:border-l-0 md:border-none md:pl-0 md:text-3xl"
            >
              {t('Example of a Simple System')}
            </Text>
            <Text className="mt-[16px] pl-[16px] md:pl-0">
              {t('Features of example configurations :')}
            </Text>
          </div>
          <div className="mt-[32px] flex flex-col justify-between gap-[32px] bg-[#EAF4FD] p-[24px] md:flex-row">
            <div>
              <div className="font-semibold text-primary-500">1.</div>
              <div>
                {t(
                  'The serverless environment is secure, scalable, and requires less server construction/operation.',
                )}
              </div>
            </div>
            <div>
              <div className="font-semibold text-primary-500">2.</div>
              <div>
                {t(
                  'Monitoring and error tracking allow early detection of problems and efficient response.',
                )}
              </div>
            </div>
            <div>
              <div className="font-semibold text-primary-500">3.</div>
              <div>
                {t(
                  'Separation of authentication/authorization, API, and front office makes each of these independent and easy to maintain.',
                )}
              </div>
            </div>
          </div>
          <div className="mt-[60px] flex justify-center">
            <img
              height={609}
              width={1079}
              src={`${process.env.NEXT_PUBLIC_STATIC_URL}/assets/images/svg/system-diagram.png`}
              alt={t('Infrastructure image')}
            />
          </div>
          <div className="mt-[32px] mb-4">
            <Text>{t('Learn more about our services:')}</Text>
            <div className="mt-[15px] flex flex-col gap-[1.5rem] lg:flex-row">
              <Button
                btnVarient="link"
                href="/service"
                type="secondary"
              >
                {t('About business co-Creation')}
              </Button>
              <Button
                btnVarient="link"
                href="/quality"
                type="secondary"
              >
                {t('About Quality Assurance')}
              </Button>
              <Button
                btnVarient="link"
                href="/security"
                type="secondary"
              >
                {t('About Security')}
              </Button>
            </div>
          </div>
        </div>
      </section>
    );
  },
);
