import { Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { ForwardedRef, forwardRef } from 'react';
import { PageProps } from 'shared/interfaces';
import { typography } from '../../../../theme';
import { Button } from '../../../atoms';
import { useRouter } from 'next/router';
import Marquee from 'react-fast-marquee';
import { Trans } from 'react-i18next';
import { CLIENTS } from 'utils/clients';

const CoCreation = forwardRef(
  ({ id, videoID }: PageProps, ref: ForwardedRef<HTMLElement>) => {
    const { t } = useTranslation();
    const { locale } = useRouter();

    const settings = {
      pauseOnHover: true,
      gradient: false,
      speed: 35,
    };
    return (
      <section
        ref={ref}
        id={id}
        className="flex flex-col max-w-xl m-auto mt-4 sm:mt-8"
      >
        <div className="mb-6">
          <h1>
            {locale?.startsWith('ja') ? (
              <>
                <Text
                  as="p"
                  className={`inline font-Rubik text-4xl font-bold leading-snug md:text-8xl md:leading-tight`}
                  style={{
                    display: 'block',
                    backgroundImage:
                      'linear-gradient(0deg, rgba(52, 168, 83, 1) 0%, rgba(66, 133, 244, 1) 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  機能開発を越えて、
                </Text>
                <Text
                  as="p"
                  className={`inline font-Rubik text-4xl font-bold leading-snug md:text-8xl md:leading-tight`}
                  style={{
                    display: 'block',
                    backgroundImage:
                      'linear-gradient(0deg, rgba(52, 168, 83, 1) 0%, rgba(66, 133, 244, 1) 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  事業を共創。
                </Text>
              </>
            ) : (
              <>
                <Text
                  as="p"
                  className={`inline font-Rubik text-3xl font-bold md:text-7xl`}
                  style={{
                    display: 'block',
                    backgroundImage:
                      'linear-gradient(0deg, rgba(52, 168, 83, 1) 0%, rgba(66, 133, 244, 1) 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  Beyond Feature Development
                </Text>
                <Text
                  as="p"
                  className={`inline font-Rubik text-3xl font-bold md:text-7xl`}
                  style={{
                    display: 'block',
                    backgroundImage:
                      'linear-gradient(0deg, rgba(52, 168, 83, 1) 0%, rgba(66, 133, 244, 1) 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  Towards Business
                </Text>
                <Text
                  as="p"
                  className={`inline font-Rubik text-3xl font-bold md:text-7xl`}
                  style={{
                    display: 'block',
                    backgroundImage:
                      'linear-gradient(0deg, rgba(52, 168, 83, 1) 0%, rgba(66, 133, 244, 1) 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  Co-Creation
                </Text>
              </>
            )}
          </h1>
        </div>
        <Text
          as="p"
          className="mb-6 mr-0 font-Poppins font-medium text-gray-secondary lg:mr-[30px] xl:mr-[60px] 3xl:mr-0"
          {...typography.medium}
        >
          {t(
            'Together, we effectively confront technological innovation and the business environment, both of which are increasingly fluid and complex.',
          )}
        </Text>

        {videoID !== 0 && (
          <video
            playsInline
            muted
            height={'100%'}
            width={'1144px'}
            role="video"
            className="3xl:w-full"
            src={`${process.env.NEXT_PUBLIC_STATIC_URL}/videos/service${videoID}.mp4`}
            controls={false}
            autoPlay={true}
            loop={true}
          />
        )}

        <div className="mt-8 mb-4 mr-0 lg:mr-[30px] xl:mr-[60px] 3xl:mr-0">
          <Text
            as={'h2'}
            className="font-Rubik text-2xl font-bold leading-[30px] text-gray-primary lg:text-medium lg:leading-[38px]"
          >
            {t(
              'Promote business development and DX from the technological domain',
            )}
          </Text>
        </div>
        <Text
          as="p"
          className="mb-8 mr-0 text-left font-Poppins text-gray-secondary lg:mr-[30px] xl:mr-[60px] 3xl:mr-0"
          {...typography.medium}
        >
          {t(
            'We build relationships as one team across organizational boundaries. From a technological domain that is the closest in its form to in-house production, we accompany business growth with business design, PMF and PoC through prototype and MVP development, main development, and post-release operations.',
          )}
        </Text>
        <Text
          as="span"
          className="block mb-4 font-Poppins"
          {...typography.normal}
        >
          {t('Learn more:')}
        </Text>
        <div className="mb-16 flex flex-col gap-6  md:!gap-6 lg:flex-row lg:gap-8">
          <Button
            className="!w-full lg:!w-auto"
            btnVarient="link"
            href="/service"
            type="secondary"
          >
            {t('About Services')}
          </Button>
          <Button
            className="!w-full lg:!w-auto"
            btnVarient="link"
            type="secondary"
            href={'/technology'}
          >
            {t('About Technology')}
          </Button>
          <Button
            className="!w-full lg:!w-auto"
            btnVarient="link"
            href="/quality"
            type="secondary"
          >
            {t('About Quality')}
          </Button>
          <Button
            className="!w-full lg:!w-auto"
            btnVarient="link"
            href="/security"
            type="secondary"
          >
            {t('About Security')}
          </Button>
        </div>

        <div className="mb-[200px] grid justify-items-center md:mb-[220px]">
          <div className="absolute left-0 right-0 translate-y-0">
            <Text
              as="p"
              align="center"
              className="logo-marquee m-auto mb-[30px] max-w-[320px] font-Poppins sm:max-w-[590px]"
              {...typography.normal}
            >
              <Trans i18nKey="We are working with many startups, government, and companies to develop businesses and promote digitalization(DX)." />
            </Text>
            <Marquee className="" {...settings}>
              {Object.keys(CLIENTS).map((logo, idx) => (
                <div key={idx} className="h-12 mx-8">
                  <img
                    src={`${process.env.NEXT_PUBLIC_STATIC_URL}/client-logo/${logo}`}
                    className="h-full"
                    alt={t(CLIENTS[logo])}
                  />
                </div>
              ))}
            </Marquee>
          </div>
        </div>
      </section>
    );
  },
);

export { CoCreation };
