import Discovery from 'public/discovery.svg';
import Automation from 'public/automation.svg';
import Growth from 'public/growth.svg';
import Monitoring from 'public/monitoring.svg';

export const OPERATION_LIST = [
  {
    logo: <Automation />,
    title: 'Automation',
    description:
      'Maximize automation of tasks such as vulnerability discovery, testing, releases, infrastructure building, etc. Development teams spend their time on value-added tasks.',
  },
  {
    logo: <Monitoring />,
    title: 'Monitoring',
    description:
      'Monitor failures and errors automatically and provide clues to the development team for investigation. Early detection and response to problems prevents lost opportunities.',
  },
  {
    logo: <Growth />,
    title: 'Business Growth',
    description:
      'Define KPIs for the business, subdividing them in a KPI tree and focusing on key numbers according to the stage of the business.',
  },
  {
    logo: <Discovery />,
    title: 'Issue Discovery',
    description:
      'Data visualization and analysis, user observation, and feedback will help us deepen our understanding of users and discover issues that need to be addressed.',
  },
];
