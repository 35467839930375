import { Text } from '@chakra-ui/react';

interface ICoreProps {
  code?: string;
  title?: string;
  description?: string;
}

export const CoreSection = ({
  code,
  title,
  description,
}: ICoreProps) => {
  return (
    <div className="mb-8 8 lg:mb-12 lg:flex lg:gap-4">
      <Text
        className="font-Poppins text-lg font-semibold leading-[26px] text-primary-300"
        as="h3"
      >
        {code}
      </Text>
      <div className="  lg:flex lg:gap-[60px]">
        <div className="flex border-b-4 border-primary-500 pb-4 lg:min-w-[400px] lg:max-w-[400px] ">
          <Text className="whitespace-pre-line font-Rubik text-[32px] font-bold capitalize leading-[38px] text-gray-primary">
            {title}
          </Text>
        </div>
        <Text className="mt-4 font-Poppins text-gray-secondary md:max-w-full lg:mt-0 lg:max-w-[560px] 2xl:max-w-none">
          {description}
        </Text>
      </div>
    </div>
  );
};
