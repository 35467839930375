import { INews } from 'shared/interfaces/news';
import Link from "next/link";

export const NEWS: INews[] = [
  {
    id: '43',
    title: 'Release of "Nenai Nyushi Navi" – A Platform Supporting University Admissions Through Comprehensive Selection and Recommendation Exams',
    thumbnail: 'nennai-nyushi-navi-released.png',
    cover: 'nennai-nyushi-navi-released-cover.png',
    link: '/news/43',
    slug: 'nennai-nyushi-navi-released',
    meta: {
      title:
        'Release of "Nenai Nyushi Navi" – A Platform Supporting University Admissions Through Comprehensive Selection and Recommendation Exams - News - wesionaryTEAM',
      keywords: 'University system, Education system, Portal site, matching system, New business co-creation',
      description: 'In collaboration with Avalon Consulting Co., Ltd., we have released "Nenai Nyushi Navi," a platform that creates new connections between students and universities. If you\'re considering developing educational services, matching systems, or new business ventures, please contact us.',
    },
    description: {
      en: (
        <div className="grid gap-4">
          <p>
            We are pleased to announce the release of "Nenai Nyushi Navi," a new university admissions support platform developed in collaboration with Avalon Consulting Co., Ltd. This platform is designed to connect high school students interested in comprehensive selection and recommendation-based admissions with universities that align with their academic goals. By addressing the needs of both students and universities, "Nenai Nyushi Navi" opens up new possibilities for making informed and effective decisions about university admissions.
          </p>

          <br/>
          <p className={`font-semibold`}>Challenges Faced by Our Clients and Project Background</p>
          <p>
            This platform was developed to address the challenges faced by each student and university. Below are the main challenges faced by students and universities.
          </p>
          <ul>
            <li>Challenges for Students in Choosing the Right University</li>
            <ul>
              <li>Modern high school students seek a variety of options for their future education. However, they often struggle with the lack of information about non-traditional admission methods such as comprehensive selection or recommendation-based exams.</li>
              <li>Even when students know what field they want to study, finding the right university that aligns with their interests can be challenging due to limited resources and guidance.</li>
            </ul>
            <li>Challenges for Universities: Reaching the Right Student Pool</li>
            <ul>
              <li>Universities, on the other hand, face the challenge of efficiently attracting students who are a good match for their curriculum and educational philosophy while preventing mismatches after enrollment.</li>
              <li>Traditional recruitment methods have made it difficult for universities to directly reach and engage with their ideal student candidates, leading to inefficient outreach efforts.</li>
            </ul>
          </ul>


          <br/>
          <p className={`font-semibold`}>Features and Expected Benefits of the Platform</p>
          <p>
            "Nenai Nyushi Navi" is a matching platform aimed at students looking to identify the best universities through comprehensive selection or recommendation-based exams. It also provides universities with an effective way to recruit talented students who meet their academic criteria.
          </p>
          <ul>
            <li>Features for High School Students:</li>
            <ul>
              <li>Quickly and easily find universities that offer comprehensive selection and recommendation-based admissions.</li>
              <li>Access tools to easily compare universities based on desired fields of study and career aspirations.</li>
              <li>Receive special admission offers from universities that are a strong match for your academic and career goals.</li>
            </ul>
            <li>Features for Universities:</li>
            <ul>
              <li>Efficiently recruit students whose goals align with the university’s curriculum.</li>
              <li>Utilize direct messaging to registered students, enhancing the recruitment process.</li>
              <li>Provide targeted and relevant information to students based on their academic preferences and needs.</li>
            </ul>
          </ul>

          <p className={`font-semibold`}>Reception and Future Outlook for the Platform</p>
          <p>
            The number of universities and admission opportunities featured on "Nenai Nyushi Navi" continues to grow, as does the number of students using the platform. Moving forward, we aim to enhance the platform’s features and usability based on user data and feedback. Our goal is to continuously evolve the platform into a more comprehensive tool that connects a growing number of students and universities, building trust and delivering new value in the admissions process.
          </p>


          <br/>
          <p>If your organization is exploring the development of educational services, matching systems, or new business ventures, please feel free to reach out to us. Through thorough research and prototyping, we can clarify the value and functionality your project needs and work together toward its realization.</p>
          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                Contact Us
              </a>
            </Link>
          </p>
        </div>
      ),
      ja: (
        <div className="grid gap-4">
          <p>
            当社は、Avalon Consulting株式会社と共同で、新たな進学支援プラットフォーム「年内入試ナビ」をリリースしました。このプラットフォームは、総合型選抜や公募推薦を希望する高校生と、優良な大学を効果的にマッチングさせることを目的としています。大学と学生のニーズに応じた最適な進学先選びを提供し、進路選択の新たな可能性を広げます。
          </p>

          <br/>
          <p className={`font-semibold`}>大学・学生の抱える課題とプロジェクトの背景</p>
          <p>
            本プラットフォームは、学生と大学それぞれが抱える課題を解決するために開発しました。以下に、学生と大学が直面する主な課題です。
          </p>
          <ul>
            <li>学生の課題：進学先の選択肢に対する学生の悩み</li>
            <ul>
              <li>現代の高校生は、将来の進路に多様な選択肢を求めていますが、特に一般受験以外の総合型選抜や推薦入試についての情報が不足していることに悩んでいます。</li>
              <li>学びたい分野が明確でも、それに合った大学を見つけるための情報を得る手段が限られており、多くの学生は希望する進学先の選定に難しさを感じています。</li>
            </ul>
            <li>大学の課題：最適な学生層との出会い</li>
            <ul>
              <li>大学側も、自校のカリキュラムや教育理念に合った学生を効率的に募り、入学後のミスマッチを防ぐための手段を求めています。</li>
              <li>従来の方法では大学側から最適な学生に直接アプローチすることが難しく、効率的な募集活動ができていません。</li>
            </ul>
          </ul>

          <br/>
          <p className={`font-semibold`}>プロダクトの特長と期待される効果</p>
          <p>
            「年内入試ナビ」は、総合型選抜や推薦入試を利用して自分に最適な大学を見つけたいと考えている学生をターゲットとしたマッチングプラットフォームです。大学側も、希望する学生層にアプローチできる仕組みを通じて、効率的に優秀な学生を募ることが可能です。
          </p>
          <ul>
            <li>高校生向けの特長</li>
            <ul>
              <li>総合型選抜や推薦入試に対応した大学を一発検索で発見可能。</li>
              <li>学びたい分野や進路に応じた大学を簡単に比較できるツールを提供。</li>
              <li>自分に合った大学から特別選考のオファーを受け取れる。</li>
            </ul>
            <li>大学向けの特長</li>
            <ul>
              <li>カリキュラムにマッチした学生を効率的にリクルート可能。</li>
              <li>登録学生へのダイレクトメッセージ機能を搭載し、選抜過程を強化。</li>
              <li>学生の志向やニーズに応じた適切な情報提供が可能。</li>
            </ul>
          </ul>

          <p className={`font-semibold`}>プロダクトに寄せられた評価と今後の展望</p>
          <p>
            「年内入試ナビ」に掲載する大学と入試情報、そして学生が増えて続けています。今後は、利用データとフィードバックに基づいて、更なる機能追加や利便性向上を図り、より多くの学生と大学をつなぐプラットフォームとして進化させていく予定です。両者の信頼性を高め、進学における新たな価値を提供します。
          </p>

          <br/>
          <p>教育サービスの開発、マッチングシステム、新規事業の開発をお考えの際は、ぜひお問い合わせください。リサーチやプロトタイピングを通じて、提供価値や必要な機能を明確化し、共に実現に向けた取り組みを進めていきます。</p>
          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                お問い合わせフォームへ
              </a>
            </Link>
          </p>
        </div>
      ),
    },
    date: '2024 Oct',
    label: 'New business co-creation',
  },
  {
    id: '44',
    title: 'Selected as One of the Top 5 Companies in the System Development Category',
    thumbnail: 'kanji-award.png',
    cover: 'kanji-award-cover.png',
    link: '/news/44',
    slug: 'kanji-award',
    meta: {
      title:
        'Selected as One of the Top 5 Companies in the System Development Category - News - wesionaryTEAM',
      keywords: 'Top System Development Company, 2024 Award Winner, Product Co-Creation, New Business Development, Digital Transformation, DX',
      description: 'We have been selected as one of the top 5 companies in the System Development category by Utill Co., Ltd. as part of their "System Kanji" service. The selection is based on a rigorous process using proprietary metrics such as "number of successful contracts" and "customer satisfaction," out of 14,949 companies. If you\'re considering new business development, analyzing and optimizing business processes, or digital transformation (DX), please feel free to contact us.',
    },
    description: {
      en: (
        <div className="grid gap-4">
          <p>
            We are proud to announce that our company has been selected as one of the top 5 companies in the System Development category by Utill Co., Ltd. as part of their "System Kanji" service. The "Kanji AWARD 2024" recognizes outstanding companies, chosen through a rigorous selection process based on proprietary metrics such as "number of successful contracts" and "customer satisfaction," from a pool of 14,949 companies, using data from January 2023 to June 2024.
          </p>

          <br/>
          <p className={`font-semibold`}>
            <a
              href="https://prtimes.jp/main/html/rd/p/000000045.000016988.html"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >[Kanji AWARD 2024] Recognizing 26 Leading Companies in Website Development, Video Production, and System Development</a>
          </p>

          <br/>
          <p>
            At our company, we consider it our mission to create meaningful products that live up to the trust, budget, and time our clients invest in us.
          </p>
          <p>
            In order to deliver more effective products grounded in positive experiences and processes, we are committed to the concept of co-creation, with the vision of creating a society where co-creation is the norm.
          </p>
          <p>
            Though the journey ahead remains long and we continue to face new challenges at every turn, we are inspired by this pursuit and embrace each challenge as an opportunity to grow and move forward.
          </p>
          <p>
            Being recognized with this award has validated our efforts and further strengthened the confidence of our entire team.
          </p>
          <p>
            We are grateful to System Kanji for connecting us with many of our valued clients and see this recognition as a driving force to work even harder moving forward.
          </p>

          <br/>
          <p>If you're considering new business development, analyzing and optimizing business processes, digital transformation (DX), or enhancing existing systems, please feel free to contact us. Through systematic research and prototyping methods, we will clarify the challenges and necessary functionalities, working together to achieve your goals.</p>
          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                Contact Us
              </a>
            </Link>
          </p>
        </div>
      ),
      ja: (
        <div className="grid gap-4">
          <p>
            当社は、株式会社ユーティルが提供する「システム幹事」において、「システム開発部門」の優良企業5社に選出されました。「幹事AWARD2024」は、2023年1月から2024年6月までのデータを基に、14,949社の中から「ご成約数」「顧客満足度」などの独自指標を元に厳正な審査を経て選ばれた企業が表彰されています。
          </p>

          <br/>
          <p className={`font-semibold`}>
            <a
              href="https://prtimes.jp/main/html/rd/p/000000045.000016988.html"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >【幹事AWARD2024】ホームページ・動画制作、システム開発の優良企業26社を発表</a>
          </p>

          <br/>
          <p>
            当社は、お客様から預かる貴重なご予算やお時間、そして信頼に応えるため、プロダクトを意義あるものにすることを使命としています。
          </p>
          <p>
            より効果的なプロダクトを良い体験とプロセスに基づいて提供するため、『共創』という手法にこだわり、ビジョンとして『共創があたりまえの社会へ』を掲げています。
          </p>
          <p>
            道のりはまだ長く、進むたびに新たな課題に直面しますが、この探求に刺激を受け、挑戦を楽しみながら前進しています。
          </p>
          <p>
            アワードを受賞したことで、私たちの取り組みが評価されたと感じると同時に、チーム全員にさらなる自信をもたらしました。
          </p>
          <p>
            これを励みに、一層努力を重ねてまいります。たくさんのお客様とのご縁を導いてくださったシステム幹事様に感謝申し上げます。
          </p>

          <br/>
          <p>新規事業開発、業務プロセスの分析と最適化、デジタルトランスフォーメーション（DX化）、既存事業のシステム強化などをお考えの場合、お気軽にお問い合わせください。体系化された手法によるリサーチやプロトタイピングを通じて、取り組むべき課題や必要な機能を明確化し、共に実現に向けた取り組みを進めていきます。</p>
          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                お問い合わせフォームへ
              </a>
            </Link>
          </p>
        </div>
      ),
    },
    date: '2024 Oct',
    label: 'Notice',
  },
  {
        id: '41',
        title: 'Launch of \'Agents,\' a Specialized Job Matching Service for Taxi Drivers and Security Staff Recruitment',
        thumbnail: 'agents-released.png',
        cover: 'agents-released-cover.png',
        link: '/news/41',
        slug: 'agents-released',
        meta: {
            title:
                'Launch of \'Agents,\' a Specialized Job Matching Service for Taxi Drivers and Security Staff Recruitment - News - wesionaryTEAM',
            keywords: 'Talent Matching System, Industry-Specific Recruitment System, New Business Development, Co-Creation of Products',
            description: '\'Agents,\' co-released with Miraiyu Co., Ltd., is an industry-specific job matching platform for taxi drivers and security staff. It connects companies and agencies to enhance talent mobility within the industry. If you are considering developing a recruitment service, a matching system, or launching a new business, please contact us.',
        },
        description: {
            en: (
                <div className="grid gap-4">
                    <p>
                        We are pleased to announce the release of the 'Agents' platform, developed in collaboration with
                        Miraiyu Co., Ltd. This platform is specifically designed to connect job-seeking candidates in
                        industries such as taxi driving and security services with recruitment agencies and employers.
                        The goal is to streamline the hiring process and enhance talent mobility within these sectors.
                    </p>

                    <br/>
                    <p className={`font-semibold`}>Challenges Faced by Our Client and Project Background</p>
                    <p>
                        Miraiyu Co., Ltd. has been providing industry-specific career support for taxi drivers and
                        security staff. However, they faced a significant challenge in meeting the diverse talent needs
                        of their clients through existing job listings. Recognizing the need for a broader talent
                        matching solution, the collaboration with agents became essential to address these limitations
                        and expand the talent pool.
                    </p>


                    <br/>
                    <p className={`font-semibold`}>Key Features and Expected Benefits</p>
                    <p>
                        'Agents' is a platform where employers can post job openings and work with recruitment agencies to connect with the most suitable candidates. The platform is expected to accelerate the hiring process across the industry through the following features:
                    </p>
                    <ul>
                        <li>Employers: Utilize job posting and editing features, as well as messaging capabilities with agencies, to approach a wide range of candidates.</li>
                        <li>Agencies: Leverage job search and bookmarking tools to propose the best job matches to candidates. Agencies can also manage the entire matching and selection process in one place.</li>
                        <li>Platform Operators: Offer user management, tracking of matching progress, and algorithm optimization based on data analysis.</li>
                    </ul>

                    <p>Although the platform has just launched, we anticipate it will enable employers to reach a wider pool of candidates and streamline the hiring process through effective collaboration with agencies.</p>

                    <br/>
                    <p>If you are considering developing a recruitment service, a matching system, or launching a new business, please feel free to contact us. Through research and prototyping, we will work with you to clarify the value and functionality needed to make your vision a reality.</p>
                    <p>
                        <Link href="/contact" passHref>
                            <a
                                className={`text-sm hover:underline md:self-end`}
                            >
                                Contact Us
                            </a>
                        </Link>
                    </p>
                </div>
            ),
            ja: (
                <div className="grid gap-4">
                    <p>
                        当社は、株式会社ミライユ様と共に、タクシードライバーや警備スタッフなど特定業界に特化した人材マッチングプラットフォーム『Agents』をリリースいたしました。このプラットフォームは、求人企業と人材紹介エージェントを効果的に結びつけ、業界全体の人材流通を活性化することを目的としています。
                    </p>

                    <br/>
                    <p className={`font-semibold`}>顧客企業の抱える課題とプロジェクトの背景</p>
                    <p>
                        株式会社ミライユ様は、タクシードライバーや警備スタッフの採用を支援するため、業界特化型の転職支援サービスを展開していましたが、既存の求人情報だけでは多様な人材ニーズに応えることが難しいという課題に直面していました。そのため、エージェントと連携し、より広範な人材マッチングを可能にする新たなプラットフォームの必要性が高まっていました。
                    </p>


                    <br/>
                    <p className={`font-semibold`}>プラットフォームの特長と期待している効果</p>
                    <p>
                        『Agents』は、求人企業が自社求人を掲載でき、エージェントを通じて最適な人材とマッチングできるプラットフォームです。以下の機能と特長により、業界全体の人材流通が加速することを期待しています。
                    </p>
                    
                    
                    <ul>
                        <ul>求人企業: 求人情報の登録・編集機能、エージェントとのメッセージ機能を活用し、多様な候補者にアプローチ。</ul>
                        <ul>エージェント: 求人情報の検索やブックマーク機能を駆使し、候補者に最適な求人を提案。マッチングと選考調整の一元管理が可能。</ul>
                        <ul>運営側: ユーザー管理やマッチング状況の把握、データ分析に基づくアルゴリズム最適化機能を提供。</ul>
                    </ul>

                    <p>サービスは開始したばかりですが、期待される効果として、求人企業がより幅広い候補者にリーチし、エージェントの活用による効率的な採用プロセスが促進されると見込んでいます。</p>

                    <br/>
                    <p>人材紹介サービスの開発、マッチングシステムの開発、新規事業の立ち上げをお考えの際は、ぜひお問い合わせください。リサーチやプロトタイピングを通じて、提供価値や必要な機能を明確化し、共に実現に向けた取り組みを進めていきます。</p>
                    <p>
                        <Link href="/contact" passHref>
                            <a
                                className={`text-sm hover:underline md:self-end`}
                            >
                                お問い合わせフォームへ
                            </a>
                        </Link>
                    </p>
                </div>
            ),
        },
        date: '2024 Oct',
        label: 'New business co-creation',
    },
    {
        id: '25',
        title: 'Enhancing Communication Quality and Productivity on Slack with Our New Auto-Translation Feature',
        thumbnail: 'slack-translation-prototype.png',
        cover: 'slack-translation-prototype-cover.png',
        link: '/news/25',
        slug: 'slack-translation-prototype',
        meta: {
            title:
                'Enhancing Communication Quality and Productivity on Slack with Our New Auto-Translation Feature - News - wesionaryTEAM',
            keywords: 'Multilingual communication, Slack plugin, Auto-translation, Productivity improvement, Team collaboration, Co-creation, Business efficiency',
            description: 'We have developed an auto-translation feature for Slack to improve multilingual communication. Messages in Japanese and English are automatically translated, and corrections can be made with just one click. This significantly boosts the quality and productivity of communication between our development team and Japanese clients.',
        },
        description: {
            en: (
                <div className="grid gap-4">
                    <p>
                        We have developed an auto-translation feature to improve multilingual communication on Slack.
                        This feature automatically translates messages between Japanese and English and allows for easy
                        one-click corrections. By bridging the communication gap between our development team and
                        Japanese clients, we are significantly enhancing both the quality and productivity of our
                        operations.
                    </p>
                    <p>
                        Previously, each team member had to manually translate messages, leading to the following
                        challenges:
                    </p>
                    <ul>
                        <li>Lower productivity due to translating messages individually when needed</li>
                        <li>Misunderstandings caused by unchecked or incorrect translations</li>
                        <li>Inconsistent translation quality depending on the individual</li>
                        <li>Extra effort needed to verify the accuracy of translations</li>
                    </ul>

                    <p>Our newly developed auto-translation feature addresses these issues, providing an environment
                        where all team members can work smoothly on projects. Even if a translation error occurs, it can
                        be quickly corrected with a single click, ensuring accurate information sharing.</p>

                    <br/>
                    <p>This feature is now in use internally, and many teams have already given it high praise. We will
                        continue to improve this functionality to further enhance the quality of communication in our
                        co-creation efforts.</p>
                    <p>
                        <Link href="/contact" passHref>
                            <a
                                className={`text-sm hover:underline md:self-end`}
                            >
                                Contact Us
                            </a>
            </Link>
          </p>
        </div>
      ),
      ja: (
        <div className="grid gap-4">
          <p>
            当社は、Slack上での多言語コミュニケーションを効率化するための自動翻訳機能を開発しました。この拡張機能により、日本語と英語のメッセージが自動的に翻訳されます。更に、翻訳内容に気になるところがあれば、Slack
            上で簡単に修正することもできます。
          </p>
          <p>
            これまで、各メンバーが手動でメッセージを翻訳していたため、以下のような課題が発生していました：
          </p>
          <ul>
            <li>各自が必要なタイミングで翻訳を行うため、作業効率が低下する</li>
            <li>誤訳のまま作業が進行し、認識の齟齬が発生する</li>
            <li>翻訳の質が個人によってばらつきがある</li>
            <li>正確な翻訳が行われているか確認する手間がかかる</li>
          </ul>
          <p>今回開発した自動翻訳機能は、これらの課題を解消し、メンバー全員がスムーズにプロジェクトを進行できる環境を提供します。</p>
          <br/>
          <p>
            この機能を現在社内で活用しています。今後もさらなる機能改善を図り、プロダクト共創の現場でのコミュニケーションの質を向上させてまいります。将来的には一般公開を予定しています。個人、企業、またはコミュニティの皆様で、公開前にご利用をご希望の方はぜひご連絡ください。
          </p>
          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                お問い合わせフォームへ
              </a>
            </Link>
          </p>
        </div>
      ),
    },
    date: '2024 Sep',
    label: 'Productivity Enhancement',
  },
  {
    id: '30',
    title: 'Launching Development of a New System to Automate Advertising on Rakuten Marketplace: Aiming to Reduce Manual Workload and Maximize Ad Effectiveness',
    thumbnail: 'rakuten-automation-start.png',
    cover: 'rakuten-automation-start-cover.png',
    link: '/news/30',
    slug: 'rakuten-automation-start',
    meta: {
      title:
        'Launching Development of a New System to Automate Advertising on Rakuten Marketplace: Aiming to Reduce Manual Workload and Maximize Ad Effectiveness - News - wesionaryTEAM',
      keywords: 'Ad automation, Rakuten Marketplace, system development, business process optimization',
      description: 'We have embarked on developing a new system to automate advertising placement, aiming to reduce manual workload and maximize ad effectiveness. This project is advancing as a co-creation partnership to streamline advertising processes.',
    },
    description: {
      en: (
        <div className="grid gap-4">
          <p>
            We are pleased to announce the start of a new project in partnership with our co-creation
            partners to streamline advertising placement. This initiative focuses on developing a new system
            designed to significantly reduce manual workload while maximizing advertising effectiveness.
          </p>

          <br/>
          <p className={`font-semibold`}>Challenges Faced by Our Client and Project Background</p>
          <p>Our client relies on Rakuten RMS, the user management system for Rakuten Marketplace merchants,
            to handle a wide range of tasks manually. Labor-intensive activities such as product management
            and advertising require significant effort. Planning and executing ad placements, especially
            during campaigns or coupon promotions, involve a high level of manual work. This often leads to
            wasted costs and missed opportunities due to the complexity of preparation and multiple
            considerations involved in advertising. As a result, improving the efficiency and effectiveness
            of advertising placement has become a critical priority.</p>

          <br/>
          <p className={`font-semibold`}>System Features and Expected Benefits</p>
          <p>The system under development will utilize data such as product order, keyword relevance, and access numbers to optimize ad placements by timing and location. By automating the advertising process, it will significantly reduce manual workload and minimize human errors. Following successful pilot tests, we have moved forward with full-scale development and are preparing for operational rollout after the system is launched.</p>

          <br/>
          <p>If you are considering optimizing your business processes, digital transformation (DX), or automating tasks like advertising through RPA, please contact us. Through collaborative problem-solving, we help improve both operational efficiency and quality.</p>
          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                Contact Us
              </a>
            </Link>
          </p>
        </div>
      ),
      ja: (
        <div className="grid gap-4">
          <p>
            この度、当社は広告掲載の効率化を目指すため、共創パートナーとして選定され、顧客企業とのプロジェクトに着手しました。このプロジェクトでは、広告効果を最大化しながら業務負担を大幅に削減する新システムの開発を行います。
          </p>

          <br/>
          <p className={`font-semibold`}>顧客企業の抱える課題とプロジェクトの背景</p>
          <p>顧客企業は、Rakuten RMS（楽天市場の店舗向け利用者管理システム）を利用し、多岐にわたる業務を手作業で行っています。商品管理や広告管理などの手間がかかる作業が多く、特にキャンペーンやクーポンに合わせた広告掲載の計画と実施は、手動による作業の負担が大きいものです。特に広告に関しては、準備や考慮すべきことが多く、無駄なコストの発生や機会損失が発生することがよくあります。こうした背景から、広告掲載作業の効率化と効果の向上が急務となっています。</p>

          <br/>
          <p className={`font-semibold`}>システムの特長と期待される効果</p>
          <p>開発するシステムは、商品の記載順、キーワードの重要性、アクセス数などのデータをもとに、広告を最適な箇所とタイミングで掲載する機能を備えています。さらに、広告の掲載プロセスを自動化することで、従来の手作業による負担を大幅に軽減し、人的エラーの発生を防ぎます。実証実験において効果が確認されたことから、本格的な開発に着手し、システム導入後の運用に向けて準備を進めています。</p>

          <br/>
          <p>業務プロセスの分析と最適化、デジタルトランスフォーメーション（DX化）、広告や業務の自動化（RPA）をお考えの場合、ぜひお問い合わせください。共創による課題解決で、業務効率化と質の向上を同時に実現するお手伝いをいたします。</p>
          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                お問い合わせフォームへ
              </a>
            </Link>
          </p>
        </div>
      ),
    },
    date: '2024 Sep',
    label: 'New business co-creation',
  },
  {
    id: '16',
    title: 'Introducing Our New Prototype for Streamlining Multilingual UI Design',
    thumbnail: 'figma-translation-prototype.png',
    cover: 'figma-translation-prototype-cover.png',
    link: '/news/16',
    slug: 'figma-translation-prototype',
    meta: {
      title:
        'Introducing Our New Prototype for Streamlining Multilingual UI Design - News - wesionaryTEAM',
      keywords: 'Productivity enhancement, Figma, Multiple Languages UI',
      description: 'Discover our new Figma prototype designed to streamline multilingual UI design, boosting productivity with easy language switching between English and Japanese. Contact us for early access before the public release.',
    },
    description: {
      en: (
        <div className="grid gap-4">
          <p>
            We have developed a new prototype that significantly boosts productivity when creating
            wireframes and
            UIs in Figma. This prototype is designed to make multilingual UI design more efficient by
            allowing
            users to easily switch between English and Japanese.
          </p>
          <p>
            In the past, creating UIs for multiple languages required separate wireframes for each language,
            adding extra work when updating or sharing designs. This often led to the following challenges:
          </p>
          <ul>
            <li>Time-consuming process for creating and updating multilingual UIs</li>
            <li>Frequent update inconsistencies between different languages</li>
            <li>Multiple URLs for the same UI, complicating sharing and documentation</li>
            <li>Difficulty managing prototypes that support multiple languages in one place</li>
            <li>Overwhelming number of UIs in Figma, reducing overall visibility and clarity</li>
          </ul>
          <p>Our newly developed prototype addresses these issues by enabling seamless language switching,
            reducing
            rework, and improving overall project productivity.</p>
          <p>Currently, this prototype is being used internally, but we plan to make it publicly available in the
            future. If you or your organization would like to try it out before the official release to enhance
            multilingual UI design in Figma, please feel free to contact us.</p>
          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                Contact Us
              </a>
            </Link>
          </p>
        </div>
      ),
      ja: (
        <div className="grid gap-4">
          <p>
            当社は、Figmaを使ったワイヤーフレームやUIの制作において、生産性を飛躍的に向上させるプロトタイプを開発しました。このプロトタイプは、英語と日本語の間で簡単に言語を切り替えられる機能を備えており、多言語対応のUI制作を効率化するために設計されています。
          </p>
          <p>
            これまで、複数言語に対応するUIを制作する際には、言語ごとに別々のワイヤーフレームを用意する必要があり、更新や共有の手間が増えていました。この状況により、以下のような課題が頻発していました。
          </p>
          <ul>
            <li>複数言語のUI作成と更新に手間がかかる</li>
            <li>言語間での更新漏れが発生しやすい</li>
            <li>同じUIに対して異なるURLが複数存在し、共有や設計書作成が煩雑になる</li>
            <li>言語別のプロトタイプを一元的に管理できない</li>
            <li>Figma上に大量のUIが増え、視認性が低下する</li>
          </ul>
          <p>今回開発したプロトタイプでは、これらの問題を解決し、言語の切り替えがシームレスに行えるようにすることで、制作の手戻りを削減し、プロジェクト全体の生産性を向上させることを期待しています。</p>
          <p>
            現在、このプロトタイプは社内での使用段階にありますが、将来的には一般公開を予定しています。FigmaでのワイヤーフレームやUI制作における多言語対応を効率化したいとお考えの個人、企業、またはコミュニティの皆様で、公開前にご利用をご希望の方はぜひご連絡ください。
          </p>
          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                お問い合わせフォームへ
              </a>
            </Link>
          </p>
        </div>
      ),
    },
    date: '2024 Aug',
    label: 'Productivity Enhancement',
  },
  {
    id: '24',
    title: 'TeamPlace Recognized as an Eligible Tool for IT Adoption Subsidy 2024',
    thumbnail: 'teamplace-it-shien.png',
    cover: 'teamplace-it-shien-cover.png',
    link: '/news/24',
    slug: 'teamplace-it-shien',
    meta: {
      title:
        'TeamPlace Recognized as an Eligible Tool for IT Adoption Subsidy 2024 - News - wesionaryTEAM',
      keywords: 'IT Adoption Subsidy 2024 recognition, Operational efficiency, New business development, DX promotion',
      description: 'TeamPlace, developed by our company, has been recognized as an eligible tool for the IT Adoption Subsidy 2024. Companies can utilize the subsidy to reduce adoption costs while achieving flexible working styles and improving their work environments.',
    },
    description: {
      en: (
        <div className="grid gap-4">
          <p>
            TeamPlace (<a
            href="https://team-place.com/"
            target={'_blank'}
            rel="noopener noreferrer"
            className=" text-primary-500 hover:underline"
          >https://team-place.com/</a>), a service developed by our company, has been recognized as an eligible tool for the IT Adoption Subsidy 2024. This recognition allows companies that implement TeamPlace’s CRM to reduce the financial burden of adoption costs by utilizing the subsidy.
          </p>

          <br />
          <p className={`font-semibold`}>About TeamPlace</p>
          <p>
            TeamPlace is a service that promotes space sharing and community building, offering users comfortable workspaces and supporting a balanced approach to work and life. Users can access partnered spaces nationwide and take advantage of community features that facilitate networking and collaboration. With this recognition, more companies and individuals are expected to benefit from TeamPlace’s convenience, enhancing their operational efficiency and enabling flexible working styles.
          </p>

          <br />
          <p className={`font-semibold`}>Benefits of the IT Adoption Subsidy</p>
          <p>
            The IT Adoption Subsidy is a program that assists small and medium-sized businesses in offsetting part of the costs of implementing IT tools. With TeamPlace now recognized as an eligible tool, companies and sole proprietors can reduce their implementation costs while enhancing flexible work environments and improving their operations. The subsidy lowers the barriers to initial investment, supporting business growth and increasing competitiveness.
          </p>

          <br />
          <p>We supports businesses in new project development and driving digital transformation (DX). If you are exploring ways to improve operational efficiency or develop new business models, please feel free to contact us.</p>
          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                Contact Us
              </a>
            </Link>
          </p>
        </div>
      ),
      ja: (
        <div className="grid gap-4">
          <p>
            当社が開発を請け負ったサービス「TeamPlace」（<a
            href="https://team-place.com/"
            target={'_blank'}
            rel="noopener noreferrer"
            className=" text-primary-500 hover:underline"
          >https://team-place.com/</a>）が、IT導入補助金2024の対象ツールとして認定されました。この認定により、TeamPlaceのCRMを導入する企業は、IT導入補助金を活用してサービスを導入する際の費用負担を軽減することが可能となります。
          </p>

          <br />
          <p className={`font-semibold`}>TeamPlaceについて</p>
          <p>
            TeamPlaceは、スペースシェアリングとコミュニティ形成を促進するサービスで、ユーザーが快適に働ける場所を提供し、仕事と生活のバランスを支える新しい働き方を提案しています。全国の提携スペースを利用できるほか、コミュニティ機能を通じてネットワーキングや交流を支援するプラットフォームです。今回の認定により、より多くの企業や個人がTeamPlaceの利便性を活用し、業務の効率化と柔軟な働き方を実現することが期待されています。
          </p>

          <br />
          <p className={`font-semibold`}>IT導入補助金の活用メリット</p>
          <p>IT導入補助金は、中小企業や小規模事業者がITツールを導入する際の費用の一部を補助する制度です。TeamPlaceが対象ツールに認定されたことで、企業や個人事業主は導入コストを抑えながら、柔軟な働き方の実現や業務環境の改善に役立てることができます。また、補助金の活用により、初期投資のハードルを低くし、企業の成長と競争力向上を支援します。</p>

          <br />
          <p>
            当社は、企業の新規事業開発や業務のDX推進をサポートしています。業務効率化や新しいビジネスモデルを模索している企業様、ぜひ一度ご相談ください。
          </p>
          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                お問い合わせフォームへ
              </a>
            </Link>
          </p>
        </div>
      ),
    },
    date: '2024 Aug',
    label: 'Notice',
  },
  {
    id: '35',
    title: 'Life Pilot, a health management promotion platform, has been re-registered as an eligible tool for IT implementation subsidy 2024',
    thumbnail: 'lifepilot-it-shien.png',
    cover: 'lifepilot-it-shien-cover.png',
    link: '/news/35',
    slug: 'lifepilot-it-shien',
    meta: {
      title:
        'Life Pilot, a health management promotion platform, has been re-registered as an eligible tool for IT implementation subsidy 2024 - News - wesionaryTEAM',
      keywords: 'IT Subsidy 2024, Corporate Wellness Systems, New Business Development, DX Promotion',
      description: 'The corporate wellness promotion platform, Life Pilot, co-created by our company and RASCAL\'s, has been re-registered as an eligible tool for IT Subsidy 2024. With the support of this subsidy, companies can reduce the implementation costs while adopting flexible working styles and improving their business environment. If you\'re looking to enhance operational efficiency or explore new business models, please feel free to contact us.',
    },
    description: {
      en: (
        <div className="grid gap-4">
          <p>
            The service Life Pilot (<a
            href="https://lp.lifepilot.jp/"
            target={'_blank'}
            rel="noopener noreferrer"
            className=" text-primary-500 hover:underline"
          >https://lp.lifepilot.jp/</a>), co-created by our company and RASCAL's, has been re-registered as an eligible tool for IT Subsidy 2024. With this re-registration, companies and organizations can take advantage of the subsidy to reduce the costs associated with implementing Life Pilot.
          </p>

          <br />
          <p className={`font-semibold`}>About Life Pilot</p>
          <p>
            Life Pilot is a platform designed to support corporate wellness initiatives. It automatically delivers daily health information to employees via LINE, and provides tools for stress checks and evaluating the effectiveness of wellness programs. By visualizing employee health data, Life Pilot offers comprehensive support for corporate wellness, helping improve employee health, increase productivity, and enhance overall organizational vitality.
          </p>

          <br />
          <p className={`font-semibold`}>Benefits of the IT Subsidy</p>
          <p>
            The IT Subsidy is a program that helps businesses reduce the cost of implementing IT tools. With Life Pilot re-registered as an eligible tool, companies and organizations can use the subsidy to lower implementation costs while promoting corporate wellness, maintaining employee health, and improving operational efficiency. By leveraging this subsidy, companies can ease the initial investment burden and foster sustainable growth.
          </p>

          <br />
          <p>Our company assists in new business development and the promotion of digital transformation (DX) for organizations. If you are exploring ways to enhance operational efficiency or seeking new business models, please feel free to reach out.</p>
          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                Contact Us
              </a>
            </Link>
          </p>
        </div>
      ),
      ja: (
        <div className="grid gap-4">
          <p>
            当社と株式会社RASCAL'sが共創したサービス「Life Pilot（<a
            href="https://lp.lifepilot.jp/"
            target={'_blank'}
            rel="noopener noreferrer"
            className=" text-primary-500 hover:underline"
          >https://lp.lifepilot.jp/</a>）」が、再びIT導入補助金2024の対象ツールとして登録されました。この再登録により、企業や団体がLife Pilotを導入する際に、IT導入補助金を活用して費用を軽減することが可能となります。
          </p>

          <br />
          <p className={`font-semibold`}>Life Pilotについて</p>
          <p>
            Life Pilotは、健康経営をサポートするプラットフォームです。従業員に向けて日々の健康情報をLINEで自動配信し、ストレスチェックや健康施策の効果検証が可能です。また、従業員の健康データを可視化し、企業の健康経営を総合的にサポートします。このサービスは、従業員の健康増進を通じて生産性の向上を促し、組織全体の活性化を目指しています。
          </p>

          <br />
          <p className={`font-semibold`}>IT導入補助金のメリット</p>
          <p>IT導入補助金は、企業や団体がITツールを導入する際のコスト負担を軽減する制度です。Life Pilotが再登録されたことで、企業や団体は導入費用を抑えながら、健康経営を促進し、従業員の健康維持と業務効率化を実現することが可能です。補助金の活用により、初期投資の負担が軽減され、企業の成長を後押しします。</p>

          <br />
          <p>
            当社は、企業の新規事業開発や業務のDX推進をサポートしています。業務効率化や新しいビジネスモデルを模索している企業様、ぜひ一度ご相談ください。
          </p>
          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                お問い合わせフォームへ
              </a>
            </Link>
          </p>
        </div>
      ),
    },
    date: '2024 Aug',
    label: 'Notice',
  },
  {
    id: '31',
    title: 'Making Car Camping More Enjoyable and Convenient! We’ve Launched a New App Development Project',
    thumbnail: 'cam-tabi-start.png',
    cover: 'cam-tabi-start-cover.png',
    link: '/news/31',
    slug: 'cam-tabi-start',
    meta: {
      title:
        'Making Car Camping More Enjoyable and Convenient! We’ve Launched a New App Development Project - News - wesionaryTEAM',
      keywords: 'New business development, Planning and research, Joint operations, Co-creation projects, Car camping app, B2C business',
      description: 'This co-creation app development project aims to make car camping more enjoyable and convenient. The app features trip planning, safety measures, and new ways to connect with others. From new business development to joint operations, we take a co-creative approach. Contact us for more information.',
    },
    description: {
      en: (
        <div className="grid gap-4">
          <p>
            We have partnered with our client to develop a new app designed to make car camping more enjoyable and convenient. Selected as a co-creation partner, we aim to leverage our app planning, development skills, and AI technology to enhance the car camping experience.
          </p>
          <p>
            Car camping, a new travel style where vehicles are used as accommodations, offers a high degree of freedom and is popular among camping and outdoor enthusiasts. It allows travelers to choose their accommodations freely, reduces costs, and provides flexible safety measures. With the market expanding recently, car camping is gaining popularity among a wide range of people, including families and solo travelers.
          </p>

          <br />
          <p className={`font-semibold`}>Challenges Faced by Our Client and Project Background</p>
          <p>The car camping market has been growing rapidly in recent years; however, there are still few apps that truly satisfy users. There is a demand for innovative services that address various needs, including trip planning, safety measures, and interaction with other users. This project aims to offer an app that provides unique enjoyment and convenience not found in existing solutions, supporting new encounters and discoveries.</p>

          <br />
          <p className={`font-semibold`}>Key Features and Expected Benefits of the App</p>
          <p>The app being developed in this project will include the following features:</p>
          <ul>
            <li>Trip Planning:** Easily create itineraries, share them, and reference trips created by other users.</li>
            <li>Recording Memories:** Capture and share memories of visited locations through photos, videos, and notes, which can be edited jointly with family and friends.</li>
            <li>New Ways to Connect:** Receive notifications when near other car campers, making it easy to connect with others and enjoy new encounters.</li>
            <li>Safety Features:** Access vital information such as nearby shelters, hospitals, and police stations, share location data with companions, and receive safety guidelines and training materials, ensuring a safe and secure car camping experience.</li>
          </ul>
          <p>These features are expected to significantly enhance the car camping experience. While still in the early stages, we are committed to building a future where users can enjoy car camping comfortably and safely.</p>


          <br />
          <p>If you are interested in new business development, from planning and research to joint operations, our co-creative approach can help bring your vision to life. We strive to be a partner in creating new value with you, supporting your success in new business ventures. Please feel free to reach out for a consultation.</p>

          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                Contact Us
              </a>
            </Link>
          </p>
        </div>
      ),
      ja: (
        <div className="grid gap-4">
          <p>
            当社は、「車中泊をもっと楽しく、便利にする」ことを目指し、顧客企業様と共創型で新たなアプリの開発を開始いたしました。顧客企業様より共創パートナーとして選定され、アプリ企画・開発技術とAIを活用し、車中泊の新しい体験の提供を目指します。
          </p>
          <p>
            車中泊は、自動車を宿泊施設として利用する新しい旅のスタイルで、自由度が高く、キャンプやアウトドアが好きな人々に人気があります。旅行中の宿泊先を自由に選べることや、コストの削減、安全対策の柔軟性が魅力です。特に、最近の市場拡大に伴い、家族連れやソロ旅行者など、幅広い層が利用するようになっています。
          </p>

          <br />
          <p className={`font-semibold`}>顧客企業の抱える課題とプロジェクトの背景</p>
          <p>近年、車中泊市場は拡大傾向にありますが、利用者が真に満足できるアプリはまだ少ないのが現状です。車中泊の計画や安全対策、他ユーザーとの交流など、複数のニーズに対応するため、革新的なサービスが求められています。このプロジェクトでは、従来のアプリでは提供できなかった楽しさや利便性を実現し、新しい出会いや発見をサポートすることを目指しています。</p>

          <br />
          <p className={`font-semibold`}>アプリの特長と期待される効果</p>
          <p>本プロジェクトで開発するアプリは、以下の特長を持っています。</p>
          <ul>
            <li>旅程作成機能：簡単に旅程を作成し、他のユーザーの旅程を参考にしたり、共有することが可能です。</li>
            <li>記録機能：訪れた場所や思い出を写真や動画とともに記録し、家族や友人と共有・編集できます。</li>
            <li>新たな楽しさ：近くを通る他の車中泊ユーザーとのすれ違いを通知し、他のユーザーとの出会いを楽しむことができます。</li>
            <li>安全機能：緊急時の避難所や病院情報の確認、同伴者の位置情報の共有、安全トレーニングガイドの提供など、安全で安心な車中泊をサポートします。</li>
          </ul>
          <p>
            これらの機能により、車中泊の体験が大幅に向上することが期待されます。まだプロジェクトの初期段階ではありますが、ユーザーが快適かつ安全に車中泊を楽しめる未来を目指して進めてまいります。
          </p>


          <br />
          <p>新規事業の開発や企画・リサーチから共同運営まで、共創型のアプローチでの事業展開にご興味のある企業様は、ぜひお問い合わせください。当社は、お客様と共に新しい価値を創造し、ビジネスの成功を共に目指すパートナーとして、貴社の新規事業を支援いたします。</p>

          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                お問い合わせフォームへ
              </a>
            </Link>
          </p>
        </div>
      ),
    },
    date: '2024 Jun',
    label: 'DX',
  },
  {
    id: '21',
    title: 'Release of the J-Client+ System for Digital Transformation of Business Operations',
    thumbnail: 'j-client-released.png',
    cover: 'j-client-released-cover.png',
    link: '/news/21',
    slug: 'j-client-released',
    meta: {
      title:
        'Release of the J-Client+ System for Digital Transformation of Business Operations - News - wesionaryTEAM',
      keywords: 'Operational efficiency, Business DX, Co-creation partner',
      description: 'In collaboration with the Japan Air Conditioning and Refrigeration Research Association, we released the J-Client+ system to enhance business DX, improve operational efficiency, and streamline communication. Developed through a co-creation approach, J-Client+ addresses traditional business communication and document management challenges.',
    },
    description: {
      en: (
        <div className="grid gap-4">
          <p>
            In June 2024, we, in collaboration with Japan Air Conditioning and Refrigeration Research Association, released the J-Client+ system to support the digital transformation (DX) of business operations. This system was developed over six months with the aim of improving operational efficiency and enhancing communication. With the release of J-Client+, we address the challenges of traditional business communications and document management, optimizing overall business processes.
          </p>

          <br />
          <p className={`font-semibold`}>Background of the Project and Customer Challenges</p>
          <p>The Japan Air Conditioning and Refrigeration Research Association faced growing complexities in their business operations and needed to streamline their information management processes. Relying on traditional phone calls and email exchanges often led to delays, overlooked information, and insufficient visibility of submission deadlines and approval processes, resulting in decreased operational efficiency.</p>
          <p>As a co-creation partner, we supported every step of the project—from defining requirements to development and ongoing operations—focusing on solving these challenges through the development of J-Client+.</p>

          <br />
          <p className={`font-semibold`}>Key Features and Benefits of J-Client+</p>
          <p>J-Client+ centralizes messaging (chat), data sharing, and document management on the web, eliminating the need to rely on emails for business processes. This system ensures that critical information is well-managed and easily accessible.</p>
          <p>Additionally, with status management of submissions and tracking of approval processes, J-Client+ enhances visibility and greatly improves communication within teams. This reduces response times and enables faster decision-making. The system also supports the standardization and automation of tasks, reducing human errors and increasing transparency in operations.</p>

          <br />
          <p className={`font-semibold`}>Positive Feedback and Future Outlook</p>
          <p>The project leader praised J-Client+ as “very well-made and easy to use,” and it has also received high evaluations from end users within the industry. By incorporating feedback identified during the development process, we have refined and improved the system, contributing to the success of the project.</p>

          <br />
          <p>Moving forward, we will continue to enhance J-Client+, aiming to further improve operational efficiency and communication. Even after the release, we remain committed to growing alongside our customers, advancing to new stages together.</p>

          <br />
          <p>For companies considering digital transformation or aiming to visualize and improve their business processes, we encourage you to explore our co-creation approach. Please feel free to reach out to us.</p>

          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                Contact Us
              </a>
            </Link>
          </p>
        </div>
      ),
      ja: (
        <div className="grid gap-4">
          <p>
            2024年6月、弊社と一般財団法人 日本空調冷凍研究所様が業務DX化システム「J-Client+」をリリースしました。このシステムは、業務の効率化とコミュニケーション改善を目指し、半年間にわたる開発を経てリリースしました。今回のリリースにより、従来の業務連絡や資料の送受信における課題を解決し、業務プロセスを最適化します。
          </p>

          <br />
          <p className={`font-semibold`}>プロジェクトの背景と顧客の課題</p>
          <p>一般財団法人 日本空調冷凍研究所様は、複雑化する業務と情報管理の効率化を必要としていました。従来の電話やメールベースのやり取りでは、業務の遅延や情報の見落としが発生しやすく、提出物の期限管理や承認プロセスの見える化も不十分で、業務効率が低下していました。</p>
          <p>弊社は、プロダクト共創のパートナーとして、要件定義から開発、そして運用までを一貫してサポートし、課題解決を目指して「J-Client+」を開発しました。</p>

          <br />
          <p className={`font-semibold`}>「J-Client+」の特長と導入効果</p>
          <p>「J-Client+」は、Web上でメッセージング（チャット）、データ共有、提出物の管理を一元化します。これにより、メールに依存することなく業務プロセスを最適化し、重要な情報をしっかりと管理します。</p>
          <p>さらに、提出物のステータス管理や承認プロセスの追跡機能を通じて、業務の見える化を実現し、チーム間のコミュニケーションを大幅に効率化します。これにより、対応の遅れを最小限に抑え、迅速な意思決定が可能になります。また、業務の標準化と自動化をサポートすることで、人的ミスを削減し、業務の透明性を高めます。</p>

          <br />
          <p className={`font-semibold`}>プロダクトに寄せられた評価と今後の展望</p>
          <p>日本空調冷凍研究所様は「非常に良くできていて使いやすい」との評価を寄せてくださり、メーカーの利用者からも高い評価をいただいています。開発過程で顕在化したフィードバックを反映し、改良を重ねることでプロジェクトの成功につなげました。</p>

          <br />
          <p>これからも「J-Client+」をさらに進化させ、業務効率化とコミュニケーション改善の実現を目指します。リリース後もお客様と共に成長し、新たなステージへ進んでまいります。</p>

          <br />
          <p>業務のデジタルトランスフォーメーション（DX化）や業務プロセスの見える化をお考えの企業様、お気軽にご相談ください。</p>

          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                お問い合わせフォームへ
              </a>
            </Link>
          </p>
        </div>
      ),
    },
    date: '2024 Jun',
    label: 'DX',
  },
  {
    id: '18',
    title: 'Completion of ISMS Certification Renewal and Our Latest Security Initiatives',
    thumbnail: 'isms-update-2024.png',
    cover: 'isms-update-2024-cover.png',
    link: '/news/18',
    slug: 'isms-update-2024',
    meta: {
      title:
        'Completion of ISMS Certification Renewal and Our Latest Security Initiatives - News - wesionaryTEAM',
      keywords: 'ISMS certification for system development, information security in system development, access management',
      description: 'We obtained ISMS certification in 2020 and completed its renewal in May 2024. As part of our latest security initiatives, we have introduced an access management system and an allowlist approach to enhance information security. If you are considering strengthening security measures in your system development, please feel free to contact us.',
    },
    description: {
      en: (
        <div className="grid gap-4">
          <p>
            We are pleased to announce that we successfully renewed our Information Security Management System (ISMS) certification in May 2024, initially obtained in 2020 as part of our ongoing commitment to information security.
          </p>
          <p>
            ISMS is a crucial framework that helps protect the confidentiality, integrity, and availability of information, safeguarding a company's valuable data assets. By obtaining and regularly renewing this certification, we ensure that our clients' important information is managed securely and that security risks are effectively mitigated.
          </p>
          <p className={`font-semibold`}>Recent Security Initiatives</p>
          <p>To further strengthen our security measures, we have introduced the following initiatives starting in 2024:</p>
          <ul>
            <li>Implementation of an Access Management System: We have launched a custom system to manage who has access to which tools and what approval processes are required. This system automates permission settings for some tools, making access management more efficient.</li>
            <li>Introduction of an Allowlist Approach: We have adopted an allowlist approach to restrict the use of software and web tools in our operations, carefully selecting tools to reduce the risk of information leaks associated with the increasing use of AI services and new technologies.</li>
          </ul>
          <p>We will continue to improve our information security measures, ensuring the safe and reliable delivery of our services.</p>
          <p>If you are considering enhancing your development structure or strengthening security measures in your system development, please feel free to contact us.</p>
          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                Contact Us
              </a>
            </Link>
          </p>
        </div>
      ),
      ja: (
        <div className="grid gap-4">
          <p>
            当社は、情報セキュリティへの取り組みとして2020年に「情報セキュリティマネジメントシステム（ISMS）」の認証を取得し、2024年5月に更新を完了しました。
          </p>
          <p>ISMSは、情報の機密性、完全性、可用性を保護するための管理システムで、企業の情報資産を守るための重要なフレームワークです。認証の取得と定期的な更新により、当社はお客様の大切な情報を安全に管理し、セキュリティリスクを軽減できます。</p>
          <p className={`font-semibold`}>直近の取り組みの一部をご紹介</p>
          <p>さらなるセキュリティ強化を目的に、2024年より以下の取り組みを進めています。</p>
          <ul>
            <li>アクセス権限管理システムの導入: スタッフがどのツールにどのようなアクセス権限を持ち、どのような承認プロセスが必要かを管理する独自システムを導入しました。このシステムは、一部のツールへの権限設定を自動化し、効率的なアクセス管理を実現しています。</li>
            <li>許可リスト方式の導入: 業務で使用するソフトウェアやウェブツールを許可リスト方式に制限し、慎重に選定することで、AIサービスや新しいツールの利用に伴う情報漏洩リスクを軽減しています。</li>
          </ul>
          <p>今後も情報セキュリティの改善に努め、安全で信頼性の高いサービス提供を続けてまいります。</p>
          <p>開発体制やシステム開発における情報セキュリティ対策の強化をお考えの方は、ぜひお気軽にお問い合わせください。</p>
          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                お問い合わせフォームへ
              </a>
            </Link>
          </p>
        </div>
      ),
    },
    date: '2024 May',
    label: 'Security Enhancement',
  },
  {
    id: '19',
    title: 'Completion of QMS Maintenance Audit and Our Latest Initiatives',
    thumbnail: 'qms-update-2024.png',
    cover: 'qms-update-2024-cover.png',
    link: '/news/19',
    slug: 'qms-update-2024',
    meta: {
      title:
        'Completion of QMS Maintenance Audit and Our Latest Initiatives - News - wesionaryTEAM',
      keywords: 'IT service quality, System quality, Development process quality',
      description: 'We obtained QMS certification in 2023 and completed the maintenance audit in 2024. We are actively working to enhance the maturity of our product development by focusing on seven key areas. If you are interested in improving quality, please contact us.',
    },
    description: {
      en: (
        <div className="grid gap-4">
          <p>
            We are pleased to announce that we successfully completed the maintenance audit of our Quality
            Management System (QMS) certification, initially obtained in 2023.
          </p>
          <p>
            QMS is a systematic framework designed to continuously improve the quality of products and
            services.
          </p>
          <p className={`font-semibold`}>Recent Quality Initiatives</p>
          <p>To systematically enhance product development quality, we have established seven foundational
            pillars in 2024 and defined six maturity levels for each area. Based on these definitions, we
            are actively working to gradually elevate the maturity of each pillar.</p>

          <br/>
          <p>The Seven Foundational Pillars</p>
          <ul>
            <ol>Issue Definition and Hypothesis Verification of Target Systems (IT Services) : This stage
              involves clarifying issues and verifying solutions to establish the direction of the
              product.
            </ol>
            <ol>Requirement and Development Preparation: In this stage, resources are prepared, plans are
              formulated, and necessary conditions and requirements are organized.
            </ol>
            <ol>Project Management: Project progress is managed to balance quality, cost, and schedule,
              ensuring that project goals are achieved.
            </ol>
            <ol>System Development : This stage includes the actual development of the system, involving
              design, implementation, and testing based on specifications.
            </ol>
            <ol>Product and System Quality: To ensure the quality of the developed product, verification and
              improvement processes are carried out. This stage checks that quality standards are met.
            </ol>
            <ol>Product Operation: Support for the operation of completed products, aiming for stable
              service delivery. Continuous monitoring and improvement are conducted during this stage.
            </ol>
            <ol>Organization Operation and Members: Management of the entire organization and its members to
              optimize team performance. This includes process standardization and skill development for
              team members.
            </ol>
          </ul>
          <br/>
          <p>The Six Maturity Levels</p>
          <ul>
            <ol>Initial:Processes are often reactive and spontaneous, lacking standardized procedures, which
              leads to inconsistent results.
            </ol>
            <ol>Defined: Processes are systematically documented and standardized, providing clear and
              consistent methods for task execution.
            </ol>
            <ol>Useable: Defined processes are applied functionally and effectively, showing the benefits of
              standardization in practice.
            </ol>
            <ol>Learnable: This stage focuses on training and education, ensuring that employees understand
              and can effectively apply processes.
            </ol>
            <ol>Managed: Processes are comprehensively managed, with metrics and monitoring used to measure
              performance and support continuous improvement.
            </ol>
            <ol>Automated: Technology is leveraged to automate processes, reducing manual intervention,
              increasing precision, and operating at the highest level of predictability.
            </ol>
          </ul>
          <br/>
          <p>We will continue to strive for improvement in quality management, consistently delivering trusted
            products and services to our customers.</p>
          <p>If you are considering enhancing the quality of your systems, services, or development processes, please feel free to contact us.</p>
          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                Contact Us
              </a>
            </Link>
          </p>
        </div>
      ),
      ja: (
        <div className="grid gap-4">
          <p>
            当社は、品質管理の取り組みとして2023年に「品質マネジメントシステム（QMS）」の認証を取得し、2024年に維持審査を無事に完了しました。
          </p>
          <p>QMSは、プロダクトやサービスの品質を継続的に向上させるための体系的なフレームワークです。</p>
          <p className={`font-semibold`}>直近の取り組みの一部をご紹介</p>
          <p>2024年にプロダクト開発の品質を体系的に向上させるため、7つの基盤的領域を設定し、それぞれに6段階の成熟度レベルを定義しています。この定義に基づいて、各領域の成熟度を段階的に高める活動を進めています。</p>

          <br/>
          <p>7つの基盤的領域</p>
          <ul>
            <ol>対象システム（ITサービス）の課題の定義と仮説検証: 問題の明確化とその解決策の検証を行い、プロダクトの方向性を確立する段階です。</ol>
            <ol>要求・要件整理と開発準備: 開発に必要な資源の準備、計画の策定、必要な条件や要件の整備を行います。</ol>
            <ol>プロジェクトマネジメント: プロジェクトの進行を管理し、品質、コスト、スケジュールのバランスを保ちながら目標を達成するための管理を行います。</ol>
            <ol>システム開発: 実際のシステム開発を行う段階で、仕様に基づいた設計、実装、テストを行います。</ol>
            <ol>プロダクトおよびシステムの品質: 開発したプロダクトの品質を確保するため、検証と改善を行います。品質基準に合致しているかの確認が行われます。</ol>
            <ol>プロダクト運用: 開発が完了したプロダクトの運用をサポートし、安定したサービス提供を目指します。継続的な運用監視と改善が行われます。</ol>
            <ol>組織運営とメンバー: 組織全体の運営とメンバーの管理を行い、チーム全体のパフォーマンスを最適化します。プロセスの標準化とメンバーのスキルアップも含まれます。</ol>
          </ul>
          <br/>
          <p>6つの成熟度レベル</p>
          <ul>
            <ol>初期 (Initial): その場しのぎの対応が多く、標準化された手順が欠如しており、結果にばらつきが生じやすい段階です。</ol>
            <ol>定義済み (Defined): プロセスが体系的に文書化され、標準化されている段階です。タスクの実行方法が明確で一貫性があります。</ol>
            <ol>使用可能 (Useable): 定義されたプロセスが実際に適用され、機能的かつ効果的に運用されています。標準化の効果が現れ始める段階です。</ol>
            <ol>学習可能 (Learnable): プロセスの実施に加え、トレーニングや教育に重点を置く段階です。従業員がプロセスを理解し、効果的に運用するための支援が行われます。</ol>
            <ol>管理された (Managed): プロセスの管理が徹底され、モニタリングと指標が活用されてパフォーマンスが計測されます。改善のための基盤が整っています。</ol>
            <ol>自動化された (Automated): テクノロジーを活用してプロセスが自動化され、手作業を最小限に抑えています。高い精度と予測可能性を持って運用される最も高度な段階です。</ol>
          </ul>
          <br/>
          <p>今後も、品質マネジメントの改善に努め、お客様に信頼される製品・サービスを提供し続けてまいります。</p>
          <p>システムやサービスの品質、開発体制の改善等についてをお考えの方は、ぜひお気軽にお問い合わせください。</p>
          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                お問い合わせフォームへ
              </a>
            </Link>
          </p>
        </div>
      ),
    },
    date: '2024 May',
    label: 'Quality Enhancement',
  },
  {
    id: '23',
    title: '"Chat-YC" App Released to Improve the Efficiency of Delivery Inquiry Operations',
    thumbnail: 'chat-yc-released.png',
    cover: 'chat-yc-released-cover.png',
    link: '/news/23',
    slug: 'chat-yc-released',
    meta: {
      title:
        '"Chat-YC" App Released to Improve the Efficiency of Delivery Inquiry Operations - News - wesionaryTEAM',
      keywords: 'Digital transformation, DX, operational efficiency tools, Inquiry management system, Chat system, Co-creation partner',
      description: 'We have released an app to improve the efficiency of delivery inquiry operations for The Yomiuri Shimbun Tokyo Headquarters. With real-time chat and response status management features, the app supports operational efficiency and digital transformation.',
    },
    description: {
      en: (
        <div className="grid gap-4">
          <p>
            In February 2024, we released "Chat-YC," an app designed to improve the efficiency of delivery inquiry operations for The Yomiuri Shimbun Tokyo Headquarters. This app was developed as a co-creation partner to address the increasing complexity and workload of customer support operations resulting from the expansion of delivery services.
          </p>
          <p className={`font-semibold`}>Background and Challenges Faced by Our Client</p>
          <p>The Yomiuri Shimbun Tokyo Headquarters faced growing challenges in managing inquiries from retail outlets and delivery personnel, as well as communicating route changes and delays. The traditional methods of using phone, email, and fax led to increasingly complex workflows. The closed nature of these communication channels made it difficult to share information smoothly among stakeholders, hindering prompt responses. Additionally, managing inquiries through manual data entry in Excel and printed documents was time-consuming, highlighting the need for operational efficiency improvements.</p>

          <br/>
          <p className={`font-semibold`}>Features and Expected Benefits of "Chat-YC"</p>
          <p>"Chat-YC" is an efficient communication tool specifically designed for delivery operations, offering the following features that aim to streamline inquiry management and support the digital transformation (DX) of business processes:</p>
          <ul>
            <li>Real-Time Chat Functionality: Replacing traditional phone and email methods, this chat feature enables real-time information sharing, enhancing communication between delivery teams and customer support, and minimizing delays.
            </li>
            <li>Response Status Management: This feature allows for real-time management of inquiry statuses, making it easy to see who is handling which inquiries. This helps prevent missed responses and ensures smooth follow-ups.
            </li>
            <li>Enhanced Security and Data Management: All data is centrally managed within the company, reducing security risks and ensuring safe operations. In the future, the accumulated data can be analyzed with AI to further optimize delivery operations and support predictive analysis.
            </li>
          </ul>

          <br/>

          <p>Following the release, "Chat-YC" will continue to evolve based on feedback from The Yomiuri Shimbun Tokyo Headquarters. We remain committed to making continuous improvements in collaboration with our client.</p>

          <br/>
          <p>If your company is considering digital transformation (DX) or optimizing business processes, please feel free to contact us. We are here to offer structured solutions that align with your challenges and provide the most effective answers.</p>
          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                Contact Us
              </a>
            </Link>
          </p>
        </div>
      ),
      ja: (
        <div className="grid gap-4">
          <p>
            当社は、2024年2月に読売新聞東京本社様向けに、宅配業務の問い合わせ対応を効率化するアプリ「Chat-YC」をリリースしました。このアプリは、配送サービスの拡大に伴い、カスタマーサポート業務の複雑化と増大する負荷を解消するために、当社がプロダクト共創のパートナーとして開発したものです。
          </p>

          <p className={`font-semibold`}>プロジェクトの背景と顧客の課題</p>
          <p>読売新聞東京本社様では、販売店や配達員からの問い合わせ対応、配送ルートの変更、遅延に関する連絡が増加し、電話やメール、FAXを用いた従来の方法では業務が煩雑化していました。情報の共有がクローズドな形式で行われていたため、関係者間でのスムーズな連携が難しく、迅速な対応が求められていました。また、エクセルでの手入力や印刷による管理は手間がかかり、業務の効率化が必要とされていました。</p>

          <br/>
          <p className={`font-semibold`}>「Chat-YC」の特長と期待される効果</p>
          <p>「Chat-YC」は、宅配業務に特化した効率的なコミュニケーションツールとして、以下の特長を備えています。これにより、問い合わせ対応の効率化と業務のデジタルトランスフォーメーション（DX化）が期待されています。</p>
          <ul>
            <li>リアルタイムのチャット機能：従来の電話やメールに代わり、リアルタイムでの情報共有を可能にするチャット機能を導入しました。これにより、配送現場とカスタマーサポート間のコミュニケーションが迅速化され、業務の遅延が最小限に抑えられることが期待されます。
            </li>
            <li>対応ステータスの管理機能：問い合わせ対応のステータスをリアルタイムで管理し、誰がどの対応を行っているかが一目でわかるようになります。これにより、対応漏れを防止し、スムーズなフォローアップを実現します。
            </li>
            <li>セキュリティとデータ管理の向上：データは自社で一元管理されるため、セキュリティリスクを低減し、安全な運用が可能です。将来的には、蓄積されたデータをAIで解析し、配送業務のさらなる最適化や予測分析への活用が期待されています。
            </li>
          </ul>

          <br/>
          <p>「Chat-YC」は、リリース後も読売新聞東京本社様と共に、現場からのフィードバックを活かしながら改良を続けてまいります。</p>

          <br/>
          <p>デジタルトランスフォーメーション（DX化）や業務プロセスの最適化をお考えの企業様、ぜひお気軽にご相談ください。体系化された手法で、貴社の課題に寄り添い、最適な解決策をご提案します。</p>
          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                お問い合わせフォームへ
              </a>
            </Link>
          </p>
        </div>
      ),
    },
    date: '2024 Feb',
    label: 'DX',
  },
    {
        id: '42',
        title: 'Co-creation of a Matching Service to Support Students in Finding Their Ideal University',
        thumbnail: 'nennai-nyushi-navi-start.png',
        cover: 'nennai-nyushi-navi-start-cover.png',
        link: '/news/42',
        slug: 'nennai-nyushi-navi-start',
        meta: {
            title:
                'Co-creation of a Matching Service to Support Students in Finding Their Ideal University - News - wesionaryTEAM',
            keywords: 'University system, Portal site, Matching system, Educational service development, New business co-creation',
            description: 'We are participating as a co-creation partner in a project to develop an innovative matching portal site that connects universities with prospective students. This new platform offers students the ability to find universities that offer comprehensive or recommended entrance exams. If you are considering the development of new business, educational services, or matching systems, please contact us.',
        },
        description: {
            en: (
                <div className="grid gap-4">
                    <p>
                        We are pleased to announce that we have been selected as a co-creation partner by our client to
                        embark on the development of a new portal site connecting universities with prospective
                        students. This project aims to efficiently match high school students seeking university
                        admission with universities that offer entrance exams based on comprehensive or recommended
                        selection processes. As a co-creation partner, we will work closely with our client to ensure
                        the success of this project.
                    </p>

                    <br/>
                    <p className={`font-semibold`}>Client's Challenges and Project Background</p>
                    <p>Our client faced the challenge of a lack of portal sites specifically focused on providing
                        information about universities offering comprehensive or recommended entrance exams. </p>
                    <p>Moreover, there was a significant need for an infrastructure that would allow high school
                        students to effectively search for and select the most suitable universities. This project
                        addresses these challenges by offering a new platform that bridges the gap between students and
                        universities, providing them with new options for making informed choices about their future
                        education.</p>

                    <br/>
                    <p className={`font-semibold`}>System Features and Expected Benefits</p>
                    <p>The system being developed will not only allow students to easily search for universities that
                        meet their criteria but will also enable universities to make more effective approaches based on
                        the level of compatibility with prospective students. While the project is still in its early
                        stages and tangible results have yet to be achieved, we are committed to maximizing the expected
                        benefits in collaboration with our client.</p>

                    <br/>
                    <p>If you are considering the development of a new business, educational services, or a matching
                        system, please do not hesitate to contact us. Through research and prototyping, we will help
                        clarify the value and necessary features of your offering and work together towards its
                        realization.</p>
                    <p>
                        <Link href="/contact" passHref>
                            <a
                                className={`text-sm hover:underline md:self-end`}
                            >
                                Contact Us
                            </a>
                        </Link>
                    </p>
                </div>
            ),
            ja: (
                <div className="grid gap-4">
                    <p>
                        当社はこのたび、顧客企業より共創パートナーとして選定され、大学と受験生を結びつける新しいポータルサイトの構築プロジェクトに着手いたしました。このプロジェクトは、大学進学を希望する高校生と、総合型選抜や公募推薦で学生を募集する大学を効率的にマッチングさせることを目的としています。当社は、共創パートナーとして、クライアント企業と緊密に連携し、プロジェクトの成功に向けた取り組みを進めてまいります。
                    </p>

                    <br />
                    <p className={`font-semibold`}>顧客企業の抱える課題とプロジェクトの背景</p>
                    <p>顧客企業は、総合型選抜や公募推薦に特化した形で大学の情報を提供するポータルサイトがほとんど存在しないという課題を抱えていました。さらに、高校生が希望する進学先を効果的に検索し、最適な大学を選定できるインフラの不足も大きな問題でした。このプロジェクトは、こうした課題を解決するために、高校生と大学をつなぐ新たな選択肢を提供するものです。</p>

                    <br/>
                    <p className={`font-semibold`}>システムの特長と期待される効果</p>
                    <p>開発されるシステムは、学生が希望する条件に合った大学を簡単に検索できるだけでなく、大学側も学生とのマッチング度合いに基づいて効果的なアプローチが可能になります。まだプロジェクトは始まったばかりで具体的な成果は出ていませんが、顧客企業と共に、期待される効果を最大化するために尽力してまいります。</p>
                    
                    <br/>
                    <p>新規事業の開発、教育サービスの開発、マッチングシステムの開発をお考えの際は、ぜひお問い合わせください。リサーチやプロトタイピングを通じて、提供価値や必要な機能を明確化し、共に実現に向けた取り組みを進めていきます。</p>
                    <p>
                        <Link href="/contact" passHref>
                            <a
                                className={`text-sm hover:underline md:self-end`}
                            >
                                お問い合わせフォームへ
                            </a>
                        </Link>
                    </p>
                </div>
            ),
        },
        date: '2024 Jan',
        label: 'New business co-creation',
    },
  {
    id: '25',
    title: 'Selected as a Mentor for Google for Startups Accelerator',
    thumbnail: 'google-for-startups-accelerator.png',
    cover: 'google-for-startups-accelerator-cover.png',
    link: '/news/45',
    slug: 'google-for-startups-accelerator',
    meta: {
      title:
        'Selected as a Mentor for Google for Startups Accelerator - News - wesionaryTEAM',
      keywords: 'Startup support, Google for Startups Accelerator, Product co-creation, Internal development',
      description: 'We have been selected as a mentor for the Google for Startups Accelerator 2024. If your company is seeking to accelerate development, strengthen your team, or internalize development processes, feel free to contact us.',
    },
    description: {
      en: (
        <div className="grid gap-4">
          <p>
            We are pleased to announce that our company has been selected as a mentor for the Google for Startups Accelerator 2024. It is an honor to support promising startups by providing technical guidance and helping them build solid structures to foster their continued growth.
          </p>
          <p>The Google for Startups Accelerator is a program designed to help startups with innovative business ideas scale rapidly. As a mentor, we will leverage our expertise to provide technical advice and assist in organizational development.</p>
          <p>We remain committed to promoting co-creation and value creation, contributing to the growth of the startup ecosystem.</p>


          <br/>
          <p>If your company is looking to accelerate development, strengthen your team, or internalize your development processes, feel free to contact us.</p>
          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                Contact Us
              </a>
            </Link>
          </p>
        </div>
      ),
      ja: (
        <div className="grid gap-4">
          <p>
            この度、当社はGoogle for Startups Accelerator 2024のメンターとして選出されました。成長著しいスタートアップの技術支援や体制構築のサポートを行うことで、彼らのさらなる成長に貢献できることを大変光栄に感じております。
          </p>
          <p>Google for Startups Acceleratorは、優れたビジネスアイデアを持つスタートアップが急速に成長できるよう支援するプログラムです。メンターとして、私たちの知見を活かし、技術的なアドバイスや組織づくりのサポートを提供していきます。</p>
          <p>今後も引き続き、共創による価値創造を推進し、スタートアップエコシステムの発展に貢献してまいります。</p>

          <p>開発の迅速化、チーム体制の強化、または内製化を目指す企業様は、どうぞお気軽にお問い合わせください。</p>
          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                お問い合わせフォームへ
              </a>
            </Link>
          </p>
        </div>
      ),
    },
    date: '2023 Dec',
    label: 'Notice',
  },
  {
    id: '29',
    title: 'Breaking Away from Shopify: Innovating Customer Experience with a Custom E-Commerce Platform—Co-Creation Development Commenced',
    thumbnail: 'shikino-miyabi-start.png',
    cover: 'shikino-miyabi-start-cover.png',
    link: '/news/29',
    slug: 'shikino-miyabi-start',
    meta: {
      title:
        'Breaking Away from Shopify: Innovating Customer Experience with a Custom E-Commerce Platform—Co-Creation Development Commenced - News - wesionaryTEAM',
      keywords: 'EC system, Moving away from Shopify, Custom e-commerce development, Enhancing customer experience, Co-creation development',
      description: 'We have been selected as a co-creation partner for a project to build an EC system equipped with unique features and have commenced development. Aiming to expand our client\'s business and enhance customer experience, we support rapid service deployment through agile development.',
    },
    description: {
      en: (
        <div className="grid gap-4">
          <p>
            Many companies utilize e-commerce platforms like Shopify but face challenges in pursuing further growth, such as enhancing customer experience, implementing unique features, and achieving flexible integrations. We are pleased to announce that we have been selected as a co-creation partner to migrate from a Shopify-operated EC system to a custom-built platform, aiming to improve customer experience, implement unique functionalities, and realize flexible integrations.
          </p>

          <br/>
          <p className={`font-semibold`}>Challenges Faced by the Client Company and Project Background</p>
          <p>The client company recognized the need to transition from their existing Shopify-based EC system to a self-developed platform. This move is to realize more advanced features like a proprietary recommendation engine, enhanced user experience, expanded media functions (membership magazines), and support for diverse purchasing methods. Additionally, automating integrations with warehouses, other marketplaces, Instagram, and Google Ads to improve operational efficiency and maximize marketing effectiveness was a critical challenge.</p>

          <br/>
          <p className={`font-semibold`}>System Features and Expected Benefits</p>
          <p>The new system is planned to include core functionalities such as order management, inventory management, and master data management, along with a unique recommendation engine, media features, and support for various purchasing methods. Furthermore, by automating integrations with warehouses, other marketplaces, Instagram, and Google Ads, we expect improvements in operational efficiency and an expansion of customer touchpoints. Adopting an agile development approach, we aim for rapid business deployment by prioritizing and releasing high-priority core features first.</p>

          <br/>
          <p>If you are considering the development of an EC platform, core systems, or order management systems, please feel free to contact us. As your co-creation partner, we will provide proposals tailored to your situation, deeply understanding your current status and objectives.</p>

          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                Contact Us
              </a>
            </Link>
          </p>
        </div>
      ),
      ja: (
        <div className="grid gap-4">
          <p>
            多くの企業がShopifyなどのECプラットフォームを利用していますが、顧客体験の向上や独自機能の実装、柔軟な連携など、さらなる成長を目指す上で課題を抱えています。この度は、当社は共創パートナーとして選定され、Shopifyで運用されているECシステムから自社システムに移行し、顧客体験の向上、独自機能の実装、柔軟な連携を実現することを目指しています。
          </p>

          <br />
          <p className={`font-semibold`}>顧客企業の抱える課題とプロジェクトの背景</p>
          <p>顧客企業は、Shopifyで運用されていた既存のECシステムから自社開発のシステムへ移行する必要性を感じていました。独自のレコメンド機能やユーザー体験の強化、メディア機能（会報誌）の拡充、多様な購入方法のサポートなど、より高度な機能を実現するためです。また、倉庫や他のモール、Instagram、Google広告との自動連携を行い、業務効率化とマーケティング効果の最大化を図ることも重要な課題でした。</p>

          <br/>
          <p className={`font-semibold`}>システムの特長と期待される効果</p>
          <p>本システムは、発注管理、在庫管理、マスター管理などの基幹機能に加え、独自のレコメンドエンジン、メディア機能、多様な購入方法のサポートを備える予定です。さらに、倉庫や他のモール、Instagram、Google広告との自動連携により、業務効率の向上と顧客接点の拡大を期待しています。プロジェクトはアジャイル方式を採用し、優先度の高いコア機能を先行リリースすることで、迅速なビジネス展開を目指します。</p>
          <br/>

          <p>EC構築、基幹システム、受発注システムなどの開発をお考えの場合は、ぜひ当社までお問い合わせください。共創パートナーとして、現状と目標を深く理解した上、状況に適したご提案をさせていただきます。</p>
          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                お問い合わせフォームへ
              </a>
            </Link>
          </p>
        </div>
      ),
    },
    date: '2023 Dec',
    label: 'Business Enhancement',
  },
  {
    id: '36',
    title: 'We Have Taken Over the Maintenance and Operations of Knowledge Society\'s Shared Office Membership and Core Systems',
    thumbnail: 'knowledge-society-start.png',
    cover: 'knowledge-society-start-cover.png',
    link: '/news/36',
    slug: 'knowledge-society-start',
    meta: {
      title:
        'We Have Taken Over the Maintenance and Operations of Knowledge Society\'s Shared Office Membership and Core Systems - News - wesionaryTEAM',
      keywords: 'System maintenance handover, System documentation, Co-creation partner, New system development',
      description: 'We have taken over the maintenance and operations of Knowledge Society\'s shared office membership dashboard and core systems. If you are facing challenges with system quality improvements, performance enhancements, or new system development, please feel free to contact us.',
    },
    description: {
      en: (
        <div className="grid gap-4">
          <p>
            We are pleased to announce that wesionaryTEAM has taken over the maintenance and operational support for the shared office membership dashboard and core systems managed by Knowledge Society Inc.
          </p>
          <p>
            Over five companies have been involved in the maintenance and operations of this system in the past, leading to a lack of documentation and creating a "black box" environment. As a result, even simple changes have required significantly more time than expected, and adding or modifying functions to adapt to changes in the business environment has become increasingly difficult, starting to impact business operations.
          </p>
          <p>As a co-creation partner, we have taken on the role of maintaining and operating the existing system while simultaneously documenting and deepening our understanding of the system. In addition, we are in the process of organizing business processes and requirements in preparation for the development of a new system in the future.</p>

          <br/>
          <p>If you are facing challenges with system quality improvements, performance enhancements, or new system development, please feel free to contact us.</p>

          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                Contact Us
              </a>
            </Link>
          </p>
        </div>
      ),
      ja: (
        <div className="grid gap-4">
          <p>
            当社は、株式会社ナレッジソサエティが運営するシェアオフィスの会員ダッシュボード及び基幹システムの保守運用を引き継ぐこととなりました。
          </p>
          <p>
            これまでに5社以上の企業がこのシステムの保守運用に関わっており、ドキュメントの欠如によってシステムがブラックボックス化していました。その結果、簡単な変更でも予想以上の時間がかかり、また、事業環境の変化に伴う機能の追加や変更が難しく、事業運営に支障をきたし始めています。
          </p>
          <p>当社は共創パートナーとして、まずは既存システムの保守運用を引き継ぎ、システムの理解を深めながらドキュメント化を進めることになりました。また、業務プロセスと要件を整理し、将来的には新システムの開発を計画しています。</p>

          <br />
          <p>システムの品質改善、パフォーマンス改善、新システムの開発にお困りの場合は、お気軽にお問い合わせください。</p>
          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                お問い合わせフォームへ
              </a>
            </Link>
          </p>
        </div>
      ),
    },
    date: '2023 Nov',
    label: 'Business Enhancement',
  },
  {
    id: '20',
    title: 'Launch of a Co-Creation Project Aimed at Streamlining Industry-Specific Operations and Improving Communication',
    thumbnail: 'j-client-start.png',
    cover: 'j-client-start-cover.png',
    link: '/news/20',
    slug: 'j-client-start',
    meta: {
      title:
        'Launch of a Co-Creation Project Aimed at Streamlining Industry-Specific Operations and Improving Communication - News - wesionaryTEAM',
      keywords: 'Operational efficiency, DX, Digital transformation, IT product co-creation partner',
      description: 'We have launched a co-creation project to develop a new web-based system to meet our clients\' needs for improved operational efficiency and communication. Through digital transformation and process visualization, we aim to enhance efficiency and productivity.',
    },
    description: {
      en: (
        <div className="grid gap-4">
          <p>
            We have embarked on a co-creation project to develop a new web-based system that addresses our clients' needs for improved operational efficiency and enhanced communication.
          </p>
          <p>
            This system is designed to solve the challenges associated with email-based business communications and document exchanges, optimizing business processes from start to finish. As a co-creation partner, we will support our clients throughout the entire journey, from requirements definition and development to ongoing operations, ensuring their challenges are fully addressed.
          </p>

          <br/>
          <p className={`font-semibold`}>Challenges Faced by Our Clients and the Project Background</p>
          <p>Many of our clients rely heavily on email for business communication, leading to delays in responses and overlooked information. Additionally, managing submission deadlines and visualizing approval processes have proven difficult, resulting in decreased operational efficiency. These issues are common across various industries and place a significant burden on frontline staff.</p>
          <p>In this project, we work closely with our clients as their co-creation partner to define specific requirements and develop a system that optimizes their workflow, addressing these shared challenges.</p>

          <br/>
          <p className={`font-semibold`}>Key Features and Expected Benefits of the New System</p>
          <p>The new system will provide an integrated platform for messaging, data sharing, and submission management on the web. This will streamline communication between team members, ensuring important information is not lost, and enabling quick decision-making. The system’s status management and approval tracking features will help reduce delays and increase transparency in business operations. Additionally, the standardization and automation of tasks will help minimize human errors and reduce the workload on employees.</p>
          <p>The goal is to launch the system in April 2024, with planned features including multilingual support to accommodate both domestic and international locations.</p>

          <br/>
          <p>For companies interested in streamlining their workflows, visualizing business processes, and pursuing digital transformation (DX), we invite you to explore how our co-creation partnership can address your challenges. Please feel free to contact us to discuss how we can help you find the best solutions tailored to your needs.</p>

          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                Contact Us
              </a>
            </Link>
          </p>
        </div>
      ),
      ja: (
        <div className="grid gap-4">
          <p>
            弊社は、顧客企業の業務効率化とコミュニケーション改善のニーズに応えるため、新しいウェブシステムの開発を共創プロジェクトとしてスタートしました。
          </p>
          <p>このシステムは、従来のメールベースで行われていた業務連絡や資料の送受信の課題を解決し、業務プロセスの最適化を目指しています。要件定義から開発、そして運用までを一貫してサポートすることで、顧客企業の課題が解決することを見届ける予定です。</p>

          <br />
          <p className={`font-semibold`}>顧客企業の抱える課題とプロジェクトの背景</p>
          <p>顧客企業では、多くの業務がメールによるやり取りに依存しており、対応の遅れや情報の見落としが頻発していました。また、提出物の期限管理や承認プロセスの見える化が難しく、業務効率の低下を招いていました。これらの課題は、業種を問わず多くの企業が直面している問題であり、現場での負担を大きくしています。</p>
          <p>このプロジェクトでは、弊社がプロダクト共創のパートナーとして、顧客とともに要件を具体化し、業務の流れを最適化するシステムの開発を進めています。</p>

          <br/>
          <p className={`font-semibold`}>システムの特長と期待される効果</p>
          <p>当システムは、Web上でのメッセージングやデータの共有、提出物の管理を一括で行えるシステムとなります。これにより、担当者間のコミュニケーションが効率化され、重要な情報が埋もれずに迅速な意思決定が可能になります。また、各種提出物のステータス管理や承認プロセスの追跡機能により、対応の遅延を減少させ、業務の透明性を高めます。さらに、業務の標準化と自動化により、人的ミスを減らし、従業員の負担を軽減します。</p>
          <p>2024年6月の運用開始を目標にしており、国内外の拠点にも対応可能な多言語機能の実装を計画しています。</p>
          <br/>

          <p>業務プロセスの整理と可視化、業務のデジタルトランスフォーメーション（DX化）に関心のある企業の皆様、ぜひお気軽にご相談ください。プロダクト共創のパートナーとして、貴社の課題に寄り添い、最適な解決策をご提案します。</p>
          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                お問い合わせフォームへ
              </a>
            </Link>
          </p>
        </div>
      ),
    },
    date: '2023 Nov',
    label: 'DX',
  },
  {
    id: '38',
    title: 'We are excited to announce the launch of "Coaching Cloud" to streamline coaching programs and enhance engagement',
    thumbnail: 'coaching-cloud-released.png',
    cover: 'coaching-cloud-released-cover.png',
    link: '/news/38',
    slug: 'coaching-cloud-released',
    meta: {
      title:
        'We are excited to announce the launch of "Coaching Cloud" to streamline coaching programs and enhance engagement',
      keywords: 'Training system, Operational efficiency, Attendance management, Digital transformation, DX',
      description: 'We are pleased to announce the release of the new coaching platform "Coaching Cloud," designed to streamline scheduling and online surveys while improving data analysis efficiency. If you are considering improving operational efficiency, visualizing and optimizing business processes, or undertaking digital transformation (DX), please feel free to contact us.',
    },
    description: {
      en: (
        <div className="grid gap-4">
          <p>
            We are pleased to announce the release of "Coaching Cloud," a new coaching platform developed in collaboration with Coach Contribution Corporation. This platform is designed to improve operational efficiency and boost engagement in coaching services.
          </p>

          <br/>
          <p className={`font-semibold`}>Challenges Faced by the Client and Project Background</p>
          <p>
            Coach Contribution Corporation faced several challenges in managing their coaching services, including scheduling, skill assessments, and engagement and wellbeing surveys, which were being handled through email and phone-based processes. These issues resulted in inefficiencies and difficulties in data analysis.
          </p>
          <ul>
            <li>Inefficient scheduling management: Coordinating schedules via email and phone was time-consuming, prone to errors, and often caused delays.</li>
            <li>Complexity in skill and engagement assessments: Manual data collection and analysis required significant effort, leading to delays in reviewing and utilizing the results.</li>
            <li>Challenges with data analysis: Organizing and analyzing the collected data was inefficient, slowing down decision-making processes.</li>
          </ul>
          <p>To address these challenges and enhance service quality and operational efficiency, we embarked on a co-creation project with the client.</p>

          <br/>
          <p className={`font-semibold`}>Key Features and Expected Benefits of the Product</p>
          <p>"Coaching Cloud" offers a centralized, intuitive user interface that consolidates scheduling management and automates attendance tracking with reminder functions and rescheduling notifications. Additionally, it allows for online skill assessments and engagement and wellbeing surveys, with results presented graphically for easy comparison. These features are expected to drive operational efficiency and facilitate data-driven decision-making while improving participant engagement.</p>
          <ul>
            <li>Centralized scheduling management: The intuitive user interface makes it easy to view and adjust schedules, and attendance tracking is automated with reminders and rescheduling notifications.</li>
            <li>Online skill assessments and engagement tracking: The platform enables online assessments of skills, engagement, and wellbeing, with results displayed in graphs for visual analysis and quick decision-making.</li>
            <li>Promotes data-driven decision-making: Efficient data collection and analysis processes support not only operational improvements but also boost participant engagement.</li>
          </ul>
          <p>By leveraging these capabilities, "Coaching Cloud" is expected to enhance both operational efficiency and participant engagement while enabling more effective, data-driven decision-making.</p>

          <br/>
          <p className={`font-semibold`}>Product Feedback and Future Outlook</p>
          <p>Since its release, users have praised "Coaching Cloud" for its ease of use, convenient scheduling management, and the efficiency of online surveys. Users also appreciated the ability to visualize results through graphs. Moving forward, we will continue to expand the platform’s features and improve its services, helping more companies improve their operational efficiency and engagement levels.</p>

          <br/>
          <p>If you are considering improving operational efficiency, visualizing and optimizing business processes, or undergoing digital transformation (DX), please feel free to contact us. We will analyze your current situation, set clear objectives and goals, and provide the best solutions to achieve them.</p>

          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                Contact Us
              </a>
            </Link>
          </p>
        </div>
      ),
      ja: (
        <div className="grid gap-4">
          <p>
            当社はこの度、コーチング事業を展開するコーチコントリビューション株式会社と共同で、業務効率化とエンゲージメント向上を実現する新たなコーチングプラットフォーム『Coaching Cloud』をリリースいたしました。
          </p>

          <br/>
          <p className={`font-semibold`}>企業の抱える課題とプロジェクトの背景</p>
          <p>コーチコントリビューション株式会社様は、コーチングサービスにおけるスケジュール管理やスキル調査、エンゲージメント・ウェルビーイング調査をメールや電話ベースで行っており、業務効率の低下やデータ分析の困難さに課題を抱えておられました。</p>
          <ul>
            <li>スケジュール管理の非効率性**: メールや電話でのスケジュール調整は時間と手間がかかり、ミスや遅延が生じやすい。</li>
            <li>スキル調査やエンゲージメント調査の複雑さ**: 手作業でのデータ収集と分析が必要であり、結果の確認や活用が遅れる。</li>
            <li>データ分析の困難さ**: 集めたデータの整理や分析が非効率的で、意思決定に時間がかかる。</li>
          </ul>
          <p>これらの課題を解決し、サービス品質と業務効率を向上させるため、当社との共創プロジェクトが始動しました。</p>

          <br/>
          <p className={`font-semibold`}>プロダクトの特長と期待している効果</p>
          <p>『Coaching Cloud』は、直感的なユーザーインターフェースでスケジュール管理を一元化し、リマインド機能や振替案内の自動送信により出欠管理を効率化します。さらに、オンラインでのスキル調査やエンゲージメント・ウェルビーイング調査を可能にし、結果をグラフで確認・比較できる機能を搭載しています。</p>
          <ul>
            <li>一元化されたスケジュール管理**: 直感的なUIでスケジュールの確認・変更が容易になり、リマインド機能や振替案内の自動送信により、出欠管理が効率化されます。</li>
            <li>オンラインスキル調査とエンゲージメント測定**: オンラインでスキルやエンゲージメント、ウェルビーイングを調査し、その結果をグラフで視覚的に確認・比較できるため、データに基づいた意思決定が迅速に行えます。</li>
            <li>データドリブンな意思決定の促進**: 効率的なデータ収集と分析により、業務効率化だけでなく、受講者のエンゲージメント向上が期待できます。</li>
          </ul>
          <p>これにより、業務の効率化だけでなく、受講者のエンゲージメント向上とデータドリブンな意思決定が期待されます。</p>

          <br/>
          <p className={`font-semibold`}>プロダクトに寄せられた評価と今後の展望</p>
          <p>リリース後、利用者からは「使いやすくスケジュール管理が便利」「オンライン調査で手間が省ける」「結果をグラフで見られるのが素晴らしい」と高い評価をいただいております。今後も機能拡充とサービス改善を続け、より多くの企業様の業務効率化とエンゲージメント向上に貢献してまいります。</p>

          <br/>
          <p>教育関連のシステム開発、業務効率化、デジタルトランスフォーメーション（DX）をご検討中のお客様は、ぜひご相談ください。現状の分析と可視化を通じて、明確な目的と目標を設定し、それに基づいた最適なソリューションをご提案いたします。</p>

          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                お問い合わせフォームへ
              </a>
            </Link>
          </p>
        </div>
      ),
    },
    date: '2023 Oct',
    label: 'DX',
  },
    {
        id: '40',
        title: 'Industry-Specific Recruitment Platform Development Begins',
        thumbnail: 'agents-start.png',
        cover: 'agents-start-cover.png',
        link: '/news/40',
        slug: 'agents-start',
        meta: {
            title:
                'Industry-Specific Recruitment Platform Development Begins - News - wesionaryTEAM',
            keywords: 'Corporate wellness system, personalized health support, co-creation for new business, Digital transformation',
            description: 'We have launched "Life Pilot," a health management platform co-developed with RASCAL\'s Co., Ltd. If your company is looking to develop new wellness projects, enhance existing operations, or accelerate digital transformation (DX), please contact us.',
        },
        description: {
            en: (
                <div className="grid gap-4">
                    <p>
                        We have been selected as a co-creation partner to address challenges in the recruitment industry and have commenced the development of a specialized recruitment platform. This project focuses on creating a job site and career support service tailored specifically for taxi drivers, security personnel, and similar roles. Through close collaboration with our client, we aim to deliver a more effective and efficient matching service.
                    </p>

                    <br/>
                    <p className={`font-semibold`}>Client Challenges and Project Background</p>
                    <p>
                        Our client faced challenges in managing job information specific to their industry and in coordinating efficiently with multiple recruitment agencies. As a result, communication between recruiting companies and agencies was not as smooth as it needed to be, making it difficult to ensure accurate matching and timely responses to job seekers. This project stems from a shared understanding that a specialized matching system is essential to energize the flow of talent within the industry as a whole.
                    </p>

                    <br/>
                    <p className={`font-semibold`}>System Features and Expected Benefits</p>
                    <p>At the core of this project is a system that allows recruiting companies to freely post and manage job listings, while also facilitating seamless communication with recruitment agencies. On the agency side, they will have access to industry-specific job information, enabling them to search more efficiently and present relevant job opportunities to candidates.</p>
                    <p>The anticipated benefits include improved matching rates between job seekers and recruiting companies, as well as promoting talent circulation across the entire industry. Although the project is still in its early stages, we aim to address the challenges faced by numerous companies and job seekers and support sustainable growth through this platform.</p>

                    <br/>
                    <p>If you are considering the development of a recruitment service, enhancement of existing services, or launching a new business, please feel free to contact us. Through research and prototyping, we will clarify the value proposition and necessary features, and work together toward bringing your vision to life.</p>

                    <p>
                        <Link href="/contact" passHref>
                            <a
                                className={`text-sm hover:underline md:self-end`}
                            >
                                Contact Us
                            </a>
                        </Link>
                    </p>
                </div>
            ),
            ja: (
                <div className="grid gap-4">
                    <p>
                        当社は、人材紹介業界における課題を解決するための共創パートナーとして選定され、人材紹介プラットフォームの開発に着手いたしました。このプロジェクトは、タクシードライバーや警備スタッフなどに特化した求人サイトおよび転職支援サービスの開発を目的としています。顧客企業と連携し、より効果的で効率的なマッチングサービスの提供を目指します。
                    </p>

                    <br/>
                    <p className={`font-semibold`}>顧客企業の抱える課題とプロジェクトの背景</p>
                    <p>顧客企業は、特定業界における求人情報の管理と、複数の人材紹介エージェントとの連携が効率的に行えないという課題に直面していました。これにより、求人企業とエージェントのコミュニケーションがスムーズに行われず、求職者のマッチング精度や迅速な対応が困難となっていました。プロジェクトの背景には、業界全体の人材流通を活性化するために、専門的なマッチングシステムの構築が必要という共通認識があります。</p>

                    <br/>
                    <p className={`font-semibold`}>システムの特長と期待される効果</p>
                    <p>本プロジェクトの中心となるシステムは、求人企業が自由に求人情報を掲載できる機能や、エージェントとのマッチングを円滑に進めるためのメッセージ管理機能を備えています。エージェント側も、業界特化型の求人情報を効率的に検索し、求職者に提案できるようになります。</p>
                    <p>期待される効果として、求職者と求人企業のマッチング率の向上や、業界全体の人材流通の促進が挙げられます。プロジェクトはまだ開始段階ですが、今後、システムを通じて多くの企業と求職者の課題を解決し、持続的な成長を支援することを目指しています。</p>

                    <br/>
                    <p>人材紹介サービスの開発や既存サービスの改善、新規事業の立ち上げをお考えの際は、ぜひお問い合わせください。リサーチやプロトタイピングを通じて、提供価値や必要な機能を明確化し、共に実現に向けた取り組みを進めていきます。</p>

                    <p>
                        <Link href="/contact" passHref>
                            <a
                                className={`text-sm hover:underline md:self-end`}
                            >
                                お問い合わせフォームへ
                            </a>
                        </Link>
                    </p>
                </div>
            ),
        },
        date: '2023 Oct',
        label: 'New business co-creation',
    },
    {
    id: '33',
    title: 'Launching the "Life Pilot" Health Management Platform to Support Effective Corporate Wellness Initiatives',
    thumbnail: 'life-pilot-released.png',
    cover: 'life-pilot-released-cover.png',
    link: '/news/33',
    slug: 'life-pilot-released',
    meta: {
      title:
        'Launching the "Life Pilot" Health Management Platform to Support Effective Corporate Wellness Initiatives - News - wesionaryTEAM',
      keywords: 'Corporate wellness system, personalized health support, co-creation for new business, Digital transformation',
      description: 'We have launched "Life Pilot," a health management platform co-developed with RASCAL\'s Co., Ltd. If your company is looking to develop new wellness projects, enhance existing operations, or accelerate digital transformation (DX), please contact us.',
    },
    description: {
      en: (
        <div className="grid gap-4">
          <p>
            We are pleased to announce the release of the "Life Pilot" platform, developed in collaboration with RASCAL's Co., Ltd. This innovative product is designed to elevate corporate wellness to the next level by automatically delivering tailored health information to each employee, significantly boosting overall company performance.
          </p>

          <br/>
          <p className={`font-semibold`}>Challenges Faced by Companies and Project Background</p>
          <p>
            Companies participating in this project have been focusing on corporate wellness, viewing employee health from a management perspective. However, many businesses face the following challenges:
          </p>

          <ul>
            <li>Difficulty tracking the implementation of wellness initiatives and visualizing overall progress.</li>
            <li>Inability to monitor employee health in real-time, making timely responses challenging.</li>
            <li>Health information sharing was done manually, leading to reduced overall efficiency.</li>
          </ul>
          <p>To address these challenges, we partnered with RASCAL's to develop a platform that provides comprehensive management of employee health.</p>

          <br/>
          <p className={`font-semibold`}>Platform Features and Expected Benefits</p>
          <p>"Life Pilot" offers the following features to help companies overcome their corporate wellness challenges:</p>
          <ul>
            <li>Personalized Health Information Distribution via LINE: Automatically sends tailored health advice and information to employees based on their conditions, promoting greater health awareness.</li>
            <li>Real-Time Visualization of Wellness Initiatives: Administrators can view employee health statuses and the effectiveness of wellness programs in real time, enabling swift decision-making.</li>
            <li>Data-Driven Approach: Equipped with tools that quantitatively measure the effectiveness of wellness initiatives, helping companies sustain growth while optimizing employee health.</li>
          </ul>
          <p>With these features, companies can expect to maintain employee well-being while improving operational efficiency and productivity.</p>

          <br/>
          <p className={`font-semibold`}>Feedback and Future Outlook</p>
          <p>The companies that have implemented "Life Pilot" have praised it as both "efficient and easy to use." We plan to further enhance its functionality by integrating it with other tools and incorporating AI, aiming to support even more businesses in achieving their corporate wellness goals and contributing to industry-wide advancements.</p>

          <br/>
          <p>If your company is considering developing new wellness initiatives, enhancing existing operations, or driving digital transformation (DX), we encourage you to reach out. We will provide full support, from organizing your current situation to setting clear objectives and achieving your goals.</p>

          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                Contact Us
              </a>
            </Link>
          </p>
        </div>
      ),
      ja: (
        <div className="grid gap-4">
          <p>
            当社はこの度、株式会社RASCAL'sと共に、企業の健康経営を次のレベルに引き上げるプラットフォーム『Life Pilot』をリリースしました。このプロダクトは、従業員一人ひとりに最適な健康情報を自動的に提供し、企業のパフォーマンスを大幅に向上させる強力なツールです。
          </p>

          <br/>
          <p className={`font-semibold`}>企業の抱える課題とプロジェクトの背景</p>
          <p>今回のプロジェクトに参画した企業は、従業員の健康状態とその影響を経営視点で捉える「健康経営」に注力しています。しかし、多くの企業が下記の課題に直面しています。</p>
          <ul>
            <li>健康管理施策の実施状況が把握しにくく、全体の進捗を見える化できていなかった。</li>
            <li>従業員の健康状態をリアルタイムで把握できず、迅速な対応が困難だった。</li>
            <li>健康情報の共有が手作業で行われており、全体の効率が低下していた。</li>
          </ul>
          <p>これらの課題を解決するため、私たちは株式会社RASCAL'sと共に、従業員の健康を包括的に管理するためのプラットフォームを開発しました。</p>

          <br/>
          <p className={`font-semibold`}>プラットフォームの特長と期待される効果</p>
          <p>『Life Pilot』は、企業が抱える健康経営に関する課題を解決するために以下の機能を提供します。</p>
          <ul>
            <li>LINEを活用したパーソナライズドな健康情報配信**：従業員の状況に応じた健康アドバイスや情報を自動で配信し、健康意識の向上を促進します。</li>
            <li>効果的な健康施策の可視化**：管理者は、従業員全体の健康状態や施策の効果をリアルタイムで確認でき、迅速な意思決定が可能です。</li>
            <li>データドリブンなアプローチ**：健康施策の効果を定量的に測定する機能を搭載し、企業が持続可能な成長を目指せるようサポートします。</li>
          </ul>
          <p>これにより、企業は従業員の健康を維持しながら、業務の効率化と生産性向上を図ることが期待されています。</p>

          <br/>
          <p className={`font-semibold`}>プロダクトに寄せられた評価と今後の展望</p>
          <p>導入いただいた企業様から「効率的で使いやすい」という高評価を受けています。今後は他のツールとの連携やAIを活用したさらなる機能強化を予定しており、より多くの企業の健康経営を支援することで、業界全体の発展に貢献してまいります。</p>

          <br/>
          <p>健康関連の新規事業の開発や既存事業の強化、業務のデジタルトランスフォーメーション（DX化）などをお考えの企業様、ぜひお問い合わせください。現状を整理し、正しく明確な目的と目標を定めた上で、実現に向けて全力でサポートいたします。</p>

          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                お問い合わせフォームへ
              </a>
            </Link>
          </p>
        </div>
      ),
    },
    date: '2023 Sep',
    label: 'New business co-creation',
  },
  {
    id: '28',
    title: 'Released \'Tsuhan Sozai\'—Achieving Efficiency in Order Operations and Enhanced UX',
    thumbnail: 'tsuhan-sozai-released.png',
    cover: 'tsuhan-sozai-released-cover.png',
    link: '/news/28',
    slug: 'tsuhan-sozai-released',
    meta: {
      title:
        'Released \'Tsuhan Sozai\'—Achieving Efficiency in Order Operations and Enhanced UX - News - wesionaryTEAM',
      keywords: 'Order Efficiency, User Experience Enhancement, Furniture Wholesale, Co-creative Product Development',
      description: 'Announcing the release of \'Tsuhan Sozai,\' an order management platform for furniture and interior goods wholesale. Achieve operational efficiency and enhanced user experience. We fully support your business growth through co-creative product development.',
    },
    description: {
      en: (
        <div className="grid gap-4">
          <p>
            We are pleased to announce the release of 'Tsuhan Sozai', an order management platform
            specialized for the wholesale of furniture and interior goods, developed in collaboration with
            Cospa Creation Co., Ltd. This product aims to address industry challenges by enhancing
            operational efficiency and improving user experience.
          </p>

          <br/>
          <p className={`font-semibold`}>Challenges Faced by the Client Company and Project Background</p>
          <p>
            Cospa Creation Co., Ltd. was encountering the following challenges:
          </p>

          <ul>
            <li>Difficulty in searching for product information and downloading individual items, requiring
              users to retrieve all data before finding the necessary information.
            </li>
            <li>Limited payment options, unable to meet the demand for automation through credit card
              payments.
            </li>
            <li>Complex procedures for order placement and invoice issuance, hindering the user experience
              for new business partners.
            </li>
          </ul>
          <p>To resolve these issues and improve operational efficiency and customer satisfaction, we
            participated in the project as a co-creation partner.</p>

          <br/>
          <p className={`font-semibold`}>Product Features and Expected Benefits</p>
          <p>'Tsuhan Sozai' offers the following features:</p>
          <ul>
            <li>Intuitive User Interface: A simple and user-friendly UI that makes product search and
              individual downloads effortless.
            </li>
            <li>Diverse Payment Options: Supports credit card payments, automating the payment process.</li>
            <li>Simplified Procedures: Optimized steps for order placement and invoice issuance, allowing
              new business partners to operate smoothly.
            </li>
          </ul>
          <p>These features are expected to significantly enhance operational efficiency and customer
            satisfaction.</p>

          <br/>
          <p className={`font-semibold`}>Evaluation of the Product and Future Outlook</p>
          <p>Post-release, we have received high praise from numerous users, stating that it has become "much easier to use." We will continue to expand functionalities and improve services, contributing to the development of the entire industry.</p>

          <br/>
          <p>If your company is facing similar challenges or considering improving the efficiency of order operations and enhancing user experience, please feel free to contact us. Through co-creative product development, we are committed to fully supporting the growth of your business.</p>

          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                Contact Us
              </a>
            </Link>
          </p>
        </div>
      ),
      ja: (
        <div className="grid gap-4">
          <p>
            当社はこの度、株式会社コスパクリエーション様と共に、家具・インテリア雑貨の卸売業務に特化した受発注プラットフォーム『通販素材』をリリースいたしました。本プロダクトは、業務効率とユーザー体験の向上を目指し、業界の課題解決に貢献することを目的としています。
          </p>

          <br/>
          <p className={`font-semibold`}>株式会社コスパクリエーション様の抱える課題とプロジェクトの背景</p>
          <p>株式会社コスパクリエーション様では、以下の課題を抱えておられました。</p>
          <ul>
            <li>商品情報の検索や個別ダウンロードが困難で、全データを取得してから必要な情報を探す手間が発生していた。</li>
            <li>決済方法が限定的で、クレジットカード決済による自動化のニーズに対応できていなかった。</li>
            <li>受発注や請求書発行の手順が複雑で、新規取引先の利用体験を阻害していた。</li>
          </ul>
          <p>これらの課題を解決し、業務効率と顧客満足度を向上させるため、当社が共創パートナーとしてプロジェクトに参画いたしました。</p>

          <br/>
          <p className={`font-semibold`}>プロダクトの特長と期待している効果</p>
          <p>『通販素材』は以下の特長を持っています。</p>
          <ul>
            <li>直感的なユーザーインターフェース：シンプルで使いやすいUIにより、商品検索や個別ダウンロードが容易になりました。</li>
            <li>多様な決済手段の導入：クレジットカード決済に対応し、決済プロセスの自動化を実現しました。</li>
            <li>手順の簡素化：受発注や請求書発行の手順を最適化し、新規取引先でもスムーズに操作できるようになりました。</li>
          </ul>
          <p>これらにより、業務効率の大幅な向上と顧客満足度の向上が期待されます。</p>

          <br/>
          <p className={`font-semibold`}>プロダクトに寄せられた評価と今後の展望</p>
          <p>リリース後、多くのユーザーから「とても使いやすくなった」との高評価をいただいております。今回のプロジェクトは、当社が掲げる「共創によるプロダクト開発」の理念を体現するものであり、株式会社コスパクリエーション様のビジネス成長に貢献できたことを大変嬉しく思います。</p>

          <br/>
          <p>同様の課題をお持ちの企業様や、受発注業務の効率化・ユーザー体験の向上をお考えの企業様は、ぜひ当社までお問い合わせください。共創型プロダクト開発を通じて、貴社のビジネス成長を全力でサポートいたします。</p>

          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                お問い合わせフォームへ
              </a>
            </Link>
          </p>
        </div>
      ),
    },
    date: '2023 Sep',
    label: 'Business Enhancement',
  },
  {
    id: '26',
    title: 'Introducing "D-Order," a Cloud Service for Streamlining Orders in the Construction Industry',
    thumbnail: 'd-order-start-released.png',
    cover: 'd-order-start-released-cover.png',
    link: '/news/26',
    slug: 'd-order-start-released',
    meta: {
      title:
        'Introducing "D-Order," a Cloud Service for Streamlining Orders in the Construction Industry - News - wesionaryTEAM',
      keywords: 'Construction Industry DX, Order Management Cloud Service, Operational Efficiency, DX Promotion, Order Process, Order Management',
      description: 'We have released "D-Order," a cloud service co-developed with Atsuta Kensou Co., Ltd., tailored for the construction industry. D-Order streamlines complex order processes and improves communication between all parties involved, aiming to standardize operations and boost productivity.',
    },
    description: {
      en: (
        <div className="grid gap-4">
          <p>
            We are excited to announce the release of the beta version of "D-Order," a cloud service co-developed with Atsuta Kensou Co., Ltd., in August 2023. D-Order is designed to simplify and standardize the complex order management processes in the construction industry, making communication between all parties involved more efficient.
          </p>

          <br/>
          <p className={`font-semibold`}>How D-Order Solves Challenges in the Construction Industry</p>
          <p>
            The construction industry involves numerous stakeholders, including craftsmen, general contractors, manufacturers, trading companies, and logistics providers. Miscommunications and delays in operations are common. D-Order addresses these challenges and supports improved productivity across the entire industry. Here are some key features of the service:
          </p>

          <ul>
            <li>Unified Ordering Process: D-Order consolidates traditional methods like Line, phone calls, and fax into a single, easy-to-use system, allowing anyone to place orders seamlessly.</li>
            <li>Accurate Information Sharing: The service ensures accurate sharing of order information to prevent errors such as incorrect product codes or quantities.</li>
            <li>Easy Management of Order History: Users can quickly search past order histories, enabling prompt responses to business needs.</li>
            <li>Real-Time Delivery Coordination: D-Order enhances efficiency by enabling real-time coordination of truck schedules and delivery times, addressing a common challenge in the industry.</li>
            <li>Invoice Accuracy and Transparency: The service helps prevent discrepancies between order amounts and manufacturer invoices, avoiding potential disputes.</li>
          </ul>

          <br />

          <p>
            For those interested in driving digital transformation (DX) in the construction industry, improving operational efficiency, or developing new business ventures, D-Order offers a powerful solution. Our experienced team, with years of expertise in the industry, is ready to provide dedicated support.
          </p>

          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                Contact Us
              </a>
            </Link>
          </p>
        </div>
      ),
      ja: (
        <div className="grid gap-4">
          <p>
            株式会社アツタ建綜と当社の共同開発により、建設業界の受発注業務を大幅に効率化するクラウドサービス「D-Order」のベータ版を2023年8月にリリースしました。このサービスは、建設業界特有の煩雑な受発注プロセスを統一し、関係者間のコミュニケーションをシンプルにすることを目指しています。
          </p>

          <br/>
          <p className={`font-semibold`}>「D-Order」が解決する建設業界の課題</p>
          <p>建設業界では、職人、元請、メーカー、商社、運送業者など多くの関係者が関わる中で、情報の行き違いや作業の遅延が頻発していました。「D-Order」は、これらの課題に対処し、業界全体の生産性向上を支援します。以下は本サービスの主な特徴です。</p>
          <ul>
            <li>発注プロセスの統一: 従来のLine、電話、FAXなどの方法を一元化し、誰でも簡単に発注ができるシステムを提供します。</li>
            <li>情報の正確な伝達: 材料の品番ミスや数量の誤解を防ぐため、発注情報が正確に共有される仕組みを実現しました。</li>
            <li>発注履歴の簡単管理: 過去の発注履歴を簡単に検索できるため、迅速な業務対応が可能です。</li>
            <li>リアルタイムの搬入調整: 業界全体で課題となっていた搬入トラックの順番や時間の調整をリアルタイムで行い、効率を向上させます。</li>
            <li>請求金額の確認と透明性の確保: 発注時の金額とメーカーからの請求金額の差異を防ぎ、トラブルを未然に防止します。</li>
          </ul>

          <br/>
          <p>建設業界のDX推進や業務改善、新規事業の開発に関心のある方は、どうぞお気軽にお問い合わせください。長年の業界経験を持つ専門チームが手厚くサポートいたします。</p>

          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                お問い合わせフォームへ
              </a>
            </Link>
          </p>
        </div>
      ),
    },
    date: '2023 Aug',
    label: 'New business co-creation',
  },
  {
    id: '34',
    title: 'We Have Taken Over the Maintenance and Operations of the GraceBank Platform',
    thumbnail: 'gracebank-start.png',
    cover: 'gracebank-start-cover.png',
    link: '/news/34',
    slug: 'gracebank-start',
    meta: {
      title:
        'We Have Taken Over the Maintenance and Operations of the GraceBank Platform - News - wesionaryTEAM',
      keywords: 'System maintenance handover, System performance improvement, System quality improvement',
      description: 'We have taken over the maintenance and operations of the GraceBank platform, focusing on improving performance and quality.',
    },
    description: {
      en: (
        <div className="grid gap-4">
          <p>
            We are pleased to announce that wesionaryTEAM has taken over the maintenance and operational support for the GraceBank platform, provided by Grace Group.
          </p>
          <p>
            Grace Group identified challenges with the previous development company's approach regarding additional development costs, system performance, and overall quality. Recognizing the need for a more efficient and reliable maintenance structure, they entrusted us with ensuring the platform’s continuous improvement.
          </p>
          <p>
            Leveraging our proven track record and technical expertise, we will focus on optimizing the system, providing ongoing improvements and support. Our goal is to enhance user convenience and ensure the platform operates smoothly and reliably. We remain committed to delivering even greater value to GraceBank in the future.
          </p>

          <br/>
          <p>If your organization faces challenges related to system quality, performance, or achieving stable maintenance, please feel free to reach out to us.</p>
          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                Contact Us
              </a>
            </Link>
          </p>
        </div>
      ),
      ja: (
        <div className="grid gap-4">
          <p>
            当社は、株式会社グレイスグループが提供する「GraceBank」プラットフォームの保守運用を引き継ぐこととなりました。
          </p>
          <p>株式会社グレイスグループは、これまでの開発会社との協力において、追加開発費用やシステムのパフォーマンス、品質に関して課題を感じており、より効率的かつ信頼性の高い保守運用体制の必要性を認識されていました。</p>
          <p>当社は、これまでの実績と技術力を活かし、システムの最適化を図るとともに、継続的な改善とサポートを提供いたします。今後も、ユーザーの利便性向上と安定したサービス運営を支援し、さらに価値あるプラットフォームとなるよう努めてまいります。</p>

          <br/>
          <p>システムの品質改善、パフォーマンス改善、安定した保守運用の実現にお困りの場合は、お気軽にお問い合わせください。</p>
          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                お問い合わせフォームへ
              </a>
            </Link>
          </p>
        </div>
      ),
    },
    date: '2023 Jul',
    label: 'Business Enhancement',
  },
  {
    id: '22',
    title: 'We Have Started Developing an App to Improve the Efficiency of Delivery Inquiry Operations',
    thumbnail: 'chat-yc-start.png',
    cover: 'chat-yc-start-cover.png',
    link: '/news/22',
    slug: 'chat-yc-start',
    meta: {
      title:
        'We Have Started Developing an App to Improve the Efficiency of Delivery Inquiry Operations - News - wesionaryTEAM',
      keywords: 'Digital transformation, Operational efficiency, Process optimization, DX',
      description: 'We have started developing a new app aimed at improving the efficiency of delivery inquiries and customer support. The app features chat functionality tailored for delivery operations, response status management, and in-house data management, enhancing security and optimizing operations.',
    },
    description: {
      en: (
        <div className="grid gap-4">
          <p>
            We have begun developing a new app aimed at improving the efficiency of customer support
            operations related to delivery inquiries. This project responds to the needs of our client
            companies, supporting their digital transformation (DX) as a co-creation partner.
          </p>

          <br/>
          <p className={`font-semibold`}>Background and Challenges Faced by Our Client Companies</p>
          <p>
            As delivery services expand, the workload of customer support operations has been increasing for
            our client companies. Handling inquiries from retail outlets and delivery personnel, as well as
            communicating changes in delivery routes, delays, and other issues, is often managed via phone,
            email, or fax, leading to increasingly complex operations.
          </p>
          <p>
            These communications rely heavily on closed channels, making it difficult to share information
            smoothly and for all stakeholders to keep track of the current status. Moreover, managing
            inquiry details through manual entry into Excel and printing creates additional administrative
            burdens, highlighting the need for operational efficiency improvements.
          </p>

          <br/>
          <p className={`font-semibold`}>System Features and Expected Benefits</p>
          <p>The system under development is specifically designed for delivery operations, aiming to streamline communication between delivery personnel and customer support teams. The main features and expected benefits are as follows:</p>
          <ul>
            <li>Chat-Based Communication Tool: Replacing traditional phone and email communication, the app introduces a chat function that allows for real-time information sharing. This feature accelerates communication on the delivery frontlines and helps prevent delays in operations.</li>
            <li>Management of Response Status: The system manages the status of inquiry responses, allowing real-time tracking of response progress. This ensures no inquiries are missed, clearly identifies who is handling each inquiry, and facilitates reliable follow-ups.</li>
            <li>Enhanced Security Through In-House Data Management: All data within the system is centrally managed in-house, reducing security risks and providing a safe operational environment. Looking forward, the data collected can be analyzed to further optimize delivery operations and support predictive analytics with AI.</li>
          </ul>

          <br />
          <p>For companies interested in visualizing and optimizing their business processes and exploring digital transformation (DX), we invite you to reach out. We are here to help you understand and analyze your current challenges systematically and offer the best solutions.</p>

          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                Contact Us
              </a>
            </Link>
          </p>
        </div>
      ),
      ja: (
        <div className="grid gap-4">
          <p>
            当社は、宅配の問い合わせ対応（カスタマーサポート）の効率化を目的とした新しいアプリの開発に着手しました。このプロジェクトは、顧客企業の要望を受けて、プロダクト共創のパートナーとして業務のデジタルトランスフォーメーション（DX）を支援するものです。
          </p>

          <br/>
          <p className={`font-semibold`}>顧客企業の抱える課題とプロジェクトの背景</p>
          <p>配送サービスの拡大に伴い、顧客企業ではカスタマーサポート業務の負荷が増大しています。販売店、配達員からの問い合わせ対応や、配送ルートの変更や遅延に関する連絡事項の伝達が、電話やメール、FAXを通じて行われており、業務が複雑化しています。</p>
          <p>これらのやり取りは、クローズドなコミュニケーションに依存しているため、情報を円滑に共有することが難しく、関係者が状況を把握しながら協同で業務を遂行することが困難です。さらに、問い合わせ内容の管理がエクセルの転記や印刷に依存しているため、管理の手間がかかり、業務の効率化が求められていました。</p>

          <br/>
          <p className={`font-semibold`}>システムの特長と期待される効果</p>
          <p>開発中のシステムは、宅配業務に特化した機能を備え、宅配業者とカスタマーサポート間のコミュニケーションを効率化することを目指しています。主な特長と期待される効果は以下の通りです。</p>
          <ul>
            <li>チャットベースのコミュニケーションツール：従来の電話やメールに代わり、リアルタイムで情報を共有できるチャット機能を導入します。これにより、配送現場でのコミュニケーションが迅速化され、業務の遅延を防ぎます。</li>
            <li>対応ステータスの管理機能：問い合わせ対応のステータスをシステム上で管理し、対応状況をリアルタイムで把握することで、対応漏れを防止します。これにより、誰がどの問い合わせを担当しているかが明確になり、確実なフォローアップが可能となります。</li>
            <li>データの自社管理によるセキュリティの向上：システム上でのデータは自社で一元管理されるため、セキュリティリスクを低減し、安心して運用することが可能です。今後のAI活用に向けて、蓄積されたデータを解析し、配送業務のさらなる最適化や予測分析に役立てることが期待されます。</li>
          </ul>

          <p>業務プロセスの見える化と最適化、業務のデジタルトランスフォーメーション（DX化）に関心のある企業の皆様、ぜひお気軽にご相談ください。体系化された手法で現状を理解・分析し、最適な解決策をご提案します。</p>
          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                お問い合わせフォームへ
              </a>
            </Link>
          </p>
        </div>
      ),
    },
    date: '2023 May',
    label: 'DX',
  },
  {
    id: '27',
    title: 'We Have Joined as a Co-Creation Partner in a Project Aiming to Enhance User Experience and Boost Sales of the Order Management Service',
    thumbnail: 'tsuhan-sozai-start.png',
    cover: 'tsuhan-sozai-start-cover.png',
    link: '/news/27',
    slug: 'tsuhan-sozai-start',
    meta: {
      title:
        'We Have Joined as a Co-Creation Partner in a Project Aiming to Enhance User Experience and Boost Sales of the Order Management Service - News - wesionaryTEAM',
      keywords: 'User Experience Improvement, Co-Creation Partner, E-Commerce, Core Systems, Payment Automation',
      description: 'We have been selected as a co-creation partner in a project aimed at enhancing the user experience and boosting sales for a client\'s order management service. We have commenced development of a new dashboard. If your company is considering service improvements, please contact us.',
    },
    description: {
      en: (
        <div className="grid gap-4">
          <p>
            We are pleased to announce that we have been selected as a co-creation partner in a project aiming to enhance user experience and increase sales in our client's order management service, and we have commenced the project.
          </p>

          <br/>
          <p className={`font-semibold`}>Challenges Faced by the Client Company and Project Background</p>
          <p>The client company was facing the following challenges:</p>
          <ul>
            <li>Inability to Search or Download Individual Product Information: Users had to download all product information and then search for the necessary details, as they couldn't search for or download individual product data scheduled for sale.</li>
            <li>Limited Payment Options: The payment method was restricted to bank transfers, and they couldn't meet the demand for automation through credit card payments.</li>
            <li>Complex UI and Procedures: The UI and steps for ordering and invoicing were complicated, hindering the user experience for new members.</li>
          </ul>
          <p>To resolve these issues and enhance the overall user experience of the service, we have joined the project as a co-creation partner.</p>

          <br/>
          <p className={`font-semibold`}>Features of the System and Expected Benefits</p>
          <p>In this project, we plan to develop a new dashboard for members and implement the following features:</p>
          <ul>
            <li>Intuitive UI: A simple and user-friendly interface will enable users to search for products and download individual information with ease.</li>
            <li>Payment Automation: Supporting credit card payments will automate the payment process, enhancing user convenience.</li>
            <li>Simplification of Procedures: We will streamline the steps for ordering and invoicing, allowing even new members to operate smoothly.</li>
          </ul>

          <br />
          <p>These features are expected to improve user satisfaction and operational efficiency. Furthermore, we plan to work on automatic listing and ordering of products on platforms such as Rakuten, Yahoo, and Shopify in the future.</p>

          <br/>
          <p>If your company is facing similar challenges or is considering enhancing your service's user experience, please feel free to contact us. Through co-creative product development, we are fully committed to supporting your business growth.</p>

          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                Contact Us
              </a>
            </Link>
          </p>
        </div>
      ),
      ja: (
        <div className="grid gap-4">
          <p>
            当社はこの度、顧客企業様の受発注サービスにおけるユーザー体験と売上の向上を目指すプロジェクトにおいて、共創パートナーとして選定され、プロジェクトに着手いたしました。
          </p>

          <br/>
          <p className={`font-semibold`}>顧客企業の抱える課題とプロジェクトの背景</p>
          <p>顧客企業様では、以下の課題を抱えておられました。</p>
          <ul>
            <li>販売予定の商品情報の検索や個別ダウンロードができず、全情報をダウンロードしてから必要な情報を探し出す必要があった。</li>
            <li>決済方法が振込のみで、クレジットカード決済による自動化の要望に応えられていなかった。</li>
            <li>発注や請求書等のUIや手順が複雑で、新規会員の利用体験を阻害していた。</li>
          </ul>
          <p>これらの課題を解決し、サービス全体のユーザー体験を向上させるために、当社が共創パートナーとしてプロジェクトに参画することとなりました。</p>

          <br/>
          <p className={`font-semibold`}>システムの特長と期待される効果</p>
          <p>本プロジェクトでは、会員向けの新しいダッシュボードを開発し、以下の機能を実装する予定です。</p>
          <ul>
            <li>直感的なUI：シンプルで使いやすいインターフェースにより、商品検索や情報の個別ダウンロードが可能になります。</li>
            <li>決済自動化：クレジットカード決済に対応し、決済プロセスを自動化してユーザーの利便性を高めます。</li>
            <li>手順の簡素化：発注や請求書発行の手順を見直し、新規会員でもスムーズに操作できるようにします。</li>
          </ul>
          <p>これらの機能により、ユーザーの満足度向上と業務効率化が期待されます。さらに、今後は楽天、ヤフー、Shopify等への商品の自動掲載と自動発注にも取り組む予定です。</p>

          <br/>
          <p>同様の課題をお持ちの企業様や、サービスのユーザー体験向上をお考えの企業様は、ぜひ当社までお問い合わせください。共創型プロダクト開発を通じて、貴社のビジネス成長を全力でサポートいたします。</p>
          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                お問い合わせフォームへ
              </a>
            </Link>
          </p>
        </div>
      ),
    },
    date: '2023 Apr',
    label: 'Business Enhancement',
  },
    {
        id: '39',
        title: 'We Have Begun Managing the Infrastructure Operations of the Real Estate Industry EC Site "MORE STYLE"',
        thumbnail: 'good-place-operation.png',
        cover: 'good-place-operation-cover.png',
        link: '/news/39',
        slug: 'good-place-operation',
        meta: {
            title:
                'We Have Begun Managing the Infrastructure Operations of the Real Estate Industry EC Site "MORE STYLE" - News - wesionaryTEAM',
            keywords: 'EC site management, Infrastructure migration, Real estate EC, Membership-based EC',
            description: 'We have begun managing the infrastructure operations of GOOD PLACE Inc.\'s membership-based EC site "MORE STYLE," for the real estate industry.',
        },
        description: {
            en: (
                <div className="grid gap-4">
                    <p>
                        We are pleased to announce that we have begun managing the infrastructure operations of "MORE STYLE," an exclusive membership-based EC site for the real estate industry, on behalf of GOOD PLACE Inc. (hereafter referred to as GOOD PLACE).
                    </p>
                    <p>GOOD PLACE is a company dedicated to solving various challenges across diverse fields, extending beyond construction, with a brand concept of "Make a GOOD PLACE," offering solutions for workspaces, gathering spaces, and more.</p>
                    <p>Our company has taken over the management of the infrastructure for "MORE STYLE" as part of the transition to a new environment following the expiration of its previous infrastructure support.</p>

                    <br />
                    <p>If you are facing challenges with infrastructure setup or migration, or need improvements in system quality and performance, please feel free to contact us.</p>

                    <p>
                        <Link href="/contact" passHref>
                            <a
                                className={`text-sm hover:underline md:self-end`}
                            >
                                Contact Us
                            </a>
                        </Link>
                    </p>
                </div>
            ),
            ja: (
                <div className="grid gap-4">
                    <p>
                        この度、当社は、株式会社GOOD PLACE様（以下、GOOD PLACE様）より、不動産業界の会員向けECサイト「MORE STYLE」のインフラ保守運用を開始しました。
                    </p>
                    <p>GOOD PLACE様は、「Make a GOOD PLACE」をブランドコンセプトに、建築という枠を超えて、働く場、集う場など様々な領域に対し課題解決を行う企業です。</p>
                    <p>この度、当社は、インフラのサポート期限切れに伴う新環境への移行を目的とした、  「MORE STYLE」のインフラ保守運用を引き継ぐことになりました。</p>

                    <br />
                    <p>インフラの構築や移行、システムの品質改善、パフォーマンス改善にお困りの場合は、お気軽にお問い合わせください。</p>
                    <p>
                        <Link href="/contact" passHref>
                            <a
                                className={`text-sm hover:underline md:self-end`}
                            >
                                お問い合わせフォームへ
                            </a>
                        </Link>
                    </p>
                </div>
            ),
        },
        date: '2023 Apr',
        label: 'Operation',
    },
  {
    id: '17',
    title: 'We Have Started Developing a Cloud Service for Order Management in the Construction Industry',
    thumbnail: 'd-order-start.png',
    cover: 'd-order-start-cover.png',
    link: '/news/17',
    slug: 'd-order-start',
    meta: {
      title:
        'We Have Started Developing a Cloud Service for Order Management in the Construction Industry - News - wesionaryTEAM',
      keywords: 'Construction industry, Order management, Construction cloud service, Construction efficiency',
      description: 'We have begun developing a cloud service to streamline the complex order management process in the construction industry. This service aims to solve order management challenges faced by contractors, manufacturers, and trading companies, boosting overall productivity.',
    },
    description: {
      en: (
        <div className="grid gap-4">
          <p>
            We have begun developing a cloud service aimed at streamlining the complex order management process in
            the construction industry. In this field, where various stakeholders such as craftsmen, contractors,
            manufacturers, trading companies, and logistics providers are involved, there are frequent issues like
            miscommunication and work delays. Our new service seeks to address these challenges and improve
            productivity across the entire industry.
          </p>
          <p>Challenges in Construction Order Management:</p>
          <ul>
            <li>Order methods vary by person, using Line, phone, or fax, with no standard process.</li>
            <li>Errors in material part numbers or quantities often result in inaccurate information.</li>
            <li>Craftsmen are often uncertain if the contractor has correctly placed the order with the
              manufacturer.
            </li>
            <li>Mistakes in part numbers frequently occur because the necessary information is not readily
              available when ordering.
            </li>
            <li>Incorrect part numbers can cause work to halt on-site.</li>
            <li>There is often a time lag between ordering and the manufacturer receiving the request, leading to
              project delays.
            </li>
            <li>Missing required details on orders frequently result in follow-up calls from manufacturers.</li>
            <li>Orders to logistics providers are sometimes overlooked, causing delivery cancellations.</li>
            <li>Changes to delivery schedules require additional communication with manufacturers and logistics
              providers, adding extra work.
            </li>
            <li>It takes a long time to search through order histories, leading to inefficiencies.</li>
            <li>Discrepancies between the quoted prices at the time of order and the manufacturer’s invoice can
              cause disputes.
            </li>
            <li>Arranging delivery truck schedules via fax or phone is time-consuming for both parties.</li>
            <li>When ordering materials from multiple manufacturers for the same site, separate order forms must
              be created for each.
            </li>
            <li>Each contractor uses a different format for order forms, complicating the manufacturers' order
              processing.
            </li>
          </ul>
          <p>To solve these issues, we have partnered with construction companies and manufacturers to develop a
            specialized cloud service for the industry. This service will be available to all participants
            involved in the order management process, aiming to standardize and optimize operations.</p>
          <p>We plan to launch the beta version in August 2023. We look forward to providing a user-friendly service
            that will enhance productivity for everyone involved.</p>
          <p>If you are considering digital transformation (DX), operational efficiency improvements, or developing
            new business ventures in the construction industry, please feel free to contact us. Our experienced
            team, with years of industry expertise, is here to provide dedicated support.</p>
          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                Contact Us
              </a>
            </Link>
          </p>
        </div>
      ),
      ja: (
        <div className="grid gap-4">
          <p>
            当社は、建設業界の複雑な受発注プロセスを効率化するクラウドサービスの開発に着手しました。職人、元請、メーカー、商社、運送業者など、多くの関係者が関わる建設業界では、情報の行き違いや作業の遅延など、さまざまな問題が発生しています。この新しいサービスは、それらの課題を解決し、業界全体の生産性向上を目指します。
          </p>
          <p>建設業界の受発注で発生している課題：</p>
          <ul>
            <li>発注方法がLine、電話、FAXなど人によって異なり、統一されていない。</li>
            <li>材料の品番ミスや数量の誤解など、正確な情報が伝わらないことが多い。</li>
            <li>職人が発注しても、元請担当者がメーカーへ正しく注文しているか不安が残る。</li>
            <li>発注時に必要な情報が手元にないため、品番ミスが起こりやすい。</li>
            <li>発注した材料の品番間違いで現場作業が中断することがある。</li>
            <li>発注からメーカーへの伝達に時間差が生じ、作業に遅れが発生する。</li>
            <li>必要事項が欠けた発注が多く、メーカーからの確認連絡が頻繁に発生する。</li>
            <li>楊重業者への発注を忘れることで搬入がキャンセルされるケースがある。</li>
            <li>搬入予定の変更時にメーカーや楊重業者への連絡が必要で手間がかかる。</li>
            <li>過去の発注履歴を調べるのに時間がかかり、業務が非効率的。</li>
            <li>発注時の金額とメーカーの請求金額に差異があり、トラブルになる。</li>
            <li>搬入トラックの順番や時間の調整をFAXや電話で行うため時間が取られる。</li>
            <li>同じ現場で複数のメーカーに発注する際、それぞれに別々の発注書を作成しなければならない。</li>
            <li>元請からメーカーへの発注書が各社で異なるフォーマットで、受注業務が煩雑になる。</li>
          </ul>
          <p>この度は建設会社やメーカーと連携し、これらの課題を解決するために業界特化型のクラウドサービスの開発に着手しました。このサービスは、受発注プロセスに関わる多くの参加者が利用でき、業務の標準化と効率化を図ることを目指します。</p>
          <p>
            2023年8月にはβ版の提供を開始する予定です。関係者全員が使いやすく、生産性を高めるこのサービスにぜひご期待ください。
          </p>
          <p>建設業界のデジタルトランスフォーメーション（DX）や業務効率化、新規事業の開発をお考えの方は、どうぞお気軽にお問い合わせください。長年の業界経験を持つ専門チームが手厚くサポートいたします。</p>
          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                お問い合わせフォームへ
              </a>
            </Link>
          </p>
        </div>
      ),
    },
    date: '2023 Mar',
    label: 'DX',
  },
  {
    id: '37',
    title: 'Co-creation Project Launched for Coaching Platform to Improve Operational Efficiency and Engagement',
    thumbnail: 'coaching-cloud-start.png',
    cover: 'coaching-cloud-start-cover.png',
    link: '/news/37',
    slug: 'coaching-cloud-start',
    meta: {
      title:
        'Co-creation Project Launched for Coaching Platform to Improve Operational Efficiency and Engagement - News - wesionaryTEAM',
      keywords: 'Training system, Attendance management, Operational efficiency, Digital transformation, DX',
      description: 'We have initiated a co-creation project with a company that operates a coaching business to develop a new attendance and content management system for B2B training services. We aim to enhance operational efficiency and improve user convenience. If you are considering improving operational efficiency, visualizing and optimizing business processes, or undertaking digital transformation (DX), please feel free to contact us.',
    },
    description: {
      en: (
        <div className="grid gap-4">
          <p>
            We have recently initiated a co-creation project with an end-client company that operates a coaching business to develop a new attendance and content management system for training services. Having been selected as their co-creation partner, we have embarked on this project.
          </p>

          <br/>
          <p className={`font-semibold`}>Client Company's Challenges and Project Background</p>
          <p>While operating a one-to-many coaching firm, our client faced challenges in productivity and convenience, specifically in automating the measurement and aggregation of engagement and well-being in their training services, enhancing efficiency in attendance management, digitizing text materials, and streamlining post-training report submissions. To address these issues, our co-creation project has commenced.</p>

          <br/>
          <p className={`font-semibold`}>System Features and Expected Benefits</p>
          <p>Our system automates the measurement of engagement and well-being, improving the efficiency of data analysis. In attendance management, it includes features like automatic schedule reminders and notifications for makeup classes, helping to prevent unauthorized absences and unregistered participants. Additionally, by enabling online sharing of text and video content, the system provides an environment where participants can easily access training materials. These enhancements are expected to improve the client's operational efficiency and enhance learning outcomes for participants.</p>

          <br/>
          <p>If you are considering improving operational efficiency, visualizing and optimizing business processes, or undertaking digital transformation (DX), please do not hesitate to contact us. We will analyze your current situation using systematic approaches, set clear and precise objectives and goals, and work together with you to achieve them.</p>
          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                Contact Us
              </a>
            </Link>
          </p>
        </div>
      ),
      ja: (
        <div className="grid gap-4">
          <p>
            当社はこの度、コーチング事業を展開するエンドクライアント様と共に、研修サービス向けの新たな出欠管理・コンテンツ管理システムの開発プロジェクトを開始いたしました。当社が共創パートナーとして選定され、本プロジェクトに着手する運びとなりました。
          </p>

          <br/>
          <p className={`font-semibold`}>顧客企業の抱える課題とプロジェクトの背景</p>
          <p>
            顧客企業様は、1対N型のコーチングファーム事業を運営する中で、研修サービスにおけるエンゲージメントとウェルビーイングの測定・集計の自動化、出欠管理の効率化、テキストのデジタル化、そして受講後のレポート提出において、業務の生産性と利便性に課題を抱えていました。これらの課題解決を目指し、当社との共創プロジェクトが始動しました。
          </p>

          <br/>
          <p className={`font-semibold`}>システムの特長と期待される効果</p>
          <p>本システムは、エンゲージメントとウェルビーイングの測定を自動化し、データ分析の効率化を図ります。出欠管理では、スケジュールリマインドや振替案内の自動送信機能を実装し、無断欠席や飛び入り参加を防止します。さらに、テキストや動画コンテンツのオンライン共有により、受講者が研修コンテンツを手軽に利用できる環境を提供します。これらにより、顧客企業様の業務効率化と受講者の学習効果向上が期待されます。</p>

          <br/>
          <p>教育関連システムの開発、業務の効率化、業務プロセスの最適化、デジタルトランスフォーメーション（DX化）をご検討中のお客様、ぜひお問い合わせください。体系化された手法で、現状を分析し、正しく明確な目的と目標を設定した上で、その実現をご一緒に目指します。</p>
          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                お問い合わせフォームへ
              </a>
            </Link>
          </p>
        </div>
      ),
    },
    date: '2023 Feb',
    label: 'DX',
  },
  {
    id: '32',
    title: 'We have begun co-developing a health management platform that leverages employee wellness to enhance business performance',
    thumbnail: 'life-pilot-start.png',
    cover: 'life-pilot-start-cover.png',
    link: '/news/32',
    slug: 'life-pilot-start',
    meta: {
      title:
        'We have begun co-developing a health management platform that leverages employee wellness to enhance business performance - News - wesionaryTEAM',
      keywords: 'Corporate health management system, Personalized health support, Co-creation of new business',
      description: 'A new health management platform has been launched. It offers the best solution for companies aiming to improve both employee wellness and business efficiency.',
    },
    description: {
      en: (
        <div className="grid gap-4">
          <p>
            We are pleased to announce that we have been selected as a co-creation partner to develop a new
            platform aimed at contributing to corporate health management. This project offers a strategic
            approach to link employee health management with business growth. The platform is designed to
            help companies, especially small and medium-sized businesses, overcome resource limitations and
            implement health management practices effectively and efficiently.
          </p>

          <br/>
          <p className={`font-semibold`}>Challenges Faced by Companies and Project Background</p>
          <p>The companies involved in this project are focusing on "corporate health management," which
            approaches employee wellness from a business management perspective. However, they have faced
            challenges in systematically managing employee health and quantifying its impact. Additionally,
            due to operational complexities and resource shortages, health management initiatives tend to be
            deprioritized, which has slowed overall business performance improvement. In response to these
            challenges, there is a clear need for a sustainable and practical health management system,
            which led to the launch of this project.</p>

          <br/>
          <p className={`font-semibold`}>Features and Expected Benefits of the New Platform</p>
          <p>This new platform offers comprehensive features, including the delivery of health-related
            information to employees, stress checks, and real-time data visualization. Companies can monitor
            employee wellness through a management dashboard, enabling the efficient implementation of
            necessary health initiatives. By providing personalized health support tailored to each
            individual employee, the platform is expected to boost operational efficiency and enhance
            employee wellness.</p>

          <br/>
          <p>If your company is considering developing new health-related projects, strengthening existing ones, or undergoing digital transformation (DX), please do not hesitate to contact us. We will work with you to assess your current situation, define clear and appropriate objectives, and fully support you in achieving your goals.</p>
          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                Contact Us
              </a>
            </Link>
          </p>
        </div>
      ),
      ja: (
        <div className="grid gap-4">
          <p>
            当社は、共創パートナーとして選定され、健康経営に貢献する新しいプラットフォームの開発に着手しました。今回のプロジェクトでは、従業員の健康管理を企業の成長に繋げるための戦略的アプローチを提供します。このプラットフォームは、特に中小企業におけるリソースの限界を克服し、健康経営を効果的かつ効率的に実践できるよう支援します。
          </p>

          <br/>
          <p className={`font-semibold`}>企業が抱える課題とプロジェクトの背景</p>
          <p>
            今回のプロジェクトに参画した企業は、従業員の健康状態とその影響を経営視点で捉える「健康経営」に注力しています。しかし、従業員の健康管理をシステム的に行うことや、その効果を定量的に測定することが難しいという課題がありました。さらに、業務の煩雑さやリソース不足により、健康経営の施策が後回しになる傾向があり、企業全体のパフォーマンス向上が停滞している状況が見られました。こうした背景から、持続可能で実行可能な健康管理システムが求められており、今回のプロジェクトがスタートしました。
          </p>

          <br/>
          <p className={`font-semibold`}>新プラットフォームの特長と期待される効果</p>
          <p>新しいプラットフォームは、従業員への健康情報配信、ストレスチェック、そして各種データの可視化といった包括的な機能を提供します。企業側は、管理画面を通じて従業員の健康状態をリアルタイムで確認でき、必要な健康施策を効率的に実施することが可能です。特に、従業員一人ひとりに合わせたパーソナライズドな健康サポートが実現されるため、個別のニーズに的確に対応し、業務の効率化や従業員の健康増進が期待されます。</p>

          <br/>
          <p>健康関連の新規事業の開発や既存事業の強化、業務のデジタルトランスフォーメーション（DX化）などをお考えの企業様、ぜひお問い合わせください。現状を整理し、正しく明確な目的と目標を定めた上で、実現に向けて全力でサポートいたします。</p>
          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                お問い合わせフォームへ
              </a>
            </Link>
          </p>
        </div>
      ),
    },
    date: '2023 Feb',
    label: 'New business co-creation',
  },
  {
    id: '2',
    title:
      'Released a music event companion matching service “MELD”.',
    thumbnail: 'card2.png',
    cover: 'cover2.png',
    link: '/news/2',
    slug: 'meld-release',
    meta: {
      title:
        'MELD Music Event Companion Matching Service - News - wesionaryTEAM',
      keywords:
        'new business co-creation, new business development, system development',
      description:
        'We have released the “MELD” music event companion matching service.',
    },
    description: {
      ja: (
        <div className="grid gap-4">
          <p>
            「
            <a
              href="https://meld.co.jp/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              MELD（メルド）
            </a>
            」は音楽のインディペンデント系レーベルを主力事業とする株式会社MELDの初となる自社サービスになります。
          </p>
          <p>
            既存の事業を展開している中で「一人」であることを理由にライブやフェスに参加できないとの声が多くあります。
          </p>
          <p>
            より多くの人に音楽イベントを楽しんで頂けるよう、信頼でき、簡単に、趣味が近い人とつながるサービスを開発しました。
          </p>
          <p>
            2023年2月にMVP（必要最小限の機能）をリリースし、アイディアの検証をスタートしました。
          </p>
          <p>
            利用者からのフィードバックと利用状況をモニタリングしながら、機能とユーザー体験を高めて参ります。
          </p>
        </div>
      ),
      en: (
        <div className="grid gap-4">
          <p>
            We will continue to enhance our service's functionality
            and user experience, while monitoring feedback from users
            and usage statistics.
          </p>
          <p>
            This new service is the first in-house service of{' '}
            <a
              href="https://meld.co.jp/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              MELD Co., Ltd.
            </a>
            , which is mainly engaged in independent record labels.
          </p>
          <p>
            While expanding their existing business, many people have
            expressed their inability to participate in live
            performances and festivals due to being "alone.”
          </p>
          <p>
            We developed a service that allows people to easily and
            reliably connect with others who share similar hobbies, in
            order to make music events more enjoyable for a larger
            audience.
          </p>
          <p>
            In February 2023, we released our MVP (Minimum Viable
            Product) to start validating our idea.
          </p>
          <p>
            We will continue to enhance our service's functionality
            and user experience, while monitoring feedback from users
            and usage statistics.
          </p>
        </div>
      ),
    },
    date: '2023 Feb',
    label: 'New business co-creation',
  },
  {
    id: '3',
    title: 'Release a corporate data search service ”BizRiz”.',
    thumbnail: 'card3.png',
    cover: 'cover3.png',
    link: '/news/3',
    slug: 'bizriz-release',
    meta: {
      title:
        'BizRiz Corporate Data Search Service - News - wesionaryTEAM',
      keywords: 'DX, business efficiency, system development',
      description:
        'We have released the corporate data search service “BizRiz”.',
    },

    description: {
      ja: (
        <div className="grid gap-4">
          <p>
            「BizRiz（ビズリズ）」ダイレクトマーケティングを主力事業とする{' '}
            <a
              href="https://www.nd-promo.co.jp/e-2.html"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              日本ダイレクトプロモーションと
            </a>
            共に、大規模な企業データベースを利用できるサービスになります。
          </p>
          <p>
            これまではメールや電話でお客様からご依頼を受け、該当するデータを抽出し提供しておりました。
          </p>
          <p>
            多くの手間と時間を要する上、お客様が事前にデータを確認できないなどの問題があり、事業の拡大と顧客体験において課題となっていました。
          </p>
          <p>
            BizRiz
            によって企業自身がデータを検索し、確認し、ダウンロードできるようになりました。
          </p>
        </div>
      ),
      en: (
        <div className="grid gap-4">
          <p>
            "BizRiz（ビズリズ)" is a service that can utilize a large
            corporate database in conjunction with{' '}
            <a
              href="https://www.nd-promo.co.jp/e-2.html"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              Japan Direct Promotion
            </a>
            , which specializes in direct marketing as its main
            business.
          </p>
          <p>
            Until now, request were received from the customer through
            emails or phone cal, and corresponding data were extracted
            and provided to the respective customers.
          </p>
          <p>
            There were problems such as requiring a lot of time and
            effort and customers being unable to confirm the data in
            advance, which became challenges in expanding the business
            and improving customer experience.
          </p>
          <p>
            With BizRiz, companies can now search for, confirm, and
            download data themselves.
          </p>
        </div>
      ),
    },
    date: '2023 Jan',
    label: 'DX',
  },
  {
    id: '4',
    title:
      'Released a a Q&A service created together with fans “Question Market (Quema)”.',
    thumbnail: 'card4.png',
    cover: 'cover4.png',
    link: '/news/4',
    date: '2022 Dec',
    slug: 'question-market-release',
    meta: {
      title:
        'Question Market - Q&A Service Created With Fans - News - wesionaryTEAM',
      keywords:
        'New Business Co-creation, New Business Development, System Development',
      description:
        'We have released “Question Market”, a Q&A service created with fans.',
    },

    description: {
      ja: (
        <div className="grid gap-4">
          <p>
            <a
              href="https://pyxis.company/company/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              株式会社ピクシス{' '}
            </a>
            <span>と新しいモデルのQ＆Aサービス「</span>
            <a
              href="https://question.market/"
              target={'_blank'}
              rel="noopener noreferrer"
              className="inline-block  text-primary-500 hover:underline"
            >
              Question Market
            </a>
            」 をリリースしました。
          </p>
          <p>
            インフルエンサーはさまざまなファンからの質問へ答えることで収益を得ることができます。過去にインフルエンサーが答えた質問を他のファンが閲覧（有料）した際に、インフルエンサーと質問したファンは収益が得られます。
          </p>
          <p>
            ビジネスモデル特許を取得している、これまでにない新たな試みになります。事業アイディアを検証するため、必要最小限の機能（MVP）をリリースしました。
          </p>
          <p>
            インフルエンサー、利用者からフィードバック、利用データをもとにプロダクトを改良し、マーケットフィットを目指して参りします。
          </p>
        </div>
      ),
      en: (
        <div className="grid gap-4">
          <p>
            Together with{' '}
            <a
              href="https://pyxis.company/company/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              Pyxis Co., Ltd.
            </a>
            , we have released a new model Q&A service called "
            <a
              href="https://question.market/"
              target={'_blank'}
              rel="noopener noreferrer"
              className="inline-block  text-primary-500 hover:underline"
            >
              Question Market
            </a>
            ".
          </p>
          <p>
            Influencers can earn revenue by answering questions from
            various fans. When third party fans (for a certain charge)
            view questions that influencers have answered in the past,
            both the influencer and the fan who asked the respective
            question can earn revenue.
          </p>
          <p>
            This is a new attempt that has obtained a business model
            patent. We have released the minimum viable product (MVP)
            to verify the business idea.
          </p>
          <p>
            We will improve the product based on feedback and usage
            data from influencers and users, and aim for market fit.
          </p>
        </div>
      ),
    },
    label: 'New business co-creation',
  },
  {
    id: '5',
    title: 'Notice Regarding Office Opening',
    thumbnail: 'card5.png',
    cover: 'cover5.png',
    link: '/news/5',
    date: '2022 Oct',
    slug: 'new-office-2022',
    meta: {
      title:
        'Notice regarding office opening - News - wesionaryTEAM',
      keywords: 'Notice',
      description: 'Notice Regarding Office Opening',
    },

    description: {
      ja: (
        <div className="grid gap-4">
          <p>
            弊社では新型コロナウイルス感染拡大の予兆が見られた2020年5月以降、フルリモート体制へと移行しておりましたが、直近での社会情勢の変化を踏まえハイブリッド体制へと一部移行するに加え、今後の組織の拡大を見据えて最大80人が勤務できるオフィスを開設致しました。
          </p>
          <p>
            メンバー一同、オフィス勤務とリモートワークの両方をバランス良く活用しながら、共創による事業開発に一層精進していく所存でございます。
          </p>
          <p>
            今後とも変わらぬご支援を賜りますようお願い申し上げます。
          </p>
        </div>
      ),
      en: (
        <div className="grid gap-4">
          <p>
            Since May 2020, when we saw signs of the spread of the new
            coronavirus, our company has transitioned to a fully
            remote system. However, in light of recent changes in the
            social situation, we have partially transitioned to a
            hybrid system and have opened an office that can
            accommodate up to 80 people, with the aim of expanding our
            organization in the future.
          </p>
          <p>
            Our team intends to balance office work and remote work,
            making the best use of both, and further devote ourselves
            to business development through collaboration.
          </p>
          <p>
            We hope to continue receiving your unwavering support in
            the future.
          </p>
        </div>
      ),
    },
    label: 'Notice',
  },
  {
    id: '6',
    title:
      'Released an unique domestic NFT service “META PYLON”',
    thumbnail: 'card6.png',
    cover: 'cover6.png',
    link: '/news/6',
    slug: 'meta_pylon',
    meta: {
      title:
        'Unique Domestic NFT Service “META PYLON” - News - wesionaryTEAM',
      keywords: 'New Business Co-creation, New Business Development, System Development',
      description:
        'We have released a unique domestic NFT service “META PYLON”',
    },
    description: {
      ja: (
        <div className="grid gap-4">
          <p>
            クリエイティブ事業を主力事業とする{' '}
            <a
              href="https://wooltrakey.com/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              WoOLTRAKEY株式会社
            </a>
            と全く新しいNFTアートプロジェクト「{' '}
            <a
              href="https://metapylon.io/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              META PYLON
            </a>
            」をスタートしました。
          </p>
          <p>
            META PYLON
            のホルダーはウォレットに保有するあらゆるNFTコレクションを三角コーンに自由自在に貼り付け、カスタマイズしたNFTを入手できます。3D
            と NFT
            により新しいアートの体験を創り出すことを目指しています。
          </p>
          <p>
            3DのパイロンにNFTを貼り付ける仕組みの特許を取得しました。
          </p>

          <video
            height={'100%'}
            width={'1144px'}
            role="video"
            className="3xl:w-full"
            src={`${process.env.NEXT_PUBLIC_STATIC_URL}/news/meta.mp4`}
            controls={true}
          />
        </div>
      ),
      en: (
        <div className="grid gap-4">
          <p>
            Together with{' '}
            <a
              href="https://wooltrakey.com/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              WoOLTRAKEY Inc.
            </a>
            , a company that specializes in creative businesses, we
            have launched a brand new NFT art project called "
            <a
              href="https://metapylon.io/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              META PYLON
            </a>
            ".
          </p>
          <p>
            META PYLON holders can freely attach any NFT collection
            they own in their wallet to the triangular cones and
            obtain customized NFTs. We aim to create a new art
            experience through 3D and NFT.
          </p>
          <p>
            We have obtained a patent for the mechanism of attaching
            NFTs to 3D pylons.
          </p>

          <video
            height={'100%'}
            width={'1144px'}
            role="video"
            className="3xl:w-full"
            src={`${process.env.NEXT_PUBLIC_STATIC_URL}/news/meta.mp4`}
            controls={true}
          />
        </div>
      ),
    },
    date: '2022 Sep',
    label: 'New business co-creation',
  },
  {
    id: '7',
    title:
      'Released “SOUDAN”, a marketplace for skills & knowledge.',
    thumbnail: 'card7.png',
    cover: 'cover7.png',
    link: '/news/7',
    date: '2022 Jul',
    slug: 'soudan-release',
    meta: {
      title:
        'Soudan - Marketplace for Skills and Knowledge - News - wesionaryTEAM',
      keywords: 'co-creation of new businesses, new business development, system development',
      description:
        'We have released “Soudan”, a marketplace for skills and knowledge.',
    },
    description: {
      ja: (
        <div className="grid gap-4">
          <p>
            「新しい体験をあなたの人生に。」をミッションとする{' '}
            <a
              href="https://www.ito-technologies.com/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              ITO Technologies株式会社
            </a>
            とスキルや知識を売買できる「Soudan」サービスのβ版をリリースしました。
          </p>
          <p>
            リモート通話で英会話、占い、恋愛相談、雑談、悩み相談などについて1on1で相談できます。
          </p>
          <p>
            また、自分のスキルや経験で多くの人の役に立てながら、収益を得る仕組みを提供しています。
          </p>
          <p>
            現在、ユーザーに使って頂きながら、正式版のリリースに向けて、機能や体験を改良しております。
          </p>
          <p className="mt-10">
            iOS アプリ <br />
            <a
              href="https://apps.apple.com/jp/app/soudan-%E5%8D%A0%E3%81%84-%E6%81%8B%E6%84%9B%E7%9B%B8%E8%AB%87-%E9%9B%91%E8%AB%87-%E6%82%A9%E3%81%BF%E7%9B%B8%E8%AB%87%E3%81%A7%E3%81%8D%E3%82%8B%E3%82%A2%E3%83%97%E3%83%AA/id1580214612"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              ストアで見る
            </a>
          </p>
          <p className="mt-10">
            Android アプリ<br />
            <a
              href="https://play.google.com/store/apps/details?id=com.ito_technologies.soudan&hl=ja&gl=US"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              ストアで見る
            </a>
          </p>
        </div>
      ),
      en: (
        <div className="grid gap-4">
          <p>
            Together with{' '}
            <a
              href="https://www.ito-technologies.com/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              ITO Technologies Co., Ltd.
            </a>{' '}
            whose mission is to bring new experiences to user’s life,
            we have released the beta version of the "Soudan" service
            where skills and knowledge can be bought and sold.
          </p>
          <p>
            With remote calling, you can have 1-on-1 consultations
            about English conversation session, fortune-telling, love
            advice, small talk, and problem-solving.
          </p>
          <p>
            Similarly, we are also offering a environment where user
            can earn revenue while using one's own skills and
            experience to benefit many people.
          </p>
          <p>
            Currently, we are improving our features and user
            experience towards the official release while working on
            the feedbacks from the current user of our application.
          </p>
          <p className="mt-10">
            iOS App <br />
            <a
              href="https://apps.apple.com/jp/app/soudan-%E5%8D%A0%E3%81%84-%E6%81%8B%E6%84%9B%E7%9B%B8%E8%AB%87-%E9%9B%91%E8%AB%87-%E6%82%A9%E3%81%BF%E7%9B%B8%E8%AB%87%E3%81%A7%E3%81%8D%E3%82%8B%E3%82%A2%E3%83%97%E3%83%AA/id1580214612"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              View in store
            </a>
          </p>
          <p className="mt-10">
            Android App <br />
            <a
              href="https://play.google.com/store/apps/details?id=com.ito_technologies.soudan&hl=ja&gl=US"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              View in store
            </a>
          </p>
        </div>
      ),
    },
    label: 'New business co-creation',
  },
  {
    id: '8',
    title:
      'Renewal of Kansai Regional Alliance, Kansai Startup Ecosystem’s website',
    thumbnail: 'card8.png',
    cover: 'cover8.png',
    link: '/news/8',
    date: '2022 May',
    slug: 'kansai-renewal',
    meta: {
      title:
        'Kansai Wide Area Union Kansai Startup Ecosystem Website - News - wesionaryTEAM',
      keywords: 'DX, Business Efficiency, System Development',
      description:
        'The Kansai Wide Area Union Kansai Startup Ecosystem website has been renewed.',
    },
    description: {ja:(
        <div className="grid gap-4">
          <p>
            <a
              href="https://www.kouiki-kansai.jp/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              関西広域連合
            </a>
            の重大構想である「日本有数の研究開発型スタートアップの地
            ディープテックバレー“KANSAI”」のブランド確立と情報発信力を高めるため、{' '}
            <a
              href="https://www.forstartups.com/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              フォースタートアップス株式会社
            </a>
            と共同で「{' '}
            <a
              href="https://kansai-startup-ecosystem.com/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              関西スタートアップ・エコシステム
            </a>
            」を刷新しました。
          </p>
          <p>
            これまで、情報のアップデートに時間がかかっていたり、その過程で多くのアナログ業務が発生していました。
          </p>
          <p>
            今回のシステム刷新により、関西広域連合の各自体の担当者が円滑にスタートアップ関連のイベントやお知らせ、記事を配信できるようになりました。
          </p>
          <p>
            アクセスと利用状況を踏まえてサイトを改良しながら、SEO対策の強化によって、より多くのターゲットユーザーがコンテンツにふれる機会を増やして参ります。
          </p>
        </div>
      ),en:(
        <div className="grid gap-4">
          <p>
            As part of the{" "}
            <a
              href="https://www.kouiki-kansai.jp/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              Kansai Regional Alliance
            </a>
            's major plan, "KANSAI - the Deep Tech Valley for Japan's leading research and development startups," efforts have been made to establish the brand and enhance the initiative's information dissemination. In collaboration with{" "}
            <a
              href="https://www.forstartups.com/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              For Startups Inc.
            </a>
            ., the "
            <a
              href="https://kansai-startup-ecosystem.com/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              Kansai Startup Ecosystem
            </a>
            " has been revamped.
          </p>
          <p>
            Until now, updating information had taken time and had resulted in many analog tasks during the process.
          </p>
          <p>
            With this system renewal, representatives of each organization within the Kansai Wide Area Union can now smoothly distribute events, announcements, and articles related to start-ups.
          </p>
          <p>
            We will improve the website based on access and usage and enhance SEO to increase the opportunities for more target users to engage with the content.
          </p>
        </div>
      )},
    label: 'DX',
  },
  {
    id: '9',
    title:
      'Released influencer matching service “Beee”.',
    thumbnail: 'card9.png',
    cover: 'cover9.png',
    link: '/news/9',
    date: '2022 Feb',
    slug: 'beee-release',
    meta: {
      title:
        'Beee Influencer Matching Service - News - wesionaryTEAM',
      keywords: 'new business co-creation, new business development, system development',
      description:
        'We have released the influencer matching service “Beee”.',
    },
    description: {ja:(
        <div className="grid gap-4">
          <p>
            ファッション業界のサステナビリティに取り組んでいる{' '}
            <a
              href="https://kol-tech.co.jp/company/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              株式会社KOLテクノロジーズ
            </a>
            とインフルエンサー・マッチング・サービス「{' '}
            <a
              href="https://www.c.beee.kol-tech.co.jp/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              Beee（ビー）
            </a>
            」を開発しました。
          </p>
          <p>
            発信力を持つインフルエンサーと、商品やイベントの体験談を発信してほしい企業さまを、A.I（人工知能）の技術でマッチングするサービスです。
          </p>
          <p>
            ひとまず必要最小限の機能（MVP）からスタートし、企業とインフルエンサーを増やしながらマーケットフィットに向けてプロダクトを改良する予定です。
          </p>
        </div>
      ),en:(
        <div className="grid gap-4">
          <p>
            In collaboration with{' '}
            <a
              href="https://kol-tech.co.jp/company/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              KOL Technologies.inc
            </a>
            , a company that focuses on sustainability in the fashion industry, we have developed the influencer matching service "
            <a
              href="https://www.c.beee.kol-tech.co.jp/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              Beee
            </a>
            ”.
          </p>
          <p>
            Our service uses artificial intelligence (AI) technology to match companies who want influencers with strong communication skills to share their product or event experiences.
          </p>
          <p>
            Our plan is to start with the minimum viable product (MVP) and gradually improve it towards market fit as we increase the number of companies and influencers.
          </p>
        </div>
      )},
    label: 'New business co-creation',
  },
  {
    id: '10',
    title:
      'Released “Health Journey”, a PHR app that specializes in infant health checkups and school health checkups.',
    thumbnail: 'card10.png',
    cover: 'cover10.png',
    link: '/news/10',
    date: '2022 Feb',
    slug: 'health-journey-release',
    meta: {
      title:
        'Health Journey - PHR app specialized in infant and school health checkups - News - wesionaryTEAM',
      keywords: 'DX, business efficiency, system development',
      description:
        'We have released “Health Journey,” a PHR app specialized in infant and school health checkups.',
    },
    description: {ja:(
        <div className="grid gap-4">
          <p>
            病院の診療情報や、健康診断の情報を電子化の電子化に長く取り組み多数の実績を持つ、{' '}
            <a
              href="https://rwdata.co.jp/about/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              リアルワールドデータ株式会社
            </a>
            のPHRアプリをリニュアルしました。
          </p>
          <p>
            今回のリニューアルでは、健診結果の閲覧や共有の体験を向上することに注力しました。
          </p>
          <p>
            次に健診結果に対する解説の追加や、健診に関する自治体からのタイムリーなお知らせの実現を目指して参ります。
          </p>
          <div className="my-10 flex gap-8">
            <img
              width={'25%'}
              src={`${process.env.NEXT_PUBLIC_STATIC_URL}/news/HJ_ios.png`}
              className="  border border-solid border-gray-500"
              alt="news"
            />

            <div>
              <div>iOS アプリ</div>
              <a
                href="https://apps.apple.com/app/id1599442495"
                target={'_blank'}
                rel="noopener noreferrer"
                className=" text-primary-500 hover:underline"
              >
                ストアで見る
              </a>
            </div>
          </div>
          <div className="my-10 flex gap-8">
            <img
              width={'25%'}
              src={`${process.env.NEXT_PUBLIC_STATIC_URL}/news/HJ_android.png`}
              className="  border border-solid border-gray-500"
              alt="news"
            />
            <div>
              <div>Android アプリ</div>
              <a
                href="https://play.google.com/store/apps/details?id=jp.co.shrcenter.HealthJourney"
                target={'_blank'}
                rel="noopener noreferrer"
                className=" text-primary-500 hover:underline"
              >
                ストアで見る
              </a>
            </div>
          </div>
        </div>
      ),
      en:(
        <div className="grid gap-4">
          <p>
            Together with {' '}
            <a
              href="https://rwdata.co.jp/about/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              Real World Data Inc.
            </a>
            , a company with extensive experience in digitizing hospital medical information and health examination data, we have renewed it’s existing PHR app.
          </p>
          <p>
            For this renewal, we focused on enhancing the experience of viewing and sharing health check results.
          </p>
          <p>
            Our next goal is to provide explanations for health examination results & achieve timely notifications regarding health checkups from local governments.
          </p>
          <div className="my-10 flex gap-8">
            <img
              width={'25%'}
              src={`${process.env.NEXT_PUBLIC_STATIC_URL}/news/HJ_ios.png`}
              className="  border border-solid border-gray-500"
              alt="news"
            />

            <div>
              <div>iOS App</div>
              <a
                href="https://apps.apple.com/app/id1599442495"
                target={'_blank'}
                rel="noopener noreferrer"
                className=" text-primary-500 hover:underline"
              >
                View in store
              </a>
            </div>
          </div>
          <div className="my-10 flex gap-8">
            <img
              width={'25%'}
              src={`${process.env.NEXT_PUBLIC_STATIC_URL}/news/HJ_android.png`}
              className="  border border-solid border-gray-500"
              alt="news"
            />
            <div>
              <div>Android App</div>
              <a
                href="https://play.google.com/store/apps/details?id=jp.co.shrcenter.HealthJourney"
                target={'_blank'}
                rel="noopener noreferrer"
                className=" text-primary-500 hover:underline"
              >
                View in store
              </a>
            </div>
          </div>
        </div>
      )
    },
    label: 'DX',
  },
  {
    id: '11',
    title:
      'Released “Co-Workation”, a platform for collaborative experiences.',
    thumbnail: 'card11.png',
    cover: 'cover11.png',
    link: '/news/11',
    date: '2021 Sep',
    slug: 'co-workation-release',
    meta: {
      title: 'co-workation Experience Co-Creation Platform - News - wesionaryTEAM',
      keywords: 'new business co-creation, new business development, system development',
      description:
        'We have released the experience co-creation platform “co-workation”.',
    },
    description: {ja:(
        <div className="grid gap-4">
          <p>
            組織と働く人に寄り添い働く環境のUpdateをミッションとする{' '}
            <a
              href="https://perkup.life/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              PerkUP株式会社
            </a>
            と法人向け共創型ワーケーションプラットフォーム「{' '}
            <a
              href="https://co-workation.com/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              コワーケーション / co-workation
            </a>
            」をスタートしました。
          </p>
          <p>
            共に(CO)働く(WORK)オフィスだけではない滞在体験(WORKATION)を創り、”働く環境改革”の促進を目指しています。
          </p>
          <p>
            構想は非常に大きく、企画者、施設提供者、コンテンツ提供者との連携が必要となる新しい試みになります。
          </p>
          <p>
            ひとまず最小限の機能をリリースし、マーケットにニーズを掴みながら、知見を蓄積し、プロダクトを改良して参ります。
          </p>
          <img
            src={`${process.env.NEXT_PUBLIC_STATIC_URL}/news/cw.png`}
            className="m-auto
                w-full border border-solid border-gray-500 object-cover object-center"
            alt="news"
          />
        </div>
      ),en:(
        <div className="grid gap-4">
          <p>
            We released{' '}
            <a
              href="https://co-workation.com/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              コワーケーション / co-workation
            </a>

            , a co-creation work platform for corporate clients, in collaboration with {' '}
            <a
              href="https://perkup.life/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              PerkUP Inc.
            </a>

            , whose mission is to update the working environment by being close to the organization and its workers.
          </p>
          <p>
            Our goal is to create a unique stay experience called "workation" that goes beyond the traditional office setting, and to promote the "work environment reform".
          </p>
          <p>
            This is a huge project that requires collaboration between planners, facility providers, and content providers.
          </p>
          <p>
            Currently, we have released minimum necessary features, accumulate the needs of the market, knowledge, and improve the product.
          </p>
          <img
            src={`${process.env.NEXT_PUBLIC_STATIC_URL}/news/cw.png`}
            className="m-auto
                w-full border border-solid border-gray-500 object-cover object-center"
            alt="news"
          />
        </div>
      )},
    label: 'New business co-creation',
  },
  {
    id: '12',
    title:
      'Development of a medical diagnosis digitalization(DX) platform “Porous” & started trial use at medical institutions.',
    thumbnail: 'card12.png',
    cover: 'cover12.png',
    link: '/news/12',
    date: '2021 Jul',
    slug: 'porous-release',
    meta: {
      title:
        'Porous Medical Diagnosis DX Platform - News - wesionaryTEAM',
      keywords: 'DX, Business Efficiency, System Development',
      description:
        'We have developed the medical diagnosis DX platform “Porous” and started trials in medical institutions.',
    },
    description: {ja:(
        <div className="grid gap-4">
          <p>
            医療関連のソフトウェア開発を主力事業とするBonBon株式会社と医療診断のDX化とAIによる簡略化ができる「Porous」を開発しました。
          </p>
          <p>
            症例画像の共有やレビュー、コメントのやり取り、レポートの作成や承認など、多岐にわたる業務をカバーしております。
          </p>
          <p>
            複数の医療機関で試用して頂いております。フィードバックや利用状況を踏まえて、プロダクトを改良して参りします。
          </p>
        </div>
      ),en:(
        <div className="grid gap-4">
          <p>
            Together with BonBon Co., Ltd., whose main business is medical-related software development, we have developed "Porous", a medical diagnosis digitalization(DX) platform which enables the simplification of medical diagnosis through digitalization & AI.
          </p>
          <p>
            Our platform covers a wide range of tasks, including sharing and reviewing medical images, exchanging comments, creating and approving reports, and more.
          </p>
          <p>
            Our product is being tested in multiple medical institutions. Based on feedback and usage from users, we are working to improve the product & user experience .
          </p>
        </div>
      )},
    label: 'DX',
  },
  {
    id: '13',
    title:
      'Released “TeamPlace” a workplace platform that connects people.',
    thumbnail: 'card13.png',
    cover: 'cover13.png',
    link: '/news/13',
    date: '2021 May',
    slug: 'teamplace-release',
    meta: {
      title:
        'TeamPlace “Workplace platform that connects people” - News - wesionaryTEAM',
      keywords: 'New Business Co-creation, New Business Development, System Development',
      description:
        'We have released “TeamPlace”, a workplace platform that connects people.',
    },
    description: {ja:(
        <div className="grid gap-4">
          <p>
            世界中の誰もが、どこでも豊かに働き生きられる社会」の実現をビジョンとする{' '}
            <a
              href="https://anywhere.co.jp/company"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              株式会社AnyWhere
            </a>
            と国内初の「人でつなげる」ことを特徴するワークプレイスプラットフォーム「{' '}
            <a
              href="https://team-place.com/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              TeamPlace
            </a>
            」の提供を開始しました。
          </p>
          <p>
            ワークプレイスの空間や関わる人を可視化し、人のつながりや新しいコラボレーションを生むきっかけを創ることを目指しています。
          </p>
          <p>TeamPlace は新しく大きな構想となります。</p>
          <p>
            コアな機能を先行してリリースしました。スペース運営者、スペース利用者からフィードバックを頂きながらサービスの提供価値を高めて参りします。
          </p>
        </div>
      ),en:(
        <div className="grid gap-4">
          <p>
            In collaboration with{' '}
            <a
              href="https://anywhere.co.jp/company"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              Anywhere Inc.
            </a>
            , which aims to achieve a vision of "a society where everyone in the world can work and live richly anywhere," in Japan we have launched its first workplace platform, "
            <a
              href="https://team-place.com/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              TeamPlace
            </a>
            ". The platform connects people and is designed to enable remote and flexible working arrangements.
          </p>
          <p>
            Our aim is to visualize the workplace environment and people involved, and create opportunities for new collaborations and connections.
          </p>
          <p>TeamPlace is a new and ambitious project.</p>
          <p>
            We have released the core features first and will continue to enhance the value of the service based on feedback from space operators and users.
          </p>
        </div>
      )},
    label: 'New business co-creation',
  },
  {
    id: '37',
    title: 'side bizz, One of the Largest Sales Outsourcing Matching Services, Successfully Renewed',
    thumbnail: 'side-bizz-released.png',
    cover: 'side-bizz-released-cover.png',
    link: '/news/37',
    slug: 'side-bizz-released',
    meta: {
      title:
        'side bizz, One of the Largest Sales Outsourcing Matching Services, Successfully Renewed - News - wesionaryTEAM',
      keywords: 'System replacement, System renewal, UI/UX improvements, System security enhancements, System performance improvements, Scouting system, Recommendation system',
      description: 'We have completed the system replacement for Webex Inc.\'s side bizz service, enhancing performance, security, and UI/UX. If your company is considering new business development, system improvements, or the development of matching, recommendation, or scouting systems, please feel free to contact us.',
    },
    description: {
      en: (
        <div className="grid gap-4">
          <p>
            Our company has successfully completed a major system overhaul for the "Side Bizz" platform provided by Webex Inc. We addressed the accumulated technical debt, introduced new features, and improved performance. As a result, the user experience on Side Bizz has significantly improved, leading to an increase in both the number of users and the frequency of service usage.
          </p>

          <br />
          <p className={`font-semibold`}>About side bizz</p>
          <p>
            side bizz is a platform designed to address sales-related challenges and provide new value to both businesses and sales professionals or freelancers.
          </p>
          <p>On the business side, companies often face challenges such as a shortage of sales talent, difficulty in nurturing staff, struggles with new business development or market expansion, and increasing sales costs. On the sales professional or freelancer side, there is a lack of side job opportunities where they can leverage their sales experience, unstable income, and a desire to use their skills to earn efficiently.</p>
          <p>side bizz bridges these gaps by offering a matching service between companies and sales professionals or freelancers. Companies can flexibly strengthen their sales force by leveraging skilled freelance sales professionals as needed, achieving cost-effective sales activities through a pay-for-performance model. This also allows for quicker development of new business and market expansion.</p>
          <p>For sales professionals and freelancers, side bizz provides access to a wide range of side job opportunities where they can use their sales experience. With the flexibility to choose when and where to work, they can earn a stable income, and in some cases, achieve higher earnings based on their performance, further enhancing motivation.</p>
          <p>By providing value to both companies and sales professionals, side bizz creates a win-win relationship and contributes to revitalizing Japan's business landscape. It serves as a platform that generates new business opportunities by empowering companies with enhanced sales capabilities and offering individuals a place to excel using their skills.</p>

          <br />
          <p className={`font-semibold`}>Challenges Before the Renewal</p>
          <p>
            As the number of features and data increased, the system's performance significantly declined, severely impacting business operations. Furthermore, without documentation or design materials, technical debt accumulated, making it difficult to maintain the system and add new features. There were also multiple security concerns that needed to be addressed.
          </p>

          <br />
          <p className={`font-semibold`}>Results of the Renewal</p>
          <p>
            As a co-creation partner, wesionaryTEAM led the system replacement, improving the UI/UX, security, and features. This resulted in a significant performance boost, enabling the launch of previously challenging features like the scouting and recommendation systems. We also strengthened security, providing users with a safe and reliable service environment.
          </p>
          <p>As a result, the number of users and the frequency of service usage increased. side bizz has evolved into a more valuable platform, offering enhanced business matching features for its users.</p>

          <br/>
          <p>If your company is considering new business development, system improvements, or the development of matching systems, recommendation systems, or scouting systems, please feel free to contact us. Our dedicated team will start with thorough research and analysis, gaining a deep understanding of your current situation. Together, we will set clear and precise goals and work towards achieving them.</p>

          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                Contact Us
              </a>
            </Link>
          </p>
        </div>
      ),
      ja: (
        <div className="grid gap-4">
          <p>
            当社は、ウェブエックス株式会社が提供する「side bizz」プラットフォームにおけるシステムの大規模リニューアルを無事に完了しました。これまでの技術的負債を解消しつつ、新機能の追加やパフォーマンス改善に成功しました。これにより、side bizz のユーザー体験は大幅に向上、サービスの利用者数と利用頻度が増加しました。
          </p>

          <br />
          <p className={`font-semibold`}>side bizz について</p>
          <p>
            side bizzは、企業と営業職・フリーランス双方にとっての営業に関する課題を解決し、新たな価値を提供するプラットフォームです。
          </p>
          <p>企業側においては、営業人材の不足や育成の難しさ、新規事業・販路開拓の困難さ、営業コストの増大といった課題が存在します。一方、営業職・フリーランス側では、営業経験を活かせる副業機会の不足、収入の不安定さ、スキルを活かして効率的に稼ぎたいというニーズがあります。</p>
          <p>side bizzは、これらの課題を解決するために、企業と営業職・フリーランスをマッチングするサービスを提供します。企業は、優秀な営業フリーランスを活用することで、必要な時に柔軟に営業力を強化し、成果報酬型で費用対効果の高い営業活動を実現できます。さらに、新規事業や販路開拓をスピーディーに進めることも可能です。</p>
          <p>一方、営業職・フリーランスにとっては、営業経験を活かせる多様な副業案件にアクセスできる場となります。自由な働き方で、時間と場所を選ばずに収入を獲得でき、成果次第で高収入も可能となるため、モチベーション向上にもつながります。</p>
          <p>side bizzは、企業と営業職・フリーランス双方に価値を提供することで、win-winの関係を築き、日本のビジネスシーンを活性化していきます。企業は営業力を強化し、個人はスキルを活かして活躍できる場を提供することで、新たなビジネスチャンスを生み出すプラットフォームとしての役割を果たしています。</p>

          <br />
          <p className={`font-semibold`}>リニューアル前の課題</p>
          <p>
            機能やデータが増加する中で、システムのパフォーマンスが著しく低下し、事業運営に大きな影響を与えるまでに至っていました。また、ドキュメントや設計資料がないまま、技術的負債が積み重なり、システム運用や新機能の追加が困難な状況に陥っていました。さらに、セキュリティ面でも複数の懸念事項が発生していました。
          </p>

          <br />
          <p className={`font-semibold`}>リニューアルの効果</p>
          <p>
            当社は、共創パートナーとしてシステムのリプレイスを担当し、UI/UX、セキュリティ、機能の改善を行いました。これにより、パフォーマンスが飛躍的に向上し、以前は提供が難しかったスカウト機能やレコメンド機能の実現が可能となりました。セキュリティの強化も行われ、ユーザーが安心してサービスを利用できる環境を整えました。
          </p>
          <p>結果として、利用者数とサービスの利用頻度が増加しました。side bizzは、ビジネスマッチングを支援する機能を強化し、利用者にとってより価値のあるプラットフォームとして進化しています。</p>

          <br/>
          <p>新規事業の開発、既存システムの改善、マッチングシステムの開発、レコメンとシステム、スカウトシステムなどをお考えを企業社、ぜひお気軽にお問い合わせください。専属チームで、リサーチ、分析からスタートし、現状を深い理解します。また、正しく明確な目的と目標を定め、その実現をご一緒に目指します。</p>

          <p>
            <Link href="/contact" passHref>
              <a
                className={`text-sm hover:underline md:self-end`}
              >
                お問い合わせフォームへ
              </a>
            </Link>
          </p>
        </div>
      ),
    },
    date: '2021 Apr',
    label: 'Business Enhancement',
  },
  {
    id: '14',
    title:
      'Released a business support service called “GIMMY” that specializes in personal trainers and sports coaches.',
    thumbnail: 'card14.png',
    cover: 'cover14.png',
    link: '/news/14',
    date: '2021 Jan',
    slug: 'gimmy-release',
    meta: {
      title:
        'GIMMY, a business support service specializing in personal trainers and sports coaches - News - wesionaryTEAM',
      keywords: 'DX, business efficiency, system development',
      description:
        'We have released “GIMMY”, a business support service specializing in personal trainers and sports coaches.',
    },
    description: {ja:(
        <div className="grid gap-4">
          <p>
            スポーツやフィットネス関連サービスを主力事業とする{' '}
            <a
              href="https://rascals.co.jp/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              株式会社RASCAL's
            </a>
            とパーソナルトレーニングに関する業務をDX化するため「{' '}
            <a
              href="https://gimmy.co/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              GIMMY
            </a>
            」プラットフォームの提供を開始しました。
          </p>
          <p>
            当業界ではITに不慣れな方が多い上、DX化の余地が多く残されています。
          </p>
          <p>
            顧客管理、スケジュール管理、売上管理など多くがアナログ作業で行われています。
          </p>
          <p>
            GIMMY
            は広範囲に及ぶ業務をデジタル化し生産性を高めるとともに、トレーナーの集客にも貢献するプロダクトとなっています。
          </p>
          <p>
            より多くの方に使って頂けるよう、利用状況を追いながら、プロダクトを改良して参ります。
          </p>
        </div>
      ),en:(
        <div className="grid gap-4">
          <p>
            Together with{' '}
            <a
              href="https://rascals.co.jp/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              RASCAL's Co., Ltd.
            </a>
            , which focuses on sports and fitness-related services, we released "
            <a
              href="https://gimmy.co/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              GIMMY
            </a>
            " platform to digitize(DX) personal training operations.
          </p>
          <p>
            Many people in the industry are unfamiliar with IT, and there is still a lot of room for digitalization(DX).
          </p>
          <p>
            Tasks such as customer management, schedule management, and sales management are mostly done manually.
          </p>
          <p>
            GIMMY is a product that digitizes a wide range of business processes, increases productivity, and contributes to attracting trainers.
          </p>
          <p>
            We are continuously enhancing the product and keeping track of its usage to ensure it becomes more accessible to a much more wider audience.
          </p>
        </div>
      )},
    label: 'DX',
  },
];
