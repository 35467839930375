export const CORE_VALUES = [
  {
    code: '01',
    title: 'Pursuing customer\nsuccess',
    description:
      'Contribution to business growth by providing rapid and essential value to end users through a deep understanding of customers’ business visions and challenges.',
  },
  {
    code: '02',
    title: 'Continuous\nImprovement',
    description:
      'Continuously improve the current situation by recognizing issues scattered throughout daily operations and processes and taking action to improve them.',
  },
  {
    code: '03',
    title: 'Increase\nreproducibility',
    description:
      'Abstract, systematize, and share findings, experiences, and results, in order to increase organizational throughput and assurance of business success.',
  },
];