type Props = {
  children: React.ReactNode;
};

const NoSider = ({ children }: Props) => {
  return (
    <div className="m-auto max-w-[1440px] overflow-hidden px-6 lg:px-[30px] xl:px-[60px] 3xl:px-0">
      {children}
    </div>
  );
};

export { NoSider };
