import { ReactNode } from 'react';
import { Select as ChakraSelect, Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { DownIconForm } from 'public/assets/images/svg';
interface ISelectOption {
  label: string | number;
  value: string | number;
}

interface InputProps {
  value?: string;
  isInvalid?: boolean;
  errorMessage?: string;
  onChange?: any;
  name?: string;
  icon?: ReactNode;
  label?: string;
  required?: boolean;
  placeholder?: string;
  options: ISelectOption[];
  id?: string;
}

export const Select = ({
  value,
  onChange,
  errorMessage,
  isInvalid,
  name,
  icon,
  label,
  required,
  placeholder,
  options,
  id,
}: InputProps) => {
  const { t } = useTranslation();
  return (
    <div className="h-32">
      <div className="flex items-center">
        {icon}
        <Text
          as="label"
          className={`${
            icon && 'ml-[10px]'
          } inline font-Poppins text-[18px] font-normal leading-[26px] text-gray-primary`}
        >
          {label}
        </Text>
        {required && (
          <Text
            as="span"
            className="pl-[3px] font-Poppins text-[18px] font-normal leading-[26px] text-gray-secondary"
          >
            {t('(Required)')}
          </Text>
        )}
      </div>
      <ChakraSelect
        id={id}
        w="100%"
        focusBorderColor="#960912"
        name={name}
        value={value}
        onChange={onChange}
        isInvalid={isInvalid}
        errorBorderColor="#960912"
        icon={<DownIconForm />}
        _placeholder={{
          color: '#8F9BB3',
          fontFamily: 'Poppins',
          fontSize: '16px',
          lineHeight: '24px',
        }}
        className={`select mt-3 rounded-lg border p-4 ${
          !isInvalid && '!border-gray-secondary'
        } focus:border focus:border-primary-500 focus:outline-2 focus:outline-primary-400`}
      >
        <option
          selected
          disabled
          style={{
            color: '#8F9BB3',
            fontFamily: 'Poppins',
            fontSize: '16px',
            lineHeight: '24px',
          }}
          value=""
        >
          {placeholder}
        </option>
        {options.map((option: ISelectOption, index: number) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </ChakraSelect>
      {errorMessage && (
        <Text className="text-[#960912]" height="25px" as="p">
          {errorMessage}
        </Text>
      )}
    </div>
  );
};
