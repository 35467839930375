const TOP_LEVEL = [
  'Board of Directors',
  'President',
  'Executive Committee',
  'Internal Audit Committee',
  'General Managers Committee'
];

const MEMBERS = [
  {
    title: 'People & Culture',
    list: [
      'Recruitment',
      'Vision & Values',
      'EX Promotion',
      'Assessment & Feedback',
    ],
    people: [
      {
        name: 'Prakash',
        position: 'COO',
        photo: '/prakash_new.png',
      },
      {
        name: 'Sudeep',
        position: 'Manager',
        photo: '/sudeep_new.png',
      },
      {
        name: 'Binod',
        position: 'Manager',
        photo: '/binod_new.png',
      },
      {
        name: 'Ujjwol',
        position: 'Manager',
        photo: '/ujjwol_new.png',
      },
    ],
  },
  {
    title: 'Customer Success',
    list: [
      'Co-Creation Promotion',
      'Project Management',
      'Quality Management',
      'Incident Management',
      'CX Promotion'
    ],
    people: [
      {
        name: 'Ichiro',
        position: 'CEO',
        photo: '/ichiro_new.png',
      },
      {
        name: 'Manoj',
        position: 'CQO',
        photo: '/manoj_new.png',
      },
      
    ],
  },
  {
    title: 'Technology',
    list: ['Mobile', 'Web','Backend','Security','DevOps','No-Code'],
    people: [
      {
        name: 'Dipesh',
        position: 'CTO',
        photo: '/dipesh_new.png',
      },
      {
        name: 'Bibek',
        position: 'VPoE',
        photo: '/bibek_new.png',
      },
      {
        name: 'Manip',
        position: 'VPoE',
        photo: '/manip_new.png',
      },
      {
        name: 'Dinesh',
        position: 'VPoE',
        photo: '/dinesh_new.png',
      },
    ],
  },
  {
    title: 'Design',
    people: [
      {
        name: 'Dipendra',
        position: 'CDO',
        photo: '/dipendra_new.png',
      },
      {
        name: 'Ayumi',
        position: 'Designer',
        photo: '/ayumi_new.png',
      },
      {
        name: 'Akihiro',
        position: 'Designer',
        photo: '/akihiro_new.png',
      },
    ],
  },
  {
    title: 'Systematization Productivity',
    people: [
      {
        name: 'Ujjwal',
        position: 'Manager',
        photo: '/ujjwol_new.png',
      },
    ],
  },
  {
    title: 'Information Security',
    people: [
      {
        name: 'Ichiro',
        position: 'CEO',
        photo: '/ichiro_new.png',
      },
    ],
  },  
  {
    title: 'BizDev',
    list: ['Co-Creation Platform','Partnership'],
    people: [
      {
        name: 'Ichiro',
        position: 'CEO',
        photo: '/ichiro_new.png',
      },
      {
        name: 'Haruhisa',
        position: 'Business P&D',
        photo: '/isa_new.png',
      }, 
      {
        name: 'Akifumi',
        position: 'Business P&D',
        photo: '/akifumi_new.png',
      }, 
      {
        name: 'Chiharu',
        position: 'CS',
        photo: '/chiharu_new.png',
      }, 
      {
        name: 'Mikako',
        position: 'CS',
        photo: '/mikako_new.png',
      }, 
      {
        name: 'Yuta',
        position: 'PdM',
        photo: '/yuta_new.png',
      },
    ],
  },
  {
    title: 'Corporate Management',
    list: ['General Affairs', 'Legal Affairs','Finance','Public relations','Risk Management'],
    people: [
      {
        name: 'Prakash',
        position: 'COO',
        photo: '/prakash_new.png',
      },
      {
        name: 'Masachika',
        position: 'Legal Advisor',
        photo: '/masachika_new.png',
      },
      {
        name: 'Takuya',
        position: 'Financial Advisor',
        photo: '/takuya_new.png',
      },
    ],
  },
];

export { TOP_LEVEL, MEMBERS };
